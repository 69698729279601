<template>

  <div class="pa-4">
    <div style="max-width: 600px;" class="mx-auto">
      <div class="title text-center pb-4">
        Admin functions
      </div>

      <div v-if="showWarning" class="v-card pa-8 br-20">
        <div class="title font-weight-bold orange--text mb-2">
          WARNING
        </div>
        This section is intended for the <span class="orange--text">pool owner</span> or the project admins themselves, this is not for farmers or stakers.
        Using any function in this area will lead to loss of funds, as they are for sending tokens to the reward pool to share with farmers.

        <div class="mt-4">
          If you just plan to farm or stake this section is not for you.
        </div>
        <v-btn x-large block rounded color="orange" class="white--text mt-2" @click="showWarning = false">
          I am a pool admin
        </v-btn>
      </div>

      <template v-else>

        <top-up :reward_pool="reward_pool" :address="address"></top-up>

        <increase-block-reward :reward_pool="reward_pool" :address="address" class="mt-10"></increase-block-reward>

        <drain-token :reward_pool="reward_pool" :address="address" class="mt-10"></drain-token>

        <unicrypt-admin v-if="$store.state.adminIds.includes($store.state.user.jwtObject.user_id)" :reward_pool="reward_pool" :address="address" class="mt-10" @hard-refresh="$emit('hard-refresh')"></unicrypt-admin>

      </template>
    </div>
  </div>

</template>

<script>
import TopUp from './admin/top-up'
import DrainToken from './admin/drain-token'
import IncreaseBlockReward from './admin/increase-block-reward'
import UnicryptAdmin from './admin/unicrypt-admin'

export default {

  props: {
    address: null,
    reward_pool: {
      type: Object,
      default: () => {
        return {
          reward_token: {}
        }
      }
    }
  },

  components: {
    TopUp,
    DrainToken,
    IncreaseBlockReward,
    UnicryptAdmin
  },

  data: () => ({
    showWarning: true
  }),

  computed: {
  },

  methods: {
  },

  created () {
  }
}
</script>