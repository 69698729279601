<template>
  <div>
    
    <!--
    <div class="text-center pt-2 pb-2">
      <v-btn large rounded depressed @click="$root.$dialog.chainSwitcher.open()" color="midground">
        <img 
        :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
        height="18px"
        width="18px"
        class="mr-3 br-20">
        {{ $settings.CHAIN_DISPLAY_NAME[$store.state.requiredNetwork] }}
        <v-icon small color="textFaint">mdi-chevron-down</v-icon>
      </v-btn>
    </div>
    -->

    <!-- NOT CONNECTED -->
    <div v-if="!sEthers.coinbase" class="text-center pa-6">
      <div>
        <v-icon size="40" color="textFaint">mdi-account-circle</v-icon>
        <div class="textFaint--text caption pt-2">
          Connect your wallet to view your farms
        </div>
      </div>
      <div class="pt-2">
        <v-btn
        large
        block
        outlined
        color="navcolor"
        class="white--text"
        rounded
        @click="connectWallet"
        >
          CONNECT
        </v-btn>
      </div>
    </div>
    <!-- NOT CONNECTED -->

    <template v-else>
      <div class="d-flex align-center py-2 px-2">
        <v-btn text @click="tab = 'user'" :color="tab === 'user' ? 'primary' : 'textFaint'">
          YOUR FARMS
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="tab = 'admin'" :color="tab === 'admin' ? 'primary' : 'textFaint'">
          ADMIN FARMS
        </v-btn>
      </div>

      <template v-if="tab === 'user'">
        <div class="pa-4 text-center textFaint--text">
          Pools you are currently staking.
        </div>
        <user-farms network="Mainnet"></user-farms>
        <user-farms network="BSC_MAINNET"></user-farms>
        <user-farms network="AVAX"></user-farms>
        <user-farms v-if="$store.state.allowGanache" network="Ganache"></user-farms>
        <user-farms network="Kovan"></user-farms>
      </template>

      <template v-if="tab === 'admin'">
        <div class="pa-4 text-center textFaint--text">
          Pools you are admin of (for stake pool creators)
        </div>
        <admin-farms network="Mainnet"></admin-farms>
        <admin-farms network="BSC_MAINNET"></admin-farms>
        <admin-farms network="AVAX"></admin-farms>
        <admin-farms v-if="$store.state.allowGanache" network="Ganache"></admin-farms>
        <admin-farms network="Kovan"></admin-farms>
      </template>
    </template>
  </div>
</template>

<script>
import UserFarms from '@/views/farms/user/user-farms.vue'
import AdminFarms from '@/views/farms/user/admin-farms'

export default {

  props: {
  },

  components: {
    UserFarms,
    AdminFarms
  },

  data: () => ({
    tab: 'user', // user, admin
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
  },

  methods: {
    async refresh () {
    },
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
  },

  created () {
  }
}
</script>