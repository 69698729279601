import {store} from '@/store/index';
import { ethers } from "ethers";
import FACTORY_ABI from './abis'

const Self = {
  stakePoolsLength: async () => {
    var sEthers = store.state.ethers
    const Factory = new ethers.Contract(FACTORY_ABI.factory_address(), FACTORY_ABI.factory_abi, sEthers.provider)
    var res = await Factory.stakePoolsLength()
    return res.toString()
  },
  stakePoolAtIndex: async (_index) => {
    var sEthers = store.state.ethers
    const Factory = new ethers.Contract(FACTORY_ABI.factory_address(), FACTORY_ABI.factory_abi, sEthers.provider)
    var res = await Factory.stakePoolAtIndex(_index)
    return res
  },
  getNumPoolsForAdmin: async (_user) => {
    var sEthers = store.state.ethers
    const Factory = new ethers.Contract(FACTORY_ABI.factory_address(), FACTORY_ABI.factory_abi, sEthers.provider)
    var res = await Factory.getNumPoolsForAdmin(_user)
    return res
  },
  getStakePoolForAdminAtIndex: async (_user, _index) => {
    var sEthers = store.state.ethers
    const Factory = new ethers.Contract(FACTORY_ABI.factory_address(), FACTORY_ABI.factory_abi, sEthers.provider)
    var res = await Factory.getStakePoolForAdminAtIndex(_user, _index)
    return res
  }
}

export default Self