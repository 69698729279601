<template>
  <div class="pa-4">
    <div v-if="!token_hydrated.address">
      {{ address }}
    </div>
    <!-- LOADED -->
    <div v-else>
      <div class="d-flex align-center pa-2">
        <coin-icon :address="token_hydrated.address" class="mr-2"></coin-icon>
        {{ token_hydrated.symbol }}
        <v-spacer></v-spacer>
        <copy-address :address="token_hydrated.address" color="textFaint"></copy-address>
      </div>
      <admin-card :token_address="address"></admin-card>
    </div>
  </div>
</template>

<script>
import ERC20PAGER from '@/smart-contracts/erc20/pager'
import AdminCard from './admin-card'

export default {
  props: {
    address: null
  },

  components: {
    AdminCard
  },

  data: () => ({
    token_hydrated: {},
    loadingToken: false
  }),

  computed: {
  },

  methods: {
    async loadTokenInfo () {
      this.token_hydrated = {}
      this.loadingToken = true
      ERC20PAGER.getERC20(this.address)
        .then(token => {
          if (token.name === 'Unknown' && token.symbol === 'Unknown' && token.totalSupply === '0') {
            return
          }
          this.token_hydrated = token
        })
        .catch(e => {
        })
        .then(() => {
          this.loadingToken = false
        })
    },
  },

  created () {
    this.loadTokenInfo()
  }
}
</script>