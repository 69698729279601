<template>
  <div class="d-flex align-center inputcolor br-8 py-1">
    <c-input :value.sync="timeInput" @updateWhileFocussed="onTimeInputChange"  placeholder="time integer (e.g. '12')" class="pa-2">
    </c-input>
    <v-menu
    offset-y
    >
      <template v-slot:activator="{ attrs, on }">
        <div class="d-flex align-center px-4"
          v-bind="attrs"
          v-on="on"
        >
          {{ timePeriod }}
          <v-spacer></v-spacer>
          <v-icon small>mdi-chevron-down</v-icon>
        </div>
      </template>

      <v-list style="min-width: 200px;" class="background">
        <v-list-item @click="setTimePeriod('seconds')">
          <v-list-item-title>
            seconds
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="setTimePeriod('minutes')">
          <v-list-item-title>
            minutes
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="setTimePeriod('hours')">
          <v-list-item-title>
            hours
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="setTimePeriod('days')">
          <v-list-item-title>
            days
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="setTimePeriod('weeks')">
          <v-list-item-title>
            weeks
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="setTimePeriod('months')">
          <v-list-item-title>
            months
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="setTimePeriod('years')">
          <v-list-item-title>
            years
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    timeInput: '',
    timePeriod: 'months',
  }),

  watch: {
  },

  methods: {
    // this can be called from a parent component to set the display to a default value
    setDisplay (timeInSeconds) {
      if (timeInSeconds < 120) {
        this.timeInput = timeInSeconds
        this.timePeriod = 'seconds'
      } else if (timeInSeconds < 7200) {
        this.timeInput = timeInSeconds / 60
        this.timePeriod = 'minutes'
      } else if (timeInSeconds < 172800) {
        this.timeInput = timeInSeconds / 60 / 60
        this.timePeriod = 'hours'
      } else if (timeInSeconds < 5184000) {
        this.timeInput = timeInSeconds / 60 / 60 / 24
        this.timePeriod = 'days'
      } else if (timeInSeconds < 63072000) {
        this.timeInput = timeInSeconds / 60 / 60 / 24 / 30
        this.timePeriod = 'months'
      } else {
        this.timeInput = timeInSeconds / 60 / 60 / 24 / 365
        this.timePeriod = 'years'
      }
      this.$emit('onUpdate', timeInSeconds) // used to simply echo this into a parent components model
    },
    onTimeInputChange (nv) {
      this.emitOnUpdate()
    },
    setTimePeriod (tpString) {
      this.timePeriod = tpString
      this.emitOnUpdate()
    },
    emitOnUpdate () {
      var timeInSeconds = 0
      if (this.timePeriod === 'seconds') {
        timeInSeconds = this.timeInput
      } else if (this.timePeriod === 'minutes') {
        timeInSeconds = this.timeInput * 60
      } else if (this.timePeriod === 'hours') {
        timeInSeconds = this.timeInput * 60 * 60
      } else if (this.timePeriod === 'days') {
        timeInSeconds = this.timeInput * 60 * 60 * 24
      } else if (this.timePeriod === 'weeks') {
        timeInSeconds = this.timeInput * 60 * 60 * 24 * 7
      } else if (this.timePeriod === 'months') {
        timeInSeconds = this.timeInput * 60 * 60 * 24 * 30
      } else if (this.timePeriod === 'years') {
        timeInSeconds = this.timeInput * 60 * 60 * 24 * 365
      }
      this.$emit('onUpdate', timeInSeconds)
    }
  }

}
</script>