<template>
  <div>

    <!-- <v-list-item v-for="tx of txnArray" :key="tx" :href="`${$store.state.etherscan_url}/tx/${tx}`" target="_blank"> -->
    
    <v-list-item 
    v-for="tx of txnArray" 
    :key="tx.txhash" 
    :href="`${$settings.CHAINS[tx.network].explorerURL}/tx/${tx.txhash}`" 
    target="_blank"
    class="border-b">

      <v-list-item-icon>        
        <v-progress-circular
        v-if="tx.state === 'pending'"
        indeterminate
        size="24"
        width="2"
        color="primary"
        >
        </v-progress-circular>

        <v-icon v-else-if="tx.state === 'completed'" color="primary">mdi-check</v-icon>
        <v-icon v-else color="pink">mdi-cancel</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        {{ $root.condenseAddress(tx.txhash) }}
      </v-list-item-content>

      <img 
      :src="$settings.CHAINS[tx.network].icon" 
      height="20px"
      width="20px"
      class="br-20">
      <span class=""/>

    </v-list-item>
    <div v-if="Object.keys(txnArray).length === 0" class="pa-6">
      No transactions to show
    </div>

  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
  }),

  computed: {
    iconWidth () {
      return '35px'
    },
    sEthers () {
      return this.$store.state.ethers
    },
    pendingTx () {
      return this.$store.state.pendingTx.pending
    },
    pendingTxLengthHuman () {
      if (this.pendingTx.length > 9) {
        return '>9'
      }
      return this.pendingTx.length
    },
    txnArray () {
      return this.$store.state.pendingTx.tx
    },
    walletConnected () {
      return this.sEthers.coinbase
    },
    blockNumber () {
      return this.$store.state.blockNumber
    }
  },

  methods: {
  }
}
</script>

<style scoped lang="scss">
.draw-icon {
  fill:transparent;
  stroke-width:5px;

  path{
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 1.5s linear forwards;
    // animation: dash 5s cubic-bezier(.645,.045,.355,1);
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
</style>