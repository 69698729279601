<template>
  <div>
    <dynamic-marquee :style="`height: ${marqueeHeight};`" direction="row" :speed="{type: 'pps', number: 50}" reverse class="background py-1">
			<upcoming-presales></upcoming-presales>
		</dynamic-marquee>
    <div v-if="false" style="overflow-y: scroll;" class="py-2">
      <upcoming-presales></upcoming-presales>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import UpcomingPresales from './upcoming-presales'
import DynamicMarquee from 'vue-dynamic-marquee';

export default {
  props: {
  },

  components: {
    UpcomingPresales,
    DynamicMarquee
  },

  data: () => ({
    intervalID: null,
  }),

  computed: {
    marqueeHeight () {
      return this.$vuetify.breakpoint.xs ? '68px' : '130px'
    }
  },

  methods: {
    scrollRight () {
    },
  },

  created () {
    this.intervalID = setInterval(this.scrollRight, 8000)
  },

  destroyed () {
    clearInterval(this.intervalID)
  },
}

</script>
