<template>
  <tr v-if="false" class="c-list" @click="$root.ammLinkTwo(`/ilo/${db_info.presale_contract}`, exchange)">

    <td class="d-flex align-center">
      <coin-icon :url="db_info.icon_url" :size="50"></coin-icon>
      <div class="pl-2">
        <div class="font-weight-bold caption">
          {{ db_info.s_token.symbol }}
        </div>
        <div class="font-weight-medium textFaint--text">
          {{ db_info.s_token.name }}
        </div>
      </div>
    </td>

    <td class="font-weight-medium title primary--text">
      {{ ((db_info.profit / 100) + 1).toFixed(1) }} X
    </td>
    <td class="font-weight-medium textFaint--text">
      {{ hardcapHuman }} {{ baseTokenSymbol }}
    </td>
    <td>
      <div class="d-flex align-center">
        <img 
        :src="$settings.CHAINS[cNetwork].icon" 
        height="26px"
        width="26px"
        class="br-20 ml-1">
        <img 
        :src="$settings.AMMS[db_info.exchange].icon" 
        height="26px"
        width="26px"
        style="margin-left: -6px;"
        class="br-20">
      </div>
    </td>

  </tr>
  <tr v-else class="c-list" @click="$root.ammLinkTwo(`/ilo/${db_info.presale_contract}`, exchange)">

    <td class="d-flex align-center pl-4">

      <v-badge
        avatar
        bottom
        right
        color="midground"
        offset-x="18"
        offset-y="18"
      >
        <template v-slot:badge>
          <img 
          :src="$settings.CHAINS[cNetwork].icon" 
          height="20px"
          width="20px"
          style="padding: 2px;"
          class="br-20 midground">
        </template>
        <coin-icon :url="db_info.icon_url" :size="50"></coin-icon>
      </v-badge>

      <div class="pl-2">
        <div class="font-weight-medium">
          <span class="hover-primary" @click.stop="$root.tokenPage.open(db_info.s_token.address, cNetwork)">
            {{ db_info.s_token.symbol }}
          </span>
        </div>
        <div class="caption textFaint--text">
          {{ db_info.s_token.name }}
        </div>
      </div>
    </td>

    <td class="font-weight-medium textFaint--text text-end pr-6">
      <div class="title primary--text">
        {{ ((db_info.profit / 100) + 1).toFixed(1) }} X
      </div>
      <!-- <div class="caption">
        {{ hardcapHuman }} {{ baseTokenSymbol }}
      </div> -->
    </td>

  </tr>
</template>

<script>
import { ethers } from 'ethers'
import moment from 'moment'
import PresaleContract4 from '@/smart-contracts/presales/v4/presale-contract'
import PresaleContract6 from '@/smart-contracts/presales/v6/presale-contract'
import PresalePeriphery from '@/smart-contracts/presales/presale-periphery'

export default {

  props: {
    db_info: {
      type: Object,
      default: () => ({
        presale_contract: null
      })
    },
    exchange: null
  },

  data: () => ({
    presale_info: {
      hardcap: '0',
      base_token: {
        symbol: '?'
      },
      uncl_participants: '0',
      uncl_max_participants: '0'
    },
    firstLoad: true
  }),

  computed: {
    cNetwork () {
      return this.$settings.AMMS[this.exchange].chain
    },
    startDateFromNow () {
      var numBlocks = this.db_info.start_block - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date.fromNow()
    },
    blockNumber () {
      if (this.exchange) {
        return this.$store.state.blockNumz[this.$settings.AMMS[this.exchange].chain]
      }
      return this.$store.state.blockNumber
    },
    blocksPerSecond () {
      return this.$settings.CHAINS[this.cNetwork].blockTime
    },
    unclProgress () {
      if (this.presale_info.uncl_max_participants === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.presale_info.uncl_participants).mul(1000).div(this.presale_info.uncl_max_participants)
      return percent.toNumber() / 10
    },
    hardcapHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.hardcap, this.presale_info.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    baseTokenSymbol () {
      return this.presale_info.base_token.address === this.$settings.WRAPPED_GAS_TOKEN[this.cNetwork] ? this.$settings.CHAINS[this.cNetwork].gasToken.symbol : this.presale_info.base_token.symbol.toUpperCase()
    },
  },

  methods: {
    async refresh () {
      var network = this.exchange ? this.$settings.AMMS[this.exchange].chain : null
      var contract_version = await PresalePeriphery.getContractVersion(this.db_info.presale_contract, network)
      var response
      if (contract_version >= 3 && contract_version <= 5) {
        response = await PresaleContract4.getInfo(this.db_info.presale_contract, network)
      } else if (contract_version === 6) {
        response = await PresaleContract6.getInfo(this.db_info.presale_contract, network)
      } else {
        return
      }
      this.presale_info = response.presale_info
      this.firstLoad = false
    },
  },

  created () {
    this.refresh()
  }
}
</script>