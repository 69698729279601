<template>
  <div class="v-card br-20">

    <div v-if="sEthers.coinbase">
      
      <div class="pa-4 d-flex align-center midground">
        <div class="title">
          Your Account
        </div>
      </div>

      <div class="pa-4">
        <div class="d-flex align-center pl-2 py-2 mt-8">
          <span class="textFaint--text font-weight-bold ml-2">
            Your staking stats
          </span>
          <v-spacer></v-spacer>
        </div>

        <div class="v-card br-8">
          <div class="pa-4 d-flex align-center border-b">
            <span class="textFaint--text">
              Tokens staking
            </span>
            <v-spacer></v-spacer>
            <div class="">

              {{ $root.formatAmount(user_info.share_weight, pool_info.decimals) }}
            </div>
          </div>

          <div class="pa-4 d-flex align-center border-b">
            <span class="textFaint--text">
              Time boost
            </span>
            <v-spacer></v-spacer>
            <div>
              <span class="flex d-flex justify-end text--text">
                + {{ user_info.time_boost_percentage / 100 }}%
              </span>
              <v-progress-linear height="6" :value="10"
              color="lgrad-green"
              background-color="midground"
              reverse
              style="width: 80px;"
              class="font-weight-bold br-c">
              </v-progress-linear>
            </div>
          </div>

          <div class="pa-4 d-flex align-center border-b">
            <span class="textFaint--text">
              UNCL boost
            </span>
            <v-spacer></v-spacer>
            <div>
              <span class="flex d-flex justify-end text--text">
                + {{ user_info.uncl_boost_percentage / 100 }}%
              </span>
              <v-progress-linear height="6" :value="10"
              color="lgrad-blue"
              reverse
              background-color="midground"
              style="width: 80px;"
              class="font-weight-bold br-c">
              </v-progress-linear>
            </div>
          </div>

          <div class="pa-4 d-flex align-center border-b">
            <span class="textFaint--text">
              Total stake weight
            </span>
            <v-spacer></v-spacer>
            <div class="">
              {{ $root.formatAmount(user_info.share_weight, pool_info.decimals) }}
            </div>
          </div>

          <div class="pa-4 d-flex align-center">
            <span class="textFaint--text">
              Your pool share
            </span>
            <v-spacer></v-spacer>
            <div class="">
              {{ poolSharePercent }}%
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="text-center pa-6">
      <div>
        <v-icon size="40" color="textFaint">mdi-account-circle</v-icon>
        <div class="textFaint--text caption pt-2">
          Connect your wallet to stake
        </div>
      </div>
      <div class="pt-2">
        <v-btn
        large
        block
        outlined
        color="navcolor"
        class="white--text"
        rounded
        @click="connectWallet"
        >
          CONNECT
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>
import { ethers } from 'ethers'

export default {

  props: {
    address: null,
    user_info: {
      type: Object,
      default: () => {
        return {
          share_weight: '0',
          shares: '0',
          tokens_staking: '0',
        }
      }
    },
    pool_info: {
      type: Object,
      default: () => {
        return {
          share_weight_total: '0'
        }
      }
    }
  },

  components: {
  },

  data: () => ({
  }),

  watch: {
  },

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    poolSharePercent () {
      if (this.pool_info.share_weight_total === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.user_info.share_weight).mul(10000).div(this.pool_info.share_weight_total)
      return percent.toNumber() / 100
      // return 0
    },
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    async refresh () {
    },
  },

  created () {
    // this.refresh()
  }
}
</script>