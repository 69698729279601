<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="450" content-class="br-20">
    <v-card class="br-20">
      
      <v-card-text class="pa-4">
        <v-icon size="180" style="color: var(--v-background-base)!important; position: absolute; left: -30px; top: -30px;">mdi-alert-outline</v-icon>

        <div style="position: relative;">
          
          <!-- TAB 0 -->
          <div v-if="tab === 0">
            <div class="pa-8 text-center">
              <div class="display-1 mb-6">
                High Risk Alert
              </div>
              <div class="mb-2">
                Non KYC sales are <strong>High Risk</strong>
              </div>
              <div style="font-size: 80px;">
                👿
              </div>
              <div class="mb-2">
                As the developer is anonymous, it is possible for them to abandon the project 
                immediately after the sale or dump the market to zero with no consequences. Sadly this happens often. Exercise extreme caution. 
                There is a high risk of losing all funds with some of these sales.
              </div>
            </div>

            <v-row dense>
              <v-col>
                <v-btn x-large text rounded block @click="close" color="textFaint">
                  CANCEL
                </v-btn>
              </v-col>
              <v-col>
                <v-btn x-large rounded block @click="tab = 1" class="white--text lgrad-red">
                  Show high risk sales
                </v-btn>
              </v-col>
            </v-row>

          </div>
          <!-- TAB 0 -->

          <!-- TAB 1 -->
          <div v-if="tab === 1">
            <div class="text-end">
              <v-btn icon large @click="close">
                <v-icon large>mdi-close</v-icon>
              </v-btn>
            </div>
            <div class="text-center red--text">
              Confirm High Risk.
            </div>
            <div style="font-size: 50px;" class="text-center">
              🚨
            </div>
            <div class="text-center pa-4">
              <div v-if="false">
                Please confirm: 
              </div>
              <div class="title textFaint--text">
                <span :class="{'text--text': sequence >= 1}">I'm aware </span> 
                <span :class="{'text--text': sequence >= 2}">anonymous projects </span> 
                <span :class="{'text--text': sequence >= 3}">are </span> 
                <span :class="{'text--text': sequence >= 4}">HIGH RISK</span> 
              </div>
            </div>

            <v-row dense>
              <v-col cols="6">
                <v-btn @click="clickCaptcha(1)" :disabled="sequence >= 1" large block class="lgrad-red white--text">
                  Im aware
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="clickCaptcha(4)" :disabled="sequence >= 4" large block class="lgrad-red white--text">
                  HIGH RISK
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="clickCaptcha(2)" :disabled="sequence >= 2" large block class="lgrad-red white--text">
                  anon projects
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn @click="clickCaptcha(3)" :disabled="sequence >= 3" large block class="lgrad-red white--text">
                  are
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <!-- TAB 1 -->

        </div>
        
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  components: {
  },

  data: () => ({
    dialog: false,
    tab: 0,
    sequence: 0
  }),

  computed: {
  },

  watch: {
  },

  methods: {
    clickCaptcha (num) {
      if (num === this.sequence + 1) {
        this.sequence++
      }
      if (this.sequence === 4) {
        this.enableApeMode()
      }
    },
    open () {
      this.tab = 0
      this.sequence = 0
      this.dialog = true
    },
    enableApeMode () {
      this.$emit('on-enable-ape-mode')
      this.dialog = false
    },
    close () {
      this.dialog = false
    }
  },

  created () {
  }
}
</script>