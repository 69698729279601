<template>
  <div>
    <v-slide-y-transition appear>
      <div>

        <div v-if="$vuetify.breakpoint.xs" :class="['foreground']">
          <div @click="$router.push('/amm')" class="pa-2 border c-list">
            <div class="font-weight-medium title text-uppercase py-1 text--text d-flex align-center justify-center">
              <img 
              :src="$settings.AMMS[this.$store.state.exchange].icon" 
              height="24px"
              width="24px"
              class="mr-3 br-20">
              {{ $store.state.exchange }}
              <v-icon small color="textFaint">mdi-chevron-down</v-icon>
            </div>
          </div>
        </div>

        <a v-if="$settings.AMMS[$store.state.exchange].bannerLink" :href="$settings.AMMS[$store.state.exchange].bannerLink" target="_blank">
          <v-img
            v-if="backgroundImage"
            contain
            max-height="50vh"
            max-width="100%"
            :src="backgroundImage"
          ></v-img>
        </a>

        <v-img
          v-else-if="backgroundImage"
          contain
          max-height="50vh"
          max-width="100%"
          :src="backgroundImage"
        ></v-img>

        <div v-if="!$vuetify.breakpoint.xs" class="text-center">
          <div :class="['py-2 d-flex justify-center']">
            <div @click="$router.push('/amm')" class="pa-2 px-4 border br-c c-list">
              <div class="font-weight-medium title text-uppercase py-1 text--text d-flex align-center justify-center">
                <img 
                :src="$settings.AMMS[this.$store.state.exchange].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                {{ $store.state.exchange }}
                <v-icon small color="textFaint">mdi-chevron-down</v-icon>
              </div>
            </div>
          </div>
        </div>

        <div class="mx-auto" style="max-width: 800px;">

          <div :class="['d-flex border-b foreground border', {'br-c': !$vuetify.breakpoint.xs}]" style="overflow: hidden;">

            <router-link 
            :to="`${$store.state.ammPrefix}/tokens`"
            v-slot="{ isActive, navigate }">
              <div :class="['top-tab', {'active': isActive}]" @click="navigate">
                <!-- <v-icon size="20" class="mr-1">mdi-checkbox-blank-circle-outline</v-icon> -->
                <span>TOKENS</span>
              </div>
            </router-link>

            <router-link 
            :to="`${$store.state.ammPrefix}/pairs`"
            v-slot="{ isActive, navigate }">
              <div :class="['top-tab', {'active': isActive}]" @click="navigate">
                <!-- <v-icon size="20" class="mr-1">mdi-checkbox-multiple-blank-circle-outline</v-icon> -->
                <span>LIQUIDITY</span>
              </div>
            </router-link>

            <router-link 
            :to="`${$store.state.ammPrefix}/ilos`"
            v-slot="{ isActive, navigate }">
              <div :class="['top-tab', {'active': isActive}]" @click="navigate">
                <!-- <v-icon size="20" class="mr-1">mdi-rocket</v-icon> -->
                <span>LAUNCHPAD</span>
              </div>
            </router-link>

            <router-link
            v-if="$store.state.superUserMode"
            :to="`${$store.state.ammPrefix}/sajfkhflkjasdlisdulansdlakds`"
            v-slot="{ isActive, navigate }">
              <div :class="['top-tab', {'active': isActive}]" @click="navigate">
                <v-icon size="20" class="mr-1">mdi-atom</v-icon>
                <span>ChavPanel</span>
              </div>
            </router-link>
          </div>

        </div>

        <keep-alive include="CronTab,TokensPage,PairsComponent,Presales">
          <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import SETTINGS from '@/store/settings'
export default {
  props: {
    amm: {
      type: String
    }
  },

  data: () => ({
  }),

  computed: {
    backgroundImage () {
      /* if (this.$settings.AMMS[this.$store.state.exchange].banner) {
        return this.$settings.AMMS[this.$store.state.exchange].banner
      }
      return this.$root.$icons.farm_image */
      return this.$settings.AMMS[this.$store.state.exchange].banner
    }
  },

  created () {
    var nameMap = Object.keys(SETTINGS.AMMS).reduce((a, key) => {
      a[key] = SETTINGS.AMMS[key].shortname
      return a
    }, {})
    var shortnames = Object.values(nameMap)
    if (!shortnames.includes(this.amm)) {
      this.$router.replace(`/`)
    } else if (this.$store.state.exchange === null || SETTINGS.AMMS[this.$store.state.exchange].shortname !== this.amm) {
      var requiredExchange = Object.keys(nameMap).find(key => nameMap[key] === this.amm)
      this.$store.commit('switchExchange', requiredExchange)
    }
    // this.$store.commit('switchExchange', 'Uniswap')
    // this.$root.ammLink(`/tokens`)
  }
}
</script>