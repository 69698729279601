<template>
  <div>
    <div v-if="loading" class="py-2 px-2 pr-6 align-center">
      <v-progress-circular
      indeterminate
      width="2"
      size="30"
      color="#aaa">
      </v-progress-circular>
    </div>

    <div v-if="true" class="d-flex">
      <presale-row v-for="presale of presales" :key="presale.presale_contract" :db_info="presale" :exchange="presale.exchange"></presale-row>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import PresaleRow from './upcoming-presale-row'
import moment from 'moment'

export default {
  components: {
    PresaleRow
  },

  data: () => ({
    model: 0,
    loading: false,
    hide_flagged: false,
    sorting: 'start_block',
    presales_unmerged: [],
    presales: [],

    stage: 0,
  }),

  computed: {
  },

  methods: {
    changeMode (stage) {
      this.stage = stage
      this.fetchAllPresales()
    },
    async getPresales (exchange) {
      var page = 0
      var filters = {
        // sort: 'profit',
        sort: this.sorting,
        sortAscending: false,
        search: '',
        hide_flagged: this.hide_flagged,
        show_hidden: false
      }
      var response = await axios.post(`${this.$settings.AMMS[exchange].server}/presales/search`, {filters: filters, page: page, stage: this.stage, rows_per_page: 20})
      var presales = response.data.rows

      var blockNumber = this.$store.state.blockNumz[this.$settings.AMMS[exchange].chain]
      var network = this.$settings.AMMS[exchange].chain
      presales.map(item => {
        item.exchange = exchange

        var numBlocks = item.start_block - blockNumber
        var seconds = numBlocks * this.$settings.CHAINS[network].blockTime
        var date = moment().add(seconds, 'seconds')
        item.unix = date.unix()
        return item
      })
      this.presales_unmerged.push(...presales)
    },
    async fetchAllPresales () {
      this.loading = true
      this.presales_unmerged = []
      await Promise.all([
        this.getPresales('Pancakeswap V2'),
        this.getPresales('Uniswap V2'),
        this.getPresales('Quickswap V1'),
        this.getPresales('Trader Joe V1'),
      ])
        .then(response => {
          this.presales_unmerged.sort((a, b) => a.unix - b.unix)
        })
        .catch(e => {})
        .then(() => {
          this.loading = false
          this.presales = this.presales_unmerged
        })
    }
  },

  created () {
    this.fetchAllPresales()
  }
}
</script>