<template>
  <div :class="['border-b', {'textFaint--text': epochHasPassed}]" @click="expandMenu = !expandMenu">
    <div class="d-flex align-center c-list pa-2">
      <div>
        <div :class="['font-weight-bold', {'primary--text': item.v2}]">
          ${{ dollarValue }}
        </div>
        <div class="caption textFaint--text">
          {{ amountHuman }} univ2
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="text-end">
        <div class="font-weight-bold">
          {{ dateFromNow }}
        </div>
        <div class="caption textFaint--text">
          {{ dateSimple }}
        </div>
      </div>
      <v-icon class="ml-3" color="textFaint" v-if="epochHasPassed">mdi-lock-open-outline</v-icon>
      <v-icon class="ml-3" color="primary" v-else>mdi-lock-outline</v-icon>
      <v-icon style="display: block;" small color="textFaint" :class="[{'rotate-180': expandMenu}]">mdi-chevron-down</v-icon>
    </div>

    <v-expand-transition>
      <div v-show="expandMenu" class="midground textFaint--text caption">
        <div class="pa-2">
          <div v-if="item.owner">
            Owner: {{ item.owner }}
          </div>
          <div v-if="item.lockID">
            ID: {{ item.lockID }}
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import moment from 'moment'
import { ethers } from 'ethers'

export default {
  props: {
    item: {},
    reserveUSD: {
      type: String
    },
    totalSupply: {
      type: String
    }
  },

  data: () => ({
    expandMenu: false
  }),

  computed: {
    percent () {
      if (this.totalSupply === '0') {
        return 0
      }
      var perc = ethers.BigNumber.from(this.item.amount).mul(10000000000).div(this.totalSupply)
      return perc / 10000000000
    },
    dollarValue () {
      var value = this.reserveUSD * this.percent
      return Number(value).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    amountHuman () {
      var amount = ethers.utils.formatUnits(this.item.amount, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    dateFromNow () {
      if (moment.unix(this.item.unlock_date).isValid()) {
        return moment.unix(this.item.unlock_date).fromNow()
      }
      return ''
    },
    dateSimple () {
      if (moment.unix(this.item.unlock_date).isValid()) {
        return moment.unix(this.item.unlock_date).format('DD/MM/YYYY HH:mm')
      }
      return ''
    },
    epochHasPassed () {
      return this.item.unlock_date < moment().unix()
    }
  }
}
</script>