<template>
  <tr v-if="!$vuetify.breakpoint.xs">
    <td class="caption">
      <copy-address :address="staker_info.user_address" color="text"></copy-address>
    </td>
    <td>
      {{ $root.formatAmount(staker_info.share_weight, pool_info.staking_token.decimals) }}
    </td>
    <td>
      {{ $root.formatAmount(staker_info.pending_reward, tokenHydrated.decimals) }}
    </td>
  </tr>
  <tr v-else>
    <td class="caption">
      <copy-address :address="staker_info.user_address" color="text"></copy-address>
    </td>
    <td class="caption">
      <div class="font-weight-bold">
        {{ $root.formatAmount(staker_info.share_weight, pool_info.staking_token.decimals) }}
      </div>
      <div class="textFaint--text">
        {{ $root.formatAmount(staker_info.pending_reward, tokenHydrated.decimals) }} {{ tokenHydrated.symbol }}
      </div>
    </td>
  </tr>
</template>

<script>

export default {

  props: {
    tokenHydrated: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    staker_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    pool_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },

  components: {
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },

  created () {
  }
}
</script>