<template>
  <v-dialog v-model="dialog" persistent max-width="450" content-class="br-20">
    <v-card class="br-20">
      <v-row class="pa-4 ma-0 font-weight-bold align-center">
        <v-spacer></v-spacer>
        
        <v-btn large @click="close" icon color="textFaint">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <v-card-text class="px-8">
        <div class="text-center">

          <v-progress-circular
          indeterminate
          size="120"
          color="#aaa">
            <img 
            :src="($settings.CHAINS[requiredChain] || {}).icon" 
            height="100px"
            width="100px"
            class="br-c">
          </v-progress-circular>

          <div class="mt-3 font-weight-medium">
            Confirm this transaction in your wallet
          </div>
          <div class="mt-6 textFaint--text font-weight-bold caption">
            Using a mobile wallet?
          </div>
          <div class="caption textFaint--text">
            Sometimes it helps to close the mobile app and restart it if the transaction is not shown in the wallet.
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    requiredChain: '',
  }),
  methods: {
    open (requiredChain) {
      this.requiredChain = requiredChain
      this.dialog = true
    },
    close () {
      this.dialog = false
    }
  }
}
</script>