import {store} from '@/store/index';
import { ethers } from "ethers";
import PRESALEABI from '@/smart-contracts/presales/presale-abis'
import ERC20 from '@/smart-contracts/erc20'
import PROVIDERS from '@/web3/providers'

const Self = {
  userHoldsSufficientRound1Token: async (_presale_settings_address, _user, network) => {
    var provider = store.state.ethers.provider
    if (network) {
      provider = PROVIDERS[network]
    }
    const PresaleFactory = new ethers.Contract(_presale_settings_address, PRESALEABI.presale_settings_abi, provider)
    var val = await PresaleFactory.userHoldsSufficientRound1Token(_user)
    return Boolean(val)
  },
  earlyAccessTokensLength: async (_presale_settings_address, network) => {
    var provider = store.state.ethers.provider
    if (network) {
      provider = PROVIDERS[network]
    }
    const PresaleFactory = new ethers.Contract(_presale_settings_address, PRESALEABI.presale_settings_abi, provider)
    var length = await PresaleFactory.earlyAccessTokensLength()
    return length.toString()
  },
  getEarlyAccessTokenAtIndex: async (_presale_settings_address, _index, network) => {
    var provider = store.state.ethers.provider
    if (network) {
      provider = PROVIDERS[network]
    }
    const PresaleFactory = new ethers.Contract(_presale_settings_address, PRESALEABI.presale_settings_abi, provider)
    var res = await PresaleFactory.getEarlyAccessTokenAtIndex(_index)

    var token = {}
    try {
      token = await ERC20.getERC20(res[0])
    } catch {}

    return {
      token: token,
      amount: res[1].toString()
    }
  },
}

export default Self