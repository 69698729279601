<template>
  <v-card class="pa-4">
    <div>
      Latest News Banner
    </div>
    <div class="mt-5 mb-1 d-flex align-center white pa-3 r-outlined br-20 background">
      <c-input :value.sync="title" placeholder="Title..">
      </c-input>
    </div>
    <div class="mb-1 d-flex align-center white pa-3 r-outlined br-20 background">
      <c-input :value.sync="link" placeholder="Link e.g. (https://)">
      </c-input>
    </div>
    <div class="text-end">
      <v-btn color="light-blue" class="white--text" @click="submitLatestNews">
        Submit
      </v-btn>
    </div>
  </v-card>
</template>

<script>
// import moment from 'moment'

export default {
  data: () => ({
    title: '',
    link: ''
  }),

  computed: {
  },

  methods: {
    async getLatestNews () {
      this.$userAxios.get(`/admin/get-news-banner`)
        .then(response => {
          this.title = response.data.title
          this.link = response.data.link
        })
        .catch(console.error)
    },
    async submitLatestNews () {
      var data = {title: this.title, link: this.link}
      this.$userAxios.post(`/admin/set-news-banner`, data)
        .then(response => {
          this.$root.globalHooks.getNewsBanner()
        })
        .catch(error => {
          console.log(error.response.data.errors[0])
          this.loginErrMsgMethodPassword = "Invalid login"
        })
    },
  },

  created () {
    this.getLatestNews()
  }
}
</script>