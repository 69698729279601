<template>
  <tr v-if="!$vuetify.breakpoint.xs">
    <td class="caption">
      <copy-address :address="staker_info.user_address" color="text"></copy-address>
    </td>
    <td>
      {{ $root.formatAmount(staker_info.tokens_staking, tokenHydrated.decimals) }} 
      <!-- {{ tokenHydrated.symbol }} -->
    </td>
    <td>
      <div class="d-flex align-center">
        <v-progress-linear height="30" :value="timeBoostProgress"
        color="lgrad-green"
        background-color=""
        style="width: 30px;"
        class="font-weight-bold br-c">
          <v-icon size="24" color="white">mdi-flash-circle</v-icon>
        </v-progress-linear>
        <span class="pl-1">
          {{ staker_info.time_boost_percentage / 100 }}%
        </span>
      </div>
    </td>
    <td>
      <div class="d-flex align-center">
        <v-progress-linear height="30" :value="UnclBoostProgress"
        color="lgrad-blue"
        background-color=""
        style="width: 30px;"
        class="font-weight-bold br-c">
          <v-icon size="24" color="white">mdi-flash-circle</v-icon>
        </v-progress-linear>
        <span class="pl-1">
          {{ staker_info.uncl_boost_percentage / 100 }}%
        </span>
      </div>
    </td>
    <td class="">
      {{ $root.formatAmount(staker_info.share_weight, tokenHydrated.decimals) }}
    </td>
    <td v-if="false">
      {{ staker_info.subscriptions_length }}
    </td>
    <td>
      <span v-if="pool_info.unlock_tokens" class="primary--text caption">
        Now (override)
      </span>
      <template v-else>
        <div class="caption textFaint--text">
          {{ unbondingDateHuman }}
        </div>
        <div class="caption">
          {{ unbondingDateFromNow }}
        </div>
      </template>
    </td>
    <td v-if="false">
      {{ staker_info }}
    </td>
  </tr>
  <tr v-else>
    <td class="caption">
      <div class="d-flex align-center">
        <v-icon size="50">mdi-account-circle</v-icon>
        <div>
          <copy-address :address="staker_info.user_address" color="text"></copy-address>

          <div class="d-flex align-center">
            <v-progress-linear height="24" :value="timeBoostProgress"
            color="lgrad-green"
            background-color=""
            style="width: 24px;"
            class="font-weight-bold br-c">
              <v-icon size="20" color="white">mdi-flash-circle</v-icon>
            </v-progress-linear>
            <span class="primary--text px-1">
              {{ staker_info.time_boost_percentage / 100 }}%
            </span>
            <v-progress-linear height="24" :value="UnclBoostProgress"
            color="lgrad-blue"
            background-color=""
            style="width: 24px;"
            class="font-weight-bold br-c">
              <v-icon size="20" color="white">mdi-flash-circle</v-icon>
            </v-progress-linear>
            <span class="light-blue--text pl-1">
              {{ staker_info.uncl_boost_percentage / 100 }}%
            </span>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div>
        {{ $root.formatAmount(staker_info.tokens_staking, tokenHydrated.decimals) }}
      </div>
      <div class="caption textFaint--text">
        {{ $root.formatAmount(staker_info.share_weight, tokenHydrated.decimals) }}
      </div>
    </td>
  </tr>
</template>

<script>
import moment from 'moment'
import { ethers } from 'ethers'

export default {

  props: {
    tokenHydrated: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    staker_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    pool_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },

  components: {
  },

  data: () => ({
  }),

  computed: {
    timeBoostProgress () {
      var maxBoost = this.pool_info.boost_options.reduce((a, item) => {
        if (item.boost_percentage > a) {
          a = item.boost_percentage
        }
        return a
      }, 0)
      if (maxBoost === 0) {
        return 0
      }
      var percent = ethers.BigNumber.from(this.staker_info.time_boost_percentage).mul(1000).div(maxBoost)
      return percent.toNumber() / 10
    },
    UnclBoostProgress () {
      if (this.pool_info.uncl_boost_max_percentage === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.staker_info.uncl_boost_percentage).mul(1000).div(this.pool_info.uncl_boost_max_percentage)
      return percent.toNumber() / 10
      // return 0
    },
    unbondingDateHuman () {
      var total_seconds = this.staker_info.unlock_date + this.staker_info.boost_additional_time
      if (moment.unix(total_seconds).isValid()) {
        return moment.unix(total_seconds).format('D MMM YYYY, HH:mm')
      }
      return ''
    },
    unbondingDateFromNow () {
      var total_seconds = this.staker_info.unlock_date + this.staker_info.boost_additional_time
      if (moment.unix(total_seconds).isValid()) {
        return moment.unix(total_seconds).fromNow()
      }
      return ''
    },
  },

  methods: {
  },

  created () {
  }
}
</script>