<template>
  <div>

    <template>
      
      <div class="text-center">
        <v-icon color="text" size="100">mdi-account-check-outline</v-icon>
        <div class="title">
          Whitelisted Users
        </div>
        <div class="caption textFaint--text mb-2">
          Whitelisted users can participate any time in round 0 of the presale, and bypass all UNCX and UNCL 
          holding requirements.
        </div>
        <div class="primary--text mb-2">
          TIP: Whitelists work very well when combined with Twitter marketing and Gleam campaigns.
        </div>
        <div class="caption mb-2">
          You have {{ presaleInfo.whitelist_max_participants - presaleInfo.whitelist_assigned}} whitelist allocations remaining
        </div>

        <v-progress-linear rounded striped height="20" :value="whitelistProgress" 
        color="primary" background-color="background" class="br-c">
          <v-icon size="18" color="text" class="mx-1">mdi-file-document-outline</v-icon>
          <div class="caption text--text font-weight-bold">
            {{ presaleInfo.whitelist_assigned }} / {{ presaleInfo.whitelist_max_participants }}
          </div>
        </v-progress-linear>
      </div>

      <whitelist-pager :address="address" ref="whitelistPager" class="mt-8"></whitelist-pager>

      <div class="text-center my-2">
        <v-btn x-large depressed rounded @click="tab = 0" :class="{'primary--text': tab === 0}">
          Add
        </v-btn>
        <v-btn x-large depressed rounded @click="tab = 1" :class="{'primary--text': tab === 1}">
          Remove
        </v-btn>
      </div>

      <div v-if="tab === 0" class="v-card pa-2">
        <div class="title d-flex px-3">
          Add to White list
          <v-spacer></v-spacer>
          <span class="textFaint--text">
            {{ whitelistAddLength }} / {{ presaleInfo.whitelist_max_participants - presaleInfo.whitelist_assigned }}
          </span>
        </div>
        <div class="px-3">
          One address per line, no comma
        </div>
        <div class="pa-3 caption textFaint--text">
          Example format:
          <div>
            0x51d4041eeef08fca44635945da368bb36173855e
          </div>
          <div>
            0x5d7c806c24a92a0ba565970dc1eac0253d8288a4
          </div>
        </div>
        <div class="d-flex align-center inputcolor pa-2 pb-0 r-outlined">
          <c-textarea :value.sync="whitelist" placeholder="0x09120912" rows="5"></c-textarea>
        </div>

        <div 
        v-if="whitelistAddLength > presaleInfo.whitelist_max_participants - presaleInfo.whitelist_assigned"
        class="pink--text text-center font-weight-bold pa-4">
          You do not have enough spots
        </div>

        <v-btn @click="whitelistAdd" color="primary" x-large block depressed>
          Add to whitelist
        </v-btn>
      </div>

      <div v-else-if="tab === 1" class="v-card pa-2">
        <div class="title px-3">
          Remove from whitelist
        </div>
        <div class="caption primary--text px-3">
          *You can only remove accounts who have not yet contributed.
        </div>
        <div class="px-3">
          One address per line, no comma
        </div>
        <div class="pa-3 caption textFaint--text">
          Example format:
          <div>
            0x51d4041eeef08fca44635945da368bb36173855e
          </div>
          <div>
            0x5d7c806c24a92a0ba565970dc1eac0253d8288a4
          </div>
        </div>
        <div class="d-flex align-center inputcolor pa-2 pb-0 r-outlined">
          <c-textarea :value.sync="unwhitelist" placeholder="0x09120912" rows="5"></c-textarea>
        </div>

        <v-btn @click="whitelistRemove" color="primary" x-large block depressed>
          Remove from whitelist
        </v-btn>
      </div>

    </template>
  </div>
</template>

<script>
import WhitelistPager from './whitelist-pager'
import PresaleContract from '@/smart-contracts/presales/v6/presale-contract'
import TxWrapper from '@/web3/tx-wrapper-2';
import { ethers } from 'ethers'

export default {
  props: {
    address: {
      type: String
    }
  },

  components: {
    WhitelistPager
  },

  data: () => ({
    tab: 0,
    whitelistOnly: true,
    whitelist: '',
    unwhitelist: '',
    presaleInfo: {
      whitelist_max_participants: '0',
      whitelist_assigned: '0'
    }
  }),

  computed: {
    whitelistProgress () {
      var max_participants = this.presaleInfo.whitelist_max_participants === '0' ? 1 : this.presaleInfo.whitelist_max_participants
      var percent = ethers.BigNumber.from(this.presaleInfo.whitelist_assigned).mul(1000).div(max_participants)
      return percent.toNumber() / 10
    },
    whitelistAddLength () {
      return this.whitelist.split("\n").length
    }
  },

  methods: {
    async getPresaleInfo () {
      var response = await PresaleContract.getInfo(this.address)
      this.presaleInfo = response.presale_info
    },
    async whitelistAdd () {
      var addresses = this.whitelist.split("\n").map(item => item.trim())
      addresses = addresses.filter(item => item.length > 0)
      TxWrapper.doTransaction(PresaleContract.editWhitelist,
        [this.address, addresses, true], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          // this.getPresaleInfo()
          this.$refs.whitelistPager.refreshPage()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.refresh()
        })
    },
    async whitelistRemove () {
      // this.createLoading = true
      var addresses = this.unwhitelist.split("\n").map(item => item.trim())
      addresses = addresses.filter(item => item.length > 0)
      TxWrapper.doTransaction(PresaleContract.editWhitelist,
        [this.address, addresses, false], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          this.$refs.whitelistPager.refreshPage()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.refresh()
        })
    },
    refresh () {
      this.getPresaleInfo()
    }
  },

  created () {
    this.refresh()
  }
  
}
</script>