<template>

  <div class="v-card br-20 pa-6">

    <div class="title mb-4">
      Unicrypt Admin
    </div>

    <div class="">
      Allow drain primary token
    </div>

    <div class="py-3 textFaint--text">
      Allow draining the primary token (for emergency use only, this allows the pool owner to remove
      rewards even users have which they havent yet harvested, basically stealing their farm)
    </div>

    <div>
      <v-btn rounded :color="reward_pool.allow_drain_primary ? 'primary' : ''" @click="allowDrainPrimary(true)">
        True
      </v-btn>
      <v-btn rounded :color="!reward_pool.allow_drain_primary ? 'primary' : ''" @click="allowDrainPrimary(false)">
        False
      </v-btn>
    </div>

    <div class="mt-10">
      Apply 1% Tax
    </div>

    <div class="py-3 textFaint--text">
      This is for abusers. If someone makes a farm with 1 token, then sends 1 billion tokens to the reward contract to bypass fees. 
      This function sends 1% of the current reward pool balance to unicrypt fee address. Can only be called once.
    </div>

    <div>
      <v-btn rounded color="primary" @click="applyOnePercentTax" :disabled="reward_pool.one_tax_applied">
        Withdraw 1% of reward pool
      </v-btn>
    </div>

  </div>

</template>

<script>
import RewardPoolContract from '@/smart-contracts/farms-v2/normal/reward-pool/v1/reward-pool'
import TxWrapper from '@/web3/tx-wrapper-2';

export default {

  props: {
    address: null,
    reward_pool: {
      type: Object,
      default: () => {
        return {
          reward_token: {}
        }
      }
    }
  },

  components: {
  },

  data: () => ({
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
  },

  watch: {
    sEthersWatcher () {
    },
  },

  methods: {
    async allowDrainPrimary (_allow) {
      if (_allow === this.reward_pool.allow_drain_primary) {
        return
      }
      TxWrapper.doTransaction(RewardPoolContract.allowDrainPrimary,
        [this.address, _allow], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(response => {
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
    async applyOnePercentTax (_allow) {
      TxWrapper.doTransaction(RewardPoolContract.applyOnePercentTax,
        [this.address], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(response => {
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
  },

  created () {
  }
}
</script>