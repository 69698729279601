<template>
  <span>
    <v-menu
    v-model="chartPopup"
    offset-y
    open-on-hover
    content-class="br-20 pa-0"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          text
          small
          rounded
          v-bind="attrs"
          v-on="on"
          color="textFaint"
          class="font-weight-regular"
          @click.stop="chartPopup = true"
        >
          chart
          <v-icon small>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list style="min-width: 250px;" class="foreground br-20 pa-0">
        <a :href="dextLink" target="_blank" class="deadlink">
          <div class="d-flex align-center c-list px-4 py-3 border-b">
            <img 
            src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xfB7B4564402E5500dB5bB6d63Ae671302777C75a/logo.png"
            height="20px"
            width="20px">
            <div>
              <div class="ml-4 textFaint--text caption">
                View on Dext
                <v-icon x-small>mdi-arrow-top-right</v-icon>
              </div>
            </div>
          </div>
        </a>
        <a v-if="cExchange === 'Pancakeswap V2'" :href="`https://poocoin.app/tokens/${token_address}`" target="_blank" class="deadlink">
          <div class="d-flex align-center c-list px-4 py-3 border-b">
            <img 
            src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xB27ADAfFB9fEa1801459a1a81B17218288c097cc/logo.png"
            height="20px"
            width="20px">
            <div>
              <div class="ml-4 textFaint--text caption">
                View on Poocoin
                <v-icon x-small>mdi-arrow-top-right</v-icon>
              </div>
            </div>
          </div>
        </a>
      </v-list>
    </v-menu>
  </span>
</template>

<script>

export default {
  props: {
    exchange: null,
    token_address: null,
    pair_address: null
  },

  data: () => ({
    chartPopup: false,
  }),

  computed: {
    dextLink () {
      if (this.cExchange === 'Uniswap V2') {
        return `https://www.dextools.io/app/uniswap/pair-explorer/${this.pair_address}`
      } else if (this.cExchange === 'Pancakeswap V2' || this.cExchange === 'Pancakeswap V1') {
        return `https://www.dextools.io/app/pancakeswap/pair-explorer/${this.pair_address}`
      }
      return null
    },
    cExchange () {
      return this.exchange || this.$store.state.exchange
    },
  }
}
</script>