import {store} from '@/store/index';
import { ethers } from "ethers";
import STAKE_POOL_ABI from './abis'
import PROVIDERS from '@/web3/providers'

const Self = {
  getPoolInfo: async (_poolAddress, network = null) => {
    var provider = network ? PROVIDERS[network] : store.state.ethers.provider
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, provider)
    var res = await StakePool.getPoolInfo()
    return {
      creator: res[0],
      num_stakers: res[1].toString(),
      num_reward_pools: res[2].toString(),
      max_reward_subscriptions: res[3].toString(),
      share_weight_total: res[4].toString(),
      reward_creator: res[5],
      oracle_address: res[6],
      decimals: res[7].toString(),
    }
  },
  stakeTokens: (_poolAddress, _stakeOwner, _stake_amount, _lock_period, _boost_time_period, _boost_percentage, _acceptedStakeModFee, _acceptedStakeModFeeToken) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var stakeFeeTokenIsGasToken = _acceptedStakeModFeeToken === ethers.constants.AddressZero
    var msgValue = stakeFeeTokenIsGasToken ? _acceptedStakeModFee : '0'
    var res = StakePool.stakeTokens(_stakeOwner, _stake_amount, _lock_period, _boost_time_period, _boost_percentage, _acceptedStakeModFee, _acceptedStakeModFeeToken, {value: msgValue})
    return res
  },
  setOracle: (_poolAddress, _oracle) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.setOracle(_oracle)
    return res
  },
  oracleUpdateUsers: (_poolAddress, userPositions) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.oracleUpdateUsers(userPositions)
    return res
  },
  boostWeightWithUNCL: (_poolAddress, _unclAmount) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.boostWeightWithUNCL(_unclAmount)
    return res
  },
  adminDrainToken: (_poolAddress, _token, _amount) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminDrainToken(_token, _amount)
    return res
  },
  adminSetRewardCreator: (_poolAddress, _creator) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminSetRewardCreator(_creator)
    return res
  },
  adminSetMinStakingPeriod: (_poolAddress, _lock_period) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminSetMinStakingPeriod(_lock_period)
    return res
  },
  adminSetMinMaxStakeAmount: (_poolAddress, _minStakeAmount, _maxStakeAmount) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminSetMinMaxStakeAmount(_minStakeAmount, _maxStakeAmount)
    return res
  },
  adminSetUnlockTokens: (_poolAddress, _unlockTokens) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminSetUnlockTokens(_unlockTokens)
    return res
  },
  adminSetStakeModificationFee: (_poolAddress, _amount, _feeToken, _feeAddress) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminSetStakeModificationFee(_amount, _feeToken, _feeAddress)
    return res
  },
  transferPoolOwnership: (_poolAddress, _newOwner) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.transferPoolOwnership(_newOwner)
    return res
  },
  withdrawTokens: (_poolAddress, _withdraw_amount, _acceptedStakeModFee, _acceptedStakeModFeeToken) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var stakeFeeTokenIsGasToken = _acceptedStakeModFeeToken === ethers.constants.AddressZero
    var msgValue = stakeFeeTokenIsGasToken ? _acceptedStakeModFee : '0'
    var res = StakePool.withdrawTokens(_withdraw_amount, {value: msgValue})
    return res
  },
  harvestMultiple: (_poolAddress, _rewardPools) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.harvestMultiple(_rewardPools)
    return res
  },
  getUserInfo: async (_poolAddress, _user) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.provider)
    var res = await StakePool.getUserInfo(_user)
    return {
      user_address: res[0],
      share_weight: res[1].toString(),
      time_boost_percentage: res[2].toString(),
      uncl_boost_percentage: res[3].toString(),
      subscriptions_length: res[4].toNumber()
    }
  },
  sharesToTokens: async (_poolAddress, _numShares) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.provider)
    var res = await StakePool.sharesToTokens(_numShares)
    return res.toString()
  },
  tokensToShares: async (_poolAddress, _numTokens) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.provider)
    var res = await StakePool.tokensToShares(_numTokens)
    return res.toString()
  },

  // UNCX ADMIN FUNCTIONS
  setStakeModFeeState: (_poolAddress, _state) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.setStakeModFeeState(_state)
    return res
  },
  overrideUNCLBoostInfo: (_poolAddress, _boostToken, _boostFeeAddress, _boostMaxPercent, _boostMaxAmount) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.overrideUNCLBoostInfo(_boostToken, _boostFeeAddress, _boostMaxPercent, _boostMaxAmount)
    return res
  },
}

export default Self