<template>
  <div>

    <div v-if="loading" class="text-center pa-8">
      <v-progress-circular
      indeterminate
      size="120"
      width="2"
      color="#aaa">
        <img 
        src="@/assets/img/UNCX_fill.svg" 
        height="110px"
        class="greyscale"
        width="110px">
      </v-progress-circular>
    </div>

    <v-scroll-y-transition mode="out-in">
      <div v-if="!loading">
        
        <template v-if="num_stakers !== '0'">
          <div class="textFaint--text d-flex align-center pa-2">
            Subscribers ({{ num_stakers }})
            <v-spacer></v-spacer>
            <v-btn @click="refresh" text rounded color="textFaint">
              <span class="font-weight-medium mr-1">Refresh</span>
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </div>

          <div style="overflow: auto;">
            <table class="c-table" style="width: 100%">
              <tr v-if="!$vuetify.breakpoint.xs" class="caption textFaint--text">
                <th>
                  User
                </th>
                <th>
                  Share weight
                </th>
                <th>
                  Unharvested
                </th>
              </tr>
              <tr v-else class="caption textFaint--text">
                <th>
                  <div>
                    User
                  </div>
                </th>
                <th>
                  <div>
                    Share weight
                  </div>
                  <div class="caption">
                    Unharvested {{ tokenHydrated.symbol }}
                  </div>
                </th>
              </tr>
              <staker-row v-for="staker of stakers" :key="staker.user_address" :staker_info="staker" :tokenHydrated="tokenHydrated" :pool_info="pool_info" :share_decimals="share_decimals">
              </staker-row>
            </table>
          </div>
        </template>
        <div v-else class="v-card pa-4">
          No one is subscribed to this reward pool.
        </div>

      </div>
    </v-scroll-y-transition>

  </div>
</template>

<script>
import StakePoolPaginatorContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool-paginator'
import StakerRow from './reward-staker-view-row'

export default {

  props: {
    tokenHydrated: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    share_decimals: {
      type: String,
      default: () => {
        return '0'
      }
    },
    reward_pool_address: null,
    pool_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },

  components: {
    StakerRow
  },

  data: () => ({
    num_stakers: 0,
    stakers: [],
    loading: false
  }),

  methods: {
    async refresh () {
      this.loading = true
      this.num_stakers = await StakePoolPaginatorContract.getNumRewardStakers(this.reward_pool_address)
      this.stakers = await StakePoolPaginatorContract.getRewardStakers(this.reward_pool_address, 0, this.num_stakers)
      this.loading = false
    },
  },

  created () {
    this.refresh()
  }
}
</script>