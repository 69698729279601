<template>
  <v-container style="max-width: 600px;">

    <page-v1-locker v-if="[
    'Uniswap V2',
    'Sushiswap V1',
    'Pancakeswap V2',
    'Pancakeswap V1',
    'Julswap V1',
    'Quickswap V1',
    'Honeyswap V1',
    'Uniswap V2 - Kovan'
    ].includes($store.state.exchange)" :address="address"></page-v1-locker>

    <page-v2-locker v-else :address="address"></page-v2-locker>
    
  </v-container>
</template>

<script>
import PageV1Locker from './PageV1Locker'
import PageV2Locker from './PageV2Locker'

export default {
  components: {
    PageV1Locker,
    PageV2Locker
  },

  props: {
    address: {
      type: String
    }
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },

  created () {
  }
}
</script>