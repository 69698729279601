import Vue from 'vue'

import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import { store } from './store'
import vuetify from './plugins/vuetify'
import VueClipboard from 'vue-clipboard2'
import SETTINGS from '@/store/settings'
import SERVERS from '@/store/servers'
import ICONS from '@/store/icons'

import http from './plugins/axios'
import userAxios from './plugins/user-axios'

import '@/components/globals'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import '@/assets/css/main.scss'
import './registerServiceWorker'

Vue.use(VueClipboard)

Vue.use(VueGtag, {
  config: { id: "G-X5ZLQMB9HJ" }
}, router);

Vue.config.productionTip = false
Vue.prototype.$axios = http
Vue.prototype.$userAxios = userAxios
Vue.prototype.$settings = SETTINGS
Vue.prototype.$servers = SERVERS
Vue.prototype.$icons = ICONS

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
