import {store} from '@/store/index';
import { ethers } from "ethers";
import ABI from './abis'
import ERC20 from '@/smart-contracts/erc20/pager'

const Self = {
  getInfo: async (_rewardPool, network = null) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.provider)
    var res = await RewardPool.getRewardInfo()
    var reward_token = {}
    try {
      reward_token = await ERC20.getERC20(res[0][0], network)
    } catch (e) {}
    var share_multiplier
    try {
      share_multiplier = await RewardPool.SHARE_MULTIPLIER()
      share_multiplier = share_multiplier.toString()
    } catch (e) {}
    return {
      pool_address: _rewardPool,
      reward_token: reward_token,
      start_block: res[0][1].toString(),
      block_reward: res[0][2].toString(),
      bonus_end_block: res[0][3].toString(),
      bonus: res[0][4].toString(),
      end_block: res[0][5].toString(),
      last_reward_block: res[0][6].toString(),
      acc_reward_per_share: res[0][7].toString(),
      farmable_supply: res[0][8].toString(),
      num_farmers: res[0][9].toString(),
      shares_total: res[0][10].toString(),

      allow_drain_primary: Boolean(res[1][0]),
      pool_balance: res[1][1].toString(),
      one_tax_applied: Boolean(res[1][2]),
      share_multiplier: share_multiplier
    }
  },
  getUserInfo: async (_rewardPool, _user, network = null) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.provider)
    var res = await RewardPool.getUserInfo(_user)
    return {
      share_weight: res[0][0].toString(),
      reward_debt: res[0][1].toString(),
      unharvested_shares: res[0][2].toString(),
      unharvested_rewards: res[1].toString(),
    }
  },
  subscribe: (_rewardPool, _user) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.signer)
    var res = RewardPool.subscribe(_user)
    return res
  },
  unsubscribe: (_rewardPool, _user) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.signer)
    var res = RewardPool.unsubscribe(_user)
    return res
  },
  emergencyUnsubscribe: (_rewardPool) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.signer)
    var res = RewardPool.emergencyUnsubscribe()
    return res
  },
  harvest: (_rewardPool, _user) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.signer)
    var res = RewardPool.harvest(_user)
    return res
  },
  topUp: (_rewardPool, _amount) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.signer)
    var res = RewardPool.topUp(_amount)
    return res
  },
  increaseBlockReward: (_rewardPool, _amount) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.signer)
    var res = RewardPool.increaseBlockReward(_amount)
    return res
  },
  drainToken: (_rewardPool, _token, _amount) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.signer)
    var res = RewardPool.drainToken(_token, _amount)
    return res
  },
  // UNCX ADMIN FUNCTIONS
  allowDrainPrimary: (_rewardPool, _allow) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.signer)
    var res = RewardPool.allowDrainPrimary(_allow)
    return res
  },
  applyOnePercentTax: (_rewardPool) => {
    var sEthers = store.state.ethers
    const RewardPool = new ethers.Contract(_rewardPool, ABI.reward_pool_abi, sEthers.signer)
    var res = RewardPool.applyOnePercentTax()
    return res
  },
}

export default Self