<template>
  <div class="pa-4">

    <div v-if="tab === 'main'">
      <div v-if="false" class="text-center py-6">
        <v-icon size="60" color="textFaint">mdi-settings</v-icon>
        <div class="title textFaint--text">
          SETTINGS
        </div>
      </div>

      <v-card class="pa-3 c-list" @click="tab = 'stake'">
        <div class="title">
          Stake pool
        </div>
        <div class="textFaint--text">
          Adjust stake pool settings
        </div>
      </v-card>

      <v-card class="pa-3 c-list" @click="tab = 'reward'">
        <div class="title">
          Reward pool
        </div>
        <div class="textFaint--text">
          Add a reward pool
        </div>
      </v-card>

      <v-card class="pa-3 c-list" @click="tab = 'oracle'">
        <div class="title">
          Oracle settings
        </div>
        <div class="textFaint--text">
          Adjust oracle mappings
        </div>
      </v-card>

      <v-card v-if="$store.state.adminIds.includes($store.state.user.jwtObject.user_id)" class="pa-3 c-list" @click="tab = 'super'">
        <div class="title">
          Super user
          <v-icon small color="text">mdi-atom</v-icon>
        </div>
        <div class="textFaint--text">
          Edit settings
        </div>
      </v-card>
    </div>

    <div v-else class="pb-2">
      <v-btn x-large text @click="tab = 'main'" color="textFaint" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <spool-settings v-if="tab === 'stake'" :address="address" @hard-refresh="$emit('hard-refresh')"></spool-settings>

    <reward-generator v-if="tab === 'reward'" :stake_pool_address="address" :pool_info="pool_info" @pool-created="$emit('close-settings')"></reward-generator>

    <oracle-settings v-if="tab === 'oracle'" :address="address" :pool_info="pool_info"></oracle-settings>

    <super-user-settings v-if="tab === 'super'" :address="address" :pool_info="pool_info" @hard-refresh="$emit('hard-refresh')"></super-user-settings>

  </div>
</template>

<script>
import SpoolSettings from './settings-spool'
import RewardGenerator from '@/views/farms/rewards/generator/rewardgen-02'
import SuperUserSettings from './settings-super'
import OracleSettings from './settings-oracle'

export default {

  props: {
    address: null,
    pool_info: {
      type: Object,
      default: () => {
        return {
          share_weight_total: '0',
          min_stake_amount: '0',
          max_stake_amount: '0',
        }
      }
    },
  },

  components: {
    SpoolSettings,
    RewardGenerator,
    SuperUserSettings,
    OracleSettings
  },

  data: () => ({
    tab: 'main', // main, reward, stake, super
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
  },

  methods: {
  },

  created () {
  }
}
</script>