<template>
  <span>
    <v-menu
    offset-y
    content-class="br-20"
    transition="fade-transition"
    :open-on-hover="!$vuetify.breakpoint.xs"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          depressed
          rounded class="pa-5"
          color="foreground"
          v-bind="attrs"
          v-on="on"
        >
          <img 
          :src="$settings.CHAINS[localValue].icon" 
          height="20px"
          width="20px"
          class="br-20">
          <span class="ml-3 text-uppercase">
            {{ ($settings.CHAINS[localValue] || {}).shortName }}
          </span>
          <v-icon small>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list class="background br-20 ma-0 pa-0">
        <v-list-item v-for="chain_key in local_chains" :key="chain_key" @click="localValue = chain_key">
          <v-list-item-title class="d-flex align-center caption">
            <img 
            :src="$settings.CHAINS[chain_key].icon" 
            height="24px"
            width="24px"
            class="br-20">
            <span class="ml-2">
              {{ $settings.CHAINS[chain_key].name }}
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
export default {
  props: {
    chains: {
      type: Array,
      default: () => (
        ['Mainnet', 'BSC_MAINNET', 'AVAX', 'Kovan', 'xDai', 'Matic']
      )
    },
    value: {
      type: String,
      default: 'Mainnet'
    }
  },

  data: () => ({
    local_chains: [],
  }),

  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (nv) {
        this.$emit('update:value', nv)
        this.$emit('on-update', nv)
      }
    }
  },
  
  methods: {
  },

  created () {
    this.local_chains = this.chains
  }
}
</script>