<template>
  <v-dialog v-model="dialog" persistent max-width="450" content-class="br-20">
    <v-card class="br-20">
      <v-row class="pa-4 ma-0 font-weight-bold align-center">
        <v-spacer></v-spacer>
        
        <v-btn large @click="close" icon color="textFaint">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <v-card-text class="px-8">
        <div class="text-center">
          
          <div class="d-flex align-center justify-center px-8 pb-4">
            <img 
            :src="currentChainIcon" 
            height="80px"
            width="80px"
            class="br-c">
            <div class="textFaint flex" style="height: 1px;">
            </div>
            <v-icon size="32px" color="foreground" class="text br-c">mdi-chevron-right</v-icon>
            <div class="textFaint flex" style="height: 1px;">
            </div>
            <img 
            :src="($settings.CHAINS[requiredChain] || {}).icon" 
            height="80px"
            width="80px"
            class="br-c">
          </div>
          <div class="mt-3 title">
            Network change required
          </div>
          <div class="textFaint--text font-weight-medium">
            Switch your wallet to
            <span class="font-weight-medium">
              {{ ($settings.CHAINS[requiredChain] || {}).name }}
            </span>
            to continue
          </div>

          <!-- <div>
            {{ requiredChainId }} - {{ ethersChainId }}
          </div> -->

          <div v-if="chains[requiredChain]" class="mt-3">
            <v-btn block large rounded color="text" class="background--text font-weight-bold" @click="switchNetwork">
              Switch Network
            </v-btn>
          </div>

        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    requiredChain: '',
    requiredChainId: null,

    chains: {
      'BSC_MAINNET': {
        chainId: '0x38',
        chainName: "Binance Smart Chain",
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
        blockExplorerUrls: ['https://bscscan.com/'],
        nativeCurrency: {
          name: "BNB",
          symbol: "BNB",
          decimals: 18
        }
      },
      'BSC_TESTNET': {
        chainId: '0x61',
        chainName: "BSC Testnet",
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        blockExplorerUrls: ['https://testnet.bscscan.com/'],
        nativeCurrency: {
          name: "BNB",
          symbol: "BNB",
          decimals: 18
        }
      },
      'AVAX': {
        chainId: '0xa86a',
        chainName: "Avalanche Network",
        rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
        blockExplorerUrls: ['https://snowtrace.io/'],
        nativeCurrency: {
          name: "AVAX",
          symbol: "AVAX",
          decimals: 18
        }
      },
      'xDai': {
        chainId: '0x64',
        chainName: "xDAI Chain",
        rpcUrls: ["https://dai.poa.network"],
        iconUrls: [
          "https://xdaichain.com/fake/example/url/xdai.svg",
          "https://xdaichain.com/fake/example/url/xdai.png"
        ],
        nativeCurrency: {
          name: "xDAI",
          symbol: "xDAI",
          decimals: 18
        }
      },
      'Matic': {
        chainId: '0x89',
        chainName: "Polygon",
        rpcUrls: ["https://polygon-rpc.com/"],
        blockExplorerUrls: ['https://polygonscan.com/'],
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18
        }
      },
      /* 'Kovan': { // THIS DOES NOT WORK YET, AWAITING METAMASK EIP UPDATE TO SUPPORT METAMASK DEFAULT CHAINS
        chainId: '0x2a',
        chainName: "Kovan",
        rpcUrls: ["https://kovan.infura.io/v3/undefined"],
        blockExplorerUrls: ["https://kovan.etherscan.io"],
        nativeCurrency: {
          name: "ETH",
          symbol: "ETH",
          decimals: 18
        }
      }, */
      'Hardhat': {
        chainId: '0x539',
        chainName: "Hardhat",
        rpcUrls: ["http://127.0.0.1:8545/"],
        nativeCurrency: {
          name: "ETH",
          symbol: "ETH",
          decimals: 18
        }
      }
    },

    resolve: null,
    reject: null,
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    ethersChainId () {
      return this.sEthers.network
    },
    currentChainIcon () {
      var currentChainKey = null
      for (var key of Object.keys(this.$settings.CHAINS)) {
        if (this.$settings.CHAINS[key].chainId === this.sEthers.network) {
          currentChainKey = key
          break
        }
      }
      return (this.$settings.CHAINS[currentChainKey] || {}).icon
    },
  },

  watch: {
    ethersChainId () {
      if (this.ethersChainId === this.requiredChainId) {
        this.closeSuccessfully()
      }
    }
  },
  
  methods: {
    open (requiredChain) {
      this.requiredChain = requiredChain
      this.requiredChainId = this.$settings.CHAINS[requiredChain].chainId
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    close () {
      this.dialog = false
      this.reject()
    },
    closeSuccessfully () {
      this.dialog = false
      this.resolve()
      // setTimeout(() => { this.resolve() }, 4000)
    },

    async switchNetwork () {
      if (this.sEthers.coinbase) {
        window.ethereum.request({
          method: 'eth_chainId',
          params: [],
        })
          .then(res => {
            // console.log(res)
          })

        window.ethereum.request({
          jsonrpc: "2.0",
          method: 'wallet_addEthereumChain',
          params: [this.chains[this.requiredChain]],
        })
          .then(success => {
            // console.log(success)
          })
          .catch(console.error)
      }
    },
  }
}
</script>