<template>
  <div class="pa-4 v-card midground br-8">
    <div>
      Servers
    </div>
    <div class="pt-4">
      <chain-servers></chain-servers>
    </div>
  </div>
</template>

<script>
import ChainServers from './chain-servers'

export default {
  components: {
    ChainServers
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },

  created () {
  }
}
</script>