<template>
  <v-container style="max-width: 600px;">

    <v-slide-x-transition appear>

      <div :class="['pa-4', {'foreground v-card br-20': $vuetify.breakpoint.xs}, {'v-card br-20 foreground': !$vuetify.breakpoint.xs}]">
        <div>
          
          <a class="c-list mb-2 br-20 pa-4 midground pr-6 mx-2 d-flex align-center deadlink" href="https://defillama.com/protocol/unicrypt" target="_blank">
            <img 
            :src="$root.$icons.defi_llama" 
            height="60px"
            width="60px">
            <div class="pl-4">
              <div>
                <span class="title font-weight-regular">
                  ${{ tvalHumanFormat(defi_lama_tvl) }} 
                </span>
                <span class="">
                  liquidity locked
                </span>
              </div>
              <div class="caption primary--text">
                View our Liquidity Locker TVL on Defi Llama
              </div>
            </div>
          </a>

          <div class="display-1 textFaint--text font-weight-regular py-6">
            Lock Liquidity
          </div>

          <div class="py-2 mb-5">
            <div class="pl-2 textFaint--text mb-2">
              Selected network
            </div>
            <div @click="$root.$dialog.chainSwitcher.open()" class="v-card d-flex align-center title font-weight-medium c-list pa-4 br-20 mb-2">
              <img 
              :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
              height="40px"
              width="40px"
              class="mr-3 br-20">
              {{ ($settings.CHAINS[$store.state.requiredNetwork] || {}).name }}
              <v-icon small color="textFaint">mdi-chevron-down</v-icon>
            </div>
          </div>

          <div class="pl-2 textFaint--text mb-2">
            Lock Liquidity on which exchange?
          </div>

        </div>

        <div v-for="amm of amms" :key="amm" class="v-card br-20 mb-1 d-flex align-center c-list pa-4" @click="selectExchange(amm)">
          <img 
          :src="$settings.AMMS[amm].icon" 
          height="30px"
          width="30px"
          class="mr-3 br-20">
          <div>
            <div class="">
              {{ amm }}
            </div>
            <!-- <div class="caption textFaint--text">
              {{ $settings.CHAIN_DISPLAY_NAME[$store.state.requiredNetwork] }}
            </div> -->
          </div>
        </div>
      </div>
    </v-slide-x-transition>
    
  </v-container>
</template>

<script>
import axios from 'axios'

export default {

  data: () => ({
    amms: [], // ['uniswap', 'sushiswap']
    defi_lama_tvl: 0,
  }),

  computed: {
    requiredNetwork () {
      return this.$store.state.requiredNetwork
    },
  },

  watch: {
    requiredNetwork () {
      this.refresh()
    }
  },

  methods: {
    selectExchange (exchange) {
      this.$store.commit('switchExchange', exchange)
      this.$root.ammLink(`/locker`)
    },
    refresh () {
      this.amms = Object.keys(this.$settings.AMMS).filter(key => this.$settings.AMMS[key].chain === this.$store.state.requiredNetwork)
    },
    async getTVAL () {
      var response = await axios.get(`https://api.llama.fi/tvl/unicrypt`)
      this.defi_lama_tvl = response.data
    },
    tvalHumanFormat (tval) {
      var amount = parseInt(tval)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
    },
  },

  created () {
    this.refresh()
    this.getTVAL()
  }
}
</script>