// Specify the Unicrypt Stake Pools

const Self = {
  TIERS: {
    1: {
      'Mainnet': '0x887E81cab04461620A5fF196048Bba38d9Dc96e4'
    },
    2: {
      'Mainnet': '0x73f5876ECd9fAbF7d359Daf7Be7610B276641549'
    },
    3: {
      'Mainnet': '0xf4868E785457280dd48B10a02D8F03BBfD5B721f'
    },
  }
}

module.exports = Self