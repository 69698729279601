var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"600px"}},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('div',{class:['mb-16', {'foreground v-card br-20': _vm.$vuetify.breakpoint.xs}, {'v-card br-20 foreground': !_vm.$vuetify.breakpoint.xs}]},[_c('div',{staticClass:"py-6 title text-center textFaint--text"},[_vm._v(" Terms and conditions ")]),_c('router-link',{attrs:{"to":"/terms/privacy-policy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"d-flex align-center c-list pa-4 border-b",on:{"click":navigate}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"text","size":"40"}},[_vm._v("mdi-information-outline")]),_c('div',[_c('div',{staticClass:"title"},[_vm._v(" Privacy Policy ")])])],1)]}}])}),_c('router-link',{attrs:{"to":"/terms/general"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"d-flex align-center c-list pa-4 border-b",on:{"click":navigate}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"text","size":"40"}},[_vm._v("mdi-information-outline")]),_c('div',[_c('div',{staticClass:"title"},[_vm._v(" General ")]),_c('div',{staticClass:"caption textFaint--text"},[_vm._v(" Terms and conditions ")])])],1)]}}])}),_c('router-link',{attrs:{"to":"/terms/farms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"d-flex align-center c-list pa-4 border-b",on:{"click":navigate}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"text","size":"40"}},[_vm._v("mdi-sprout-outline")]),_c('div',[_c('div',{staticClass:"title"},[_vm._v(" Farms ")]),_c('div',{staticClass:"caption textFaint--text"},[_vm._v(" Terms and conditions ")])])],1)]}}])}),_c('router-link',{attrs:{"to":"/terms/ilos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"d-flex align-center c-list pa-4 border-b",on:{"click":navigate}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"text","size":"40"}},[_vm._v("mdi-rocket")]),_c('div',[_c('div',{staticClass:"title"},[_vm._v(" ILOs (Initial Liquidity Offerings) ")]),_c('div',{staticClass:"caption textFaint--text"},[_vm._v(" Terms and conditions ")])])],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }