<template>
  <div class="v-card br-20">
    <div class="py-4 px-2 pr-6 d-flex align-center">
      <v-btn text :color="stage === 0 ? 'text' : 'textFaint'" @click="changeMode(0)">
        Upcoming presales
      </v-btn>
      <v-btn text :color="stage === 1 ? 'text' : 'textFaint'" @click="changeMode(1)">
        Live presales
      </v-btn>
      <v-spacer></v-spacer>
      <v-progress-circular
      v-if="loading"
      indeterminate
      width="2"
      size="30"
      color="#aaa">
      </v-progress-circular>
    </div>

    <table class="c-table" style="width: 100%">
      <presale-row v-for="presale of presales" :key="presale.presale_contract" :db_info="presale" :exchange="presale.exchange"></presale-row>
    </table>

  </div>
</template>

<script>
import axios from 'axios'
import PresaleRow from './upcoming-presale-row'
import moment from 'moment'

export default {
  components: {
    PresaleRow
  },

  data: () => ({
    loading: false,
    hide_flagged: false,
    sorting: 'start_block',
    presales_unmerged: [],
    presales: [],

    stage: 0,
  }),

  methods: {
    changeMode (stage) {
      this.stage = stage
      this.fetchAllPresales()
    },
    async getPresales (exchange) {
      var page = 0
      var filters = {
        // sort: 'profit',
        sort: this.sorting,
        sortAscending: false,
        search: '',
        hide_flagged: this.hide_flagged,
        show_hidden: false
      }
      var response = await axios.post(`${this.$settings.AMMS[exchange].server}/presales/search`, {filters: filters, page: page, stage: this.stage, rows_per_page: 20})
      var presales = response.data.rows

      var blockNumber = this.$store.state.blockNumz[this.$settings.AMMS[exchange].chain]
      var network = this.$settings.AMMS[exchange].chain
      presales.map(item => {
        item.exchange = exchange

        var numBlocks = item.start_block - blockNumber
        var seconds = numBlocks * this.$settings.CHAINS[network].blockTime
        var date = moment().add(seconds, 'seconds')
        item.unix = date.unix()
        return item
      })
      this.presales_unmerged.push(...presales)
    },
    async fetchAllPresales () {
      this.loading = true
      this.presales_unmerged = []
      await Promise.all([
        this.getPresales('Pancakeswap V2'),
        this.getPresales('Uniswap V2'),
        this.getPresales('Quickswap V1'),
        this.getPresales('Trader Joe V1'),
      ])
        .then(response => {
          this.presales_unmerged.sort((a, b) => a.unix - b.unix)
        })
        .catch(e => {})
        .then(() => {
          this.loading = false
          this.presales = this.presales_unmerged.slice(0, 11)
        })
    }
  },

  created () {
    this.fetchAllPresales()
  }
}
</script>