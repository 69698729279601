<template>
  <div>
    <v-row dense class="ma-0">

      <v-col cols="6">
        <time-offset @onUpdate="updateLockPeriod"></time-offset>
      </v-col>

      <v-col cols="6" class="d-flex align-center">
        <div class="d-flex align-center inputcolor br-8 py-1 pr-3">
          <c-input :value.sync="item.boost_percentage" placeholder="0.0" class="pa-2 font-weight-medium">
          </c-input>

          <div class="font-weight-bold">
            %
          </div>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click.stop="$emit('remove-boost')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div class="caption textFaint--text pl-2">
      Total lock time:
      &nbsp;
      <span class="font-weight-bold">
        {{ $root.getReadableTimeSpan(item.time_period) }}
      </span>
      &nbsp;+&nbsp;
      <v-menu offset-y open-on-hover transition="scale-transition" max-width="300px">
        <template v-slot:activator="{ on, attrs }">
          <div 
          v-bind="attrs"
          v-on="on"
          class="align-center orange--text" style="display: inline-flex;">
            {{ $root.getReadableTimeSpan(minimumLockPeriod) }} MSP
            <v-icon
            small
            color="orange"
            class="ml-1"
            >
              mdi-help-circle-outline
            </v-icon>
          </div>
        </template>
        <div class="pa-3 foreground caption">
          Minimum Staking Period, Set above
        </div>
      </v-menu>
      &nbsp;= {{ $root.getReadableTimeSpan(Number(item.time_period) + Number(minimumLockPeriod)) }}
    </div>

  </div>
</template>

<script>
import TimeOffset from '@/components/date-time/time-offset'

export default {
  props: {
    item: {
      time_period: 0,
      boost_percentage: 0,
      nonce: -1
    },
    minimumLockPeriod: null
  },

  components: {
    TimeOffset
  },

  data: () => ({
  }),

  methods: {
    updateLockPeriod (timeInSeconds) {
      this.item.time_period = timeInSeconds
    },
  }
}
</script>