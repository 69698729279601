<template>
  <div class="v-card br-20">
    <div class="py-4 px-2 pr-6 d-flex align-center">
      <v-btn text :color="mode === 'all_time' ? 'text' : 'textFaint'" @click="changeMode('all_time')">
        Top performers (all time)
      </v-btn>
      <v-btn text :color="mode === 'recent' ? 'text' : 'textFaint'" @click="changeMode('recent')">
        Recent
      </v-btn>
      <v-spacer></v-spacer>
      <v-progress-circular
      v-if="loading"
      indeterminate
      width="2"
      size="30"
      color="#aaa">
      </v-progress-circular>
    </div>

    <table class="c-table" style="width: 100%">
      <presale-row v-for="presale of presales" :key="presale.presale_contract" :db_info="presale" :exchange="presale.exchange"></presale-row>
    </table>

  </div>
</template>

<script>
import axios from 'axios'
import PresaleRow from './recent-presale-row'
import moment from 'moment'

export default {
  components: {
    PresaleRow
  },

  data: () => ({
    hide_flagged: false,
    sorting: 'profit',
    presales_unmerged: [],
    presales: [],

    mode: 'all_time',
    rows_per_page: 10
  }),

  methods: {
    changeMode (mode) {
      this.mode = mode
      if (mode === 'all_time') {
        this.sorting = 'profit'
        this.rows_per_page = 10
      } else if (mode === 'recent') {
        this.sorting = 'recent_profit'
        this.rows_per_page = 3
      }
      this.fetchAllPresales()
    },
    async getPresales (exchange) {
      var page = 0
      var filters = {
        // sort: 'profit',
        sort: this.sorting,
        sortAscending: false,
        search: '',
        hide_flagged: this.hide_flagged,
        show_hidden: false
      }
      var response = await axios.post(`${this.$settings.AMMS[exchange].server}/presales/search`, {filters: filters, page: page, stage: 2, rows_per_page: this.rows_per_page})
      // var presales = response.data.rows.filter(item => item.profit > 100)
      var presales = response.data.rows

      var blockNumber = this.$store.state.blockNumz[this.$settings.AMMS[exchange].chain]
      var network = this.$settings.AMMS[exchange].chain
      presales.map(item => {
        item.exchange = exchange

        var numBlocks = item.start_block - blockNumber
        var seconds = numBlocks * this.$settings.CHAINS[network].blockTime
        var date = moment().add(seconds, 'seconds')
        item.unix = date.unix()
        return item
      })
      this.presales_unmerged.push(...presales)
      console.log(exchange)
    },
    async fetchAllPresales () {
      this.loading = true
      this.presales_unmerged = []
      await Promise.all([
        this.getPresales('Pancakeswap V2'),
        this.getPresales('Uniswap V2'),
        this.getPresales('Quickswap V1'),
        this.getPresales('Trader Joe V1'),
      ])
        .then(response => {
          if (this.mode === 'all_time') {
            this.presales_unmerged.sort((a, b) => b.profit - a.profit)
            this.presales_unmerged = this.presales_unmerged.slice(0, 11)
          }
        })
        .catch(e => {})
        .then(() => {
          this.loading = false
          this.presales = this.presales_unmerged
        })
    }
  },

  created () {
    this.fetchAllPresales()
  }
}
</script>