import {store} from '@/store/index';
import { ethers } from "ethers";
import PROVIDERS from '@/web3/providers'

const Self = {
  getContractVersion: async (_contractAddress, _network) => {
    var provider = store.state.ethers.provider
    if (_network) {
      provider = PROVIDERS[_network]
    }
    var versionABI = [{"inputs":[],"name":"CONTRACT_VERSION","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]
    const Presale = new ethers.Contract(_contractAddress, versionABI, provider)
    var res = await Presale.CONTRACT_VERSION()
    return res.toNumber()
  },
}

export default Self