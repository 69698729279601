import {store} from '@/store/index';
import { ethers } from "ethers";
import SETTINGS_ABI from './abis'

const Self = {
  getFees: async () => {
    var sEthers = store.state.ethers
    const StakePoolSettings = new ethers.Contract(SETTINGS_ABI.stake_pool_settings(), SETTINGS_ABI.stake_pool_settings_abi, sEthers.provider)
    var fees = await StakePoolSettings.SETTINGS()
    return {
      eth_creation_fee: fees[0].toString(),
      reward_fee: fees[1].toString()
    }
  },
}

export default Self