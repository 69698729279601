<template>
  <div>
    <!--
    <div>
      {{ reserve0 }}
    </div>
    <div>
      {{ reserve1 }}
    </div>
    {{ price1 }} {{ price0 }}
    -->
    <div>
      1 {{ token0.symbol }} = {{ $root.formatAmount(price1, token1.decimals) }} {{ token1.symbol }}
    </div>
    <div>
      1 {{ token1.symbol }} = {{ $root.formatAmount(price0, token0.decimals) }} {{ token0.symbol }}
    </div>
    <!--
    <v-btn @click="refresh">
      refresh
    </v-btn>
    -->
  </div>
</template>

<script>
import { ethers } from 'ethers'

export default {
  props: {
    reserve0: {
      type: String,
      default: '0'
    },
    reserve1: {
      type: String,
      default: '0'
    },
    token0: {
      type: Object,
      default: () => {
        return {
          decimals: '0'
        }
      }
    },
    token1: {
      type: Object,
      default: () => {
        return {
          decimals: '0'
        }
      }
    }
  },

  data: () => ({
    price0: '0',
    price1: '0'
  }),

  watch: {
    reserve0 () {
      this.refresh()
    },
    token0 () {
      this.refresh()
    }
  },

  methods: {
    refresh () {
      var reserve0 = ethers.BigNumber.from(this.reserve0).eq(0) ? 1 : this.reserve0
      var reserve1 = ethers.BigNumber.from(this.reserve1).eq(0) ? 1 : this.reserve1
      this.price0 = ethers.BigNumber.from(ethers.BigNumber.from(10).pow(this.token1.decimals)).mul(this.reserve0).div(reserve1)
      this.price1 = ethers.BigNumber.from(ethers.BigNumber.from(10).pow(this.token0.decimals)).mul(this.reserve1).div(reserve0)
    }
  },

  created () {
    this.refresh()
  }
}
</script>