<template>
  <div>
    <div class="v-card pa-2 br-20">

      <div class="text-center mb-3">
        Admin functions
      </div>

      <div class="textFaint--text caption">
        Set UNCL guaranteed allocation amount
      </div>
      <div>
        <div class="d-flex align-center border br-20">
          <c-input :value.sync="unclAmountHuman" placeholder="0.0" @updateWhileFocussed="onHumanAmountChange" class="pa-2 font-weight-bold">
          </c-input>

          <div class="font-weight-bold">
            UNCL
          </div>
        </div>
      </div>
      <v-btn rounded block depressed color="blue" @click="setUNCLAmount" class="ml-2">
        SET UNCL allocation
      </v-btn>

      <v-btn @click="forceFailByUnicrypt" x-large color="red" block class="mt-8">
        Force Fail
      </v-btn>

    </div>

    <!-- MARK -->
    <div v-if="['21', '16'].includes($store.state.user.jwtObject.user_id)" class="mt-10 v-card br-20 pa-3">
      <div class="text-center">
        Mark only functions
      </div>
      <div class="text-center">
        <div>
          <div class="d-flex align-center border br-20">
            <c-input :value.sync="hcaptcha.token" placeholder="token" class="pa-2 font-weight-bold">
            </c-input>
          </div>
          <vue-hcaptcha
          ref="hcaptcha"
          sitekey="3a0c424a-39b2-4fa4-b19d-7f329d712b72"
          @verify="onVerify"
          @expired="onExpire"
          @error="onError"></vue-hcaptcha>
        </div>
        <v-btn @click="getRound0AntiBotKey" rounded color="primary">
          RoundZeroKey
        </v-btn>
        <v-btn @click="getRound1AntiBotKey" rounded color="primary">
          RoundOneKey
        </v-btn>
        <v-btn @click="resetCaptcha" rounded color="purple">
          resetCaptcha
        </v-btn>
        <div>
          {{ antiBotKey }}
        </div>

        <div>
          <v-btn @click="getSqlKeyStatus" rounded color="orange">
            getSqlKeyStatus
          </v-btn>
        </div>

        <div class="mt-10">
          <div class="d-flex align-center border br-20">
            <c-input :value.sync="acceptField" placeholder="Type 'accept' to proceed" :class="['pa-2 font-weight-bold']">
            </c-input>
            <v-icon v-if="acceptField === 'accept'" color="primary" class="mr-2">mdi-check-circle</v-icon>
          </div>
        </div>
        <div>
          <div class="text-center">
            Round 0
          </div>
          <div class="d-flex">
            <v-btn @click="activateR0" rounded color="blue" class="flex">
              activate R0
            </v-btn>
            <v-btn @click="activateR1" rounded color="blue" class="flex">
              activate R1
            </v-btn>
          </div>
          <div class="text-center">
            Round 1
          </div>
          <div class="d-flex">
            <v-btn @click="disableR0" rounded color="red" class="flex">
              disable R0
            </v-btn>
            <v-btn @click="disableR1" rounded color="red" class="flex">
              disable R1
            </v-btn>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers'
import PresaleContract from '@/smart-contracts/presales/v4/presale-contract'
import TxWrapper from '@/web3/tx-wrapper-2';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  props: {
    address: {
      type: String
    },
    presale_info: {
      type: Object,
      default: () => {

      }
    }
  },

  components: {
    VueHcaptcha
  },

  data: () => ({
    unclAmount: '2000000000000000000',
    unclAmountHuman: '2',
    hcaptcha: {
      verified: false,
      expired: false,
      token: 'some key',
      eKey: null,
      error: null
    },
    antiBotKey: {},
    acceptField: ''
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
  },

  watch: {
  },

  methods: {
    onHumanAmountChange (val) {
      try {
        this.unclAmount = ethers.utils.parseUnits(val, 18)
      } catch (e) {
        this.unclAmount = '0'
      }
    },
    async setUNCLAmount () {
      TxWrapper.doTransaction(PresaleContract.setUNCLAmount,
        [this.address, this.unclAmount], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          // this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          // this.lockLoading = false
        })
    },
    async forceFailByUnicrypt () {
      TxWrapper.doTransaction(PresaleContract.forceFailByUnicrypt,
        [this.address], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          // this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          // this.lockLoading = false
        })
    },
    onVerify (token, ekey) {
      this.hcaptcha.verified = true;
      this.hcaptcha.token = token;
      this.hcaptcha.eKey = ekey;
      // console.log(`Callback token: ${token}, ekey: ${ekey}`);
    },
    onExpire () {
      this.hcaptcha.verified = false;
      this.hcaptcha.token = null;
      this.hcaptcha.eKey = null;
      this.hcaptcha.expired = true;
      console.log('Expired');
    },
    onError (err) {
      this.hcaptcha.token = null;
      this.hcaptcha.eKey = null;
      this.hcaptcha.error = err;
      console.log(`Error: ${err}`);
    },
    async getRound1AntiBotKey () {
      this.antiBotError = false
      this.$axios.post(`/anti-bot/v4/get-participation-key`, {
        presale_contract: this.presale_info.presale_contract,
        user: '0x60e2E1b2a317EdfC870b6Fc6886F69083FB2099a',
        contract_version: 4,
        hcaptcha_token: this.hcaptcha.token
      })
        .then(response => {
          this.antiBotKey = response.data
          if (!this.antiBotKey.success) {
            this.onAntiBotFailResetCaptcha()
          }
        })
        .catch(() => {
          this.onAntiBotFailResetCaptcha()
        })
    },
    onAntiBotFailResetCaptcha () {
      /* this.antiBotError = true
      this.hcaptcha.verified = false;
      this.hcaptcha.token = null;
      this.hcaptcha.eKey = null;
      this.hcaptcha.expired = true;
      this.$refs.hcaptcha.reset(); */
    },
    resetCaptcha () {
      this.$refs.hcaptcha.reset();
    },
    async getRound0AntiBotKey () {
      this.$axios.post(`/anti-bot/v4/get-uncl-key`, {
        presale_contract: this.presale_info.presale_contract,
        user: '0x60e2E1b2a317EdfC870b6Fc6886F69083FB2099a',
        contract_version: 4,
        hcaptcha_token: this.hcaptcha.token
      })
        .then(response => {
          this.antiBotKey = response.data
          if (!this.antiBotKey.success) {
            this.onAntiBotFailResetCaptcha()
          }
        })
        .catch(() => {
          this.onAntiBotFailResetCaptcha()
        })
    },
    async getSqlKeyStatus () {
      this.$axios.post(`/anti-bot/v4/get-sql-key-status`, {
        presale_contract: this.presale_info.presale_contract,
      })
        .then(response => {
          this.antiBotKey = response.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    async activateR0 () {
      if (this.acceptField !== 'accept') {
        return
      }
      this.$axios.post(`/anti-bot/v4/activate-r0`, {
        presale_contract: this.presale_info.presale_contract,
      })
        .then(response => {
          this.getSqlKeyStatus()
        })
        .catch(e => {
          console.log(e)
        })
    },
    async activateR1 () {
      if (this.acceptField !== 'accept') {
        return
      }
      this.$axios.post(`/anti-bot/v4/activate-r1`, {
        presale_contract: this.presale_info.presale_contract,
      })
        .then(response => {
          this.getSqlKeyStatus()
        })
        .catch(e => {
          console.log(e)
        })
    },
    async disableR0 () {
      if (this.acceptField !== 'accept') {
        return
      }
      this.$axios.post(`/anti-bot/v4/disable-r0`, {
        presale_contract: this.presale_info.presale_contract,
      })
        .then(response => {
          this.getSqlKeyStatus()
        })
        .catch(e => {
          console.log(e)
        })
    },
    async disableR1 () {
      if (this.acceptField !== 'accept') {
        return
      }
      this.$axios.post(`/anti-bot/v4/disable-r1`, {
        presale_contract: this.presale_info.presale_contract,
      })
        .then(response => {
          this.getSqlKeyStatus()
        })
        .catch(e => {
          console.log(e)
        })
    }
  },

  created () {
  }

}
</script>