<template>
  <v-container style="max-width: 1200px;" :class="[{'pa-0 mobile-page mb-16': $vuetify.breakpoint.xs}]">

    <div class="pa-4 br-20">
      <div class="display-1 font-weight-bold text-center font-weight-medium pb-2 pt-4">
        UNCX STAKING
      </div>

      <div class="pb-12 caption text-center textFaint--text">
        UNCX staking is available only on the Ethereum chain
      </div>

      <v-slide-x-transition appear>
        <v-row dense v-if="!loading">
          <v-col cols="12" md="4">
            <div style="overflow: unset;" class="pa-4 br-8 text-center">

              <div v-if="true" class="display-1 font-weight-medium">
                TIER 1
              </div>
              <div class="title pt-4 textFaint--text">
                100+ UNCX
              </div>
            </div>
            <farm-row :item="pools[0]" network="Mainnet" @click.native="$root.chainLink(`/farm/${pools[0].spool_address}`, 'Mainnet')">
            </farm-row>
          </v-col>
          <v-col cols="12" md="4">
            <div style="overflow: unset;" class="pa-4 br-8 text-center">

              <div v-if="true" class="display-1 font-weight-medium">
                TIER 2
              </div>
              <div class="title pt-4 textFaint--text">
                20 - 100 UNCX
              </div>
            </div>
            <farm-row :item="pools[1]" network="Mainnet" @click.native="$root.chainLink(`/farm/${pools[1].spool_address}`, 'Mainnet')">
            </farm-row>
          </v-col>
          <v-col cols="12" md="4">
            <div style="overflow: unset;" class="pa-4 br-8 text-center">

              <div v-if="true" class="display-1 font-weight-medium">
                TIER 3
              </div>
              <div class="title pt-4 textFaint--text">
                3 - 20 UNCX
              </div>
            </div>
            <farm-row :item="pools[2]" network="Mainnet" @click.native="$root.chainLink(`/farm/${pools[2].spool_address}`, 'Mainnet')">
            </farm-row>
          </v-col>
        </v-row>
      </v-slide-x-transition>

    </div>

  </v-container>
</template>

<script>
import UnicryptTiers from '@/smart-contracts/farms-v2/unicrypt-tiers'
import FarmRow from '@/views/farms/list-page/row'
import axios from "axios";

export default {

  components: {
    FarmRow
  },

  data: () => ({
    pools: [],
    loading: true
  }),

  computed: {
    tiers () {
      return UnicryptTiers.TIERS
    }
  },

  methods: {
    async fetchAPI () {
      var pools = await this.getFarms(['0x887E81cab04461620A5fF196048Bba38d9Dc96e4', '0x73f5876ECd9fAbF7d359Daf7Be7610B276641549', '0xf4868E785457280dd48B10a02D8F03BBfD5B721f'])
      var pools_ordered = []
      console.log(pools)
      pools_ordered.push(pools.filter(item => item.spool_address === '0x887E81cab04461620A5fF196048Bba38d9Dc96e4')[0])
      pools_ordered.push(pools.filter(item => item.spool_address === '0x73f5876ECd9fAbF7d359Daf7Be7610B276641549')[0])
      pools_ordered.push(pools.filter(item => item.spool_address === '0xf4868E785457280dd48B10a02D8F03BBfD5B721f')[0])
      this.pools = pools_ordered
      console.log(pools_ordered)
      this.loading = false
    },
    async getFarms (pools) {
      try {
        var response = await axios.post(
          `${this.$root.$servers.FARMS.eth.server}/farms/get-multiple`,
          {farms: pools}
        )
        return response.data
      } catch (e) {}
      return []
    }
  },

  created () {
    this.fetchAPI()
  }
}
</script>