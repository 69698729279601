import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: () => {
        return {
          datasets: []
        }
      }
    },
    options: {
      type: Object,
      default: null
    },
    red: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    update () {
      this.updateColor()
      this.$data._chart.update()
    },
    reRender () {
      this.updateColor()
      this.renderChart(this.chartdata, this.options)
    },
    updateColor () {
      var grad1 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
      var grad2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
      var grad3 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
      var grad4 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
      var grad5 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)

      grad1.addColorStop(0, "rgba(198,104,243, 0.9)")
      grad1.addColorStop(1, "rgba(85,34,130, 0.5)")

      grad2.addColorStop(0, "rgba(0,190,249, 0.9)")
      grad2.addColorStop(1, "rgba(45,84,142, 0.7)")

      grad3.addColorStop(0, "rgba(20,249,210, 0.9)")
      grad3.addColorStop(1, "rgba(45,84,142, 0.7)")

      grad4.addColorStop(0, "rgba(20,255,180, 0.9)")
      grad4.addColorStop(1, "rgba(20,255,60, 0.7)")

      grad5.addColorStop(0, "rgba(249,231,108, 0.9)")
      grad5.addColorStop(1, "rgba(245,203,95, 0.7)")

      this.chartdata.datasets[0].backgroundColor[0] = grad1
      this.chartdata.datasets[0].backgroundColor[1] = grad2
      this.chartdata.datasets[0].backgroundColor[2] = grad3
      this.chartdata.datasets[0].backgroundColor[3] = grad4
      this.chartdata.datasets[0].backgroundColor[4] = grad5

      if (this.red) {
        this.chartdata.datasets[0].backgroundColor[0] = "#F44336"
        this.chartdata.datasets[0].backgroundColor[1] = "#F44336"
        this.chartdata.datasets[0].backgroundColor[2] = "#F44336"
        this.chartdata.datasets[0].backgroundColor[3] = "#F44336"
      }
    }
  },

  mounted () {
    this.updateColor()
    this.renderChart(this.chartdata, this.options)
  }
}