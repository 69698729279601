<template>
  <span>
    <v-btn small v-if="url" icon color="textFaint" rounded :href="url" target="_blank">
      <img 
      :src="$root.$icons.coinstats" 
      height="20px"
      width="20px"
      class="mr-1">
    </v-btn>
  </span>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    network: null,
    token_address: null
  },

  data: () => ({
    url: ''
  }),

  watch: {
    network () {
      // this.getUrl()
    },
    token_address () {
      this.getUrl()
    }
  },

  methods: {
    getUrl () {
      if (!this.network || !this.token_address) {
        return
      }
      var chainMap = {
        'Mainnet': 'eth',
        'Kovan': 'eth',
        'Ganache': 'eth',
        'BSC_MAINNET': 'bsc',
        'Matic': 'matic',
      }
      axios.get(`https://api.coinstats.app/public/v1/coins/${chainMap[this.network]}/${this.token_address}`)
        .then(response => {
          this.url = response.data.url
        })
        .catch (e => {
          // console.log(e)
          this.url = null
        })
    }
  },

  created () {
    this.getUrl()
  }
}
</script>