import {store} from '@/store/index';
import { ethers } from "ethers";
import GENERATOR_ABI from './abis'

const Self = {
  createRewardPool: (_stakePool, _rewardToken, _amount, _startBlock, _endBlock, _bonusEndBlock, _bonus) => {
    var sEthers = store.state.ethers
    const RewardPoolGenerator = new ethers.Contract(GENERATOR_ABI.reward_pool_generator(), GENERATOR_ABI.reward_pool_generator_abi, sEthers.signer)
    var res = RewardPoolGenerator.createRewardPool(_stakePool, _rewardToken, _amount, _startBlock, _endBlock, _bonusEndBlock, _bonus)
    return res
  },
}

export default Self