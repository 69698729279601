<template>

  <div style="width: 200px;" :class="['v-card br-8 mr-1 c-list flex d-flex align-center', {'px-2 py-1': $vuetify.breakpoint.xs}, {'pa-4': !$vuetify.breakpoint.xs}]" @click="$root.ammLinkTwo(`/ilo/${db_info.presale_contract}`, exchange)">

    <v-progress-circular
      :value="presaleProgress"
      :rotate="-90"
      size="50"
      width="4"
      class="pa-1"
      color="primary"
      background-color="background">
        <v-progress-circular
        :value="unclProgress"
        :rotate="-90"
        size="40"
        width="3"
        :color="unclProgress > 99 ? 'light-blue' : 'light-blue'"
        background-color="background"
        :class="['pa-1', db_info.hot ? 'primary-pulse': '', 'br-c']">
          <v-badge
            avatar
            bottom
            right
            color="midground"
            offset-x="12"
            offset-y="12"
          >
            <template v-slot:badge>
              <img 
              :src="$settings.CHAINS[cNetwork].icon" 
              height="20px"
              width="20px"
              style="padding: 2px;"
              class="br-20 midground">
            </template>
            <coin-icon :url="db_info.icon_url" :size="30"></coin-icon>
          </v-badge>
        </v-progress-circular>
    </v-progress-circular>

    <div class="pl-4">
      <div class="font-weight-medium">

        <span class="pr-2">
          <span class="caption font-weight-medium">
            {{ db_info.s_token.symbol }}
          </span>
        </span>

        <v-icon v-if="db_info.audit_rating === 2" size="14" color="red">mdi-file-document-outline</v-icon>
        <v-icon v-else-if="tokenHydrated.isENMT" size="14" color="primary">mdi-file-document-outline</v-icon>
        <v-icon v-else-if="db_info.audit_rating === 1" size="14" color="orange">mdi-file-document-outline</v-icon>
        <v-icon v-else-if="db_info.audit_rating === 0" size="14" color="primary">mdi-file-document-outline</v-icon>
        <!-- <v-icon v-else-if="db_info.audit_rating === null" size="14" color="red">mdi-file-document-outline</v-icon> -->

        <v-icon v-if="db_info.kyc_rating === 0" size="14" color="primary">mdi-magnify</v-icon>
      </div>
      <div v-if="false" class="caption textFaint--text">
        {{ db_info.s_token.name }}
      </div>

      <div class="caption textFaint--text font-weight-medium">
        <template v-if="!$vuetify.breakpoint.xs">
          <div v-if="!firstLoad">
            {{ $root.formatDollars(hardcapHuman) }} 
            <span class="">
              {{ baseTokenSymbol }}
            </span>
          </div>
          <div v-else>
            ...
          </div>
        </template>
        <div v-if="true" class="caption">
          {{ startDateFromNow }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ethers } from 'ethers'
import moment from 'moment'
import PresaleContract4 from '@/smart-contracts/presales/v4/presale-contract'
import PresaleContract6 from '@/smart-contracts/presales/v6/presale-contract'
import PresalePeriphery from '@/smart-contracts/presales/presale-periphery'
import ERC20 from '@/smart-contracts/erc20/pager'

export default {

  props: {
    db_info: {
      type: Object,
      default: () => ({
        presale_contract: null
      })
    },
    exchange: null
  },

  data: () => ({
    presale_info: {
      hardcap: '0',
      total_base_collected: '0',
      base_token: {
        symbol: '?'
      },
      uncl_participants: '0',
      uncl_max_participants: '0',
    },
    tokenHydrated: {},
    firstLoad: true
  }),

  computed: {
    cNetwork () {
      return this.$settings.AMMS[this.exchange].chain
    },
    startDateFromNow () {
      var numBlocks = this.db_info.start_block - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date.fromNow()
    },
    blockNumber () {
      if (this.exchange) {
        return this.$store.state.blockNumz[this.$settings.AMMS[this.exchange].chain]
      }
      return this.$store.state.blockNumber
    },
    blocksPerSecond () {
      return this.$settings.CHAINS[this.cNetwork].blockTime
    },
    unclProgress () {
      if (this.presale_info.uncl_max_participants === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.presale_info.uncl_participants).mul(1000).div(this.presale_info.uncl_max_participants)
      return percent.toNumber() / 10
    },
    presaleProgress () {
      var percent = ethers.BigNumber.from(this.presale_info.total_base_collected).mul(1000).div(this.presale_info.hardcap === '0' ? '1' : this.presale_info.hardcap)
      return percent.toNumber() / 10
    },
    hardcapHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.hardcap, this.presale_info.base_token.decimals)
      return amount
      // return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    baseTokenSymbol () {
      return this.presale_info.base_token.address === this.$settings.WRAPPED_GAS_TOKEN[this.cNetwork] ? this.$settings.CHAINS[this.cNetwork].gasToken.symbol : this.presale_info.base_token.symbol.toUpperCase()
    },
  },

  methods: {
    async refresh () {
      var network = this.exchange ? this.$settings.AMMS[this.exchange].chain : null
      var contract_version = await PresalePeriphery.getContractVersion(this.db_info.presale_contract, network)
      var response
      if (contract_version >= 3 && contract_version <= 5) {
        response = await PresaleContract4.getInfo(this.db_info.presale_contract, network)
      } else if (contract_version === 6) {
        response = await PresaleContract6.getInfo(this.db_info.presale_contract, network)
      } else {
        return
      }
      this.presale_info = response.presale_info
      this.firstLoad = false
    },
    async hydrateToken () {
      var network = this.exchange ? this.$settings.AMMS[this.exchange].chain : null
      this.tokenHydrated = await ERC20.getERC20(this.db_info.s_token.address, network)
    },
  },

  created () {
    this.hydrateToken()
    this.refresh()
  }
}
</script>