<template>
  <div class="pa-0">

    <div v-if="!sEthers.coinbase" class="v-card br-20 text-center pa-6">
      <div>
        <v-icon size="40" color="textFaint">mdi-account-circle</v-icon>
        <div class="textFaint--text caption pt-2">
          Connect your wallet
        </div>
      </div>
      <div class="pt-2">
        <v-btn
        large
        block
        depressed
        class="font-weight-bold midground"
        rounded
        @click="connectWallet"
        >
          CONNECT
        </v-btn>
      </div>
    </div>

    <div v-else>

      <div v-if="tab === 'main'">
        <div v-if="false" class="text-center py-6">
          <v-icon size="60" color="textFaint">mdi-settings</v-icon>
          <div class="title textFaint--text">
            SETTINGS
          </div>
        </div>

        <div class="pa-3 c-list border-b d-flex align-center" @click="tab = 'media'">
          <v-icon size="50" class="mr-2" color="text">mdi-palette</v-icon>
          <div>
            <div class="title font-weight-medium">
              Banners & Media
            </div>
            <div class="textFaint--text">
              Visually brand your farm
            </div>
          </div>
        </div>

        <div class="pa-3 c-list border-b d-flex align-center" @click="tab = 'stake'">
          <v-icon size="50" class="mr-2" color="text">mdi-download</v-icon>
          <div>
            <div class="title font-weight-medium">
              Stake pool
            </div>
            <div class="textFaint--text">
              Adjust stake pool settings
            </div>
          </div>
        </div>

        <div class="pa-3 c-list border-b d-flex align-center" @click="tab = 'reward'">
          <v-icon size="50" class="mr-2" color="text">mdi-currency-usd</v-icon>
          <div>
            <div class="title font-weight-medium">
              Reward pool
            </div>
            <div class="textFaint--text">
              Add a reward pool
            </div>
          </div>
        </div>

        <div v-if="$store.state.adminIds.includes($store.state.user.jwtObject.user_id)" class="pa-3 c-list border-b d-flex align-center" @click="tab = 'super'">
          <v-icon size="50" class="mr-2" color="text">mdi-atom</v-icon>
          <div>
            <div class="title font-weight-medium">
              Super user
            </div>
            <div class="textFaint--text">
              Edit settings
            </div>
          </div>
        </div>

      </div>

      <div v-else class="pa-2 midground d-flex align-center textFaint--text">
        <v-btn x-large text @click="tab = 'main'" color="textFaint" icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="title font-weight-medium">
          {{ subSettingHeader }}
        </span>
      </div>

      <spool-settings v-if="tab === 'stake'" :address="address" @hard-refresh="$emit('hard-refresh')"></spool-settings>

      <reward-generator v-if="tab === 'reward'" :stake_pool_address="address" :pool_info="pool_info" @pool-created="$emit('close-settings')"></reward-generator>

      <super-user-settings v-if="tab === 'super'" :address="address" :pool_info="pool_info" @hard-refresh="$emit('hard-refresh')"></super-user-settings>

      <media-settings v-if="tab === 'media'" :pool_info="pool_info" :apiData="apiData" :address="address" @api-refresh="$emit('api-refresh')"></media-settings>

    </div>

  </div>
</template>

<script>
import SpoolSettings from './settings-spool'
import RewardGenerator from '@/views/farms/rewards/generator/rewardgen-01'
import SuperUserSettings from './settings-super'
import MediaSettings from './settings-media'

export default {

  props: {
    address: null,
    pool_info: {
      type: Object,
      default: () => {
        return {
          share_weight_total: '0',
          min_stake_amount: '0',
          max_stake_amount: '0',
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },

  components: {
    SpoolSettings,
    RewardGenerator,
    SuperUserSettings,
    MediaSettings
  },

  data: () => ({
    tab: 'main', // main, reward, stake, super
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    subSettingHeader () {
      var map = {
        'stake': 'Stake Pool',
        'reward': 'Reward Pool',
        'super': 'Super user',
        'media': 'Media settings',
      }
      return map[this.tab]
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
  },

  created () {
  }
}
</script>