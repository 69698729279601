<template>
  <v-container fluid :class="['pa-0 mt-1', {'pa-0 foreground mobile-page pb-16': $vuetify.breakpoint.xs}]">

    <div v-if="!firstLoad">
      <div v-if="presaleStage === 0" class="mx-auto foreground text-center pa-4 mb-1" style="position: relative; overflow: hidden;">
        <!-- <div class="light-blue" style="position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0px;opacity: 0.2;">
        </div> -->
        <div class="">
          <span class="light-blue--text">
            Starts {{ timeToStartBlock }}
          </span>
          <span class="caption textFaint--text">
            , {{ dateStartBlockHuman }},
          </span>
          <span class="caption textFaint--text">
            {{ startBlock - blockNumber }} block{{ startBlock - blockNumber === 1 ? '' : 's'}}
          </span>
        </div>
      </div>

      <v-card v-if="presaleStage === 1" outlined class="background textFaint--text text-center br-8 pa-4 mb-2">
        <div class="title text--text">
          Round 2 starts {{ timeToRound2 }}
        </div>
        <div class="d-flex">
          <div class="textFaint--text">
            {{ round2StartBlock - blockNumber }} block{{ round2StartBlock - blockNumber === 1 ? '' : 's'}} to go
          </div>
          <v-spacer></v-spacer>
          <div class="textFaint--text">
            {{ round2DateHuman }}
          </div>
        </div>
      </v-card>
    </div>

    <div v-if="!firstLoad" class="foreground border">
      
      <div :style="`max-width: ${$vuetify.breakpoint.xs ? '600px': '1100px'};`" class="mx-auto">

        <!-- DESKTOP -->
        <div v-if="!$vuetify.breakpoint.xs" class="pa-4 d-flex align-center">
          <coin-icon :url="dbInfo.icon_url" :size="180"></coin-icon>

          <div class="pl-4 flex">

            <div class="d-flex align-center">
              <span class="display-1 hover-primary" @click.stop="$root.tokenPage.open(presaleInfo.token.address, $store.state.requiredNetwork)">
                <v-icon v-if="dbInfo.hot" size="24" color="primary" class="mr-1 br-c">mdi-rocket</v-icon>
                <span :class="['text-truncate', {'primary--text': dbInfo.hot}]">
                  {{ presaleInfo.token.name }}
                </span>
              </span>

              <v-spacer></v-spacer>

              <!-- PROFIT AND LOSS -->
              <div v-if="presaleStage === 5" class="d-flex align-center mb-1">
                <chart-links :token_address="presaleInfo.token.address" :pair_address="finalizedPairAddress" :exchange="$store.state.exchange"></chart-links>
                <!--
                <v-btn @click.stop="getPriceDiff" text icon small>
                  <v-icon color="textFaint">mdi-refresh</v-icon>
                </v-btn>
                -->
                <v-icon v-if="price_diff > 0" color="primary">mdi-finance</v-icon>
                <div v-if="price_diff >= 100" :class="['d-flex display-1 align-center primary--text px-2 br-c font-weight-bold ml-1']">
                  {{ ((price_diff / 100) + 1).toFixed(1) }}X
                </div>
                <div v-else-if="price_diff > 0 || $store.state.showAllStatsMode" :class="['d-flex caption align-center primary--text px-2 br-c font-weight-bold ml-1']">
                  <span :class="[price_diff >= 0 ? 'primary--text' : 'red--text', 'display-1 font-weight-medium']">{{ price_diff.toFixed(0) }}%</span>
                </div>
              </div>
              <!-- PROFIT AND LOSS -->
            </div>

            <div class="d-flex align-center mt-2">
              <span class="textFaint--text">
                Socials
              </span>
              <v-spacer></v-spacer>
              <v-btn v-if="dbInfo.website_url" icon class="border" color="textFaint" rounded :href="dbInfo.website_url" target="_blank">
                <v-icon>mdi-web</v-icon>
              </v-btn>
              <v-btn v-if="dbInfo.twitter_url" icon class="border" color="textFaint" rounded :href="dbInfo.twitter_url" target="_blank">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <v-btn v-if="dbInfo.telegram_url" icon class="border" color="textFaint" rounded :href="dbInfo.telegram_url" target="_blank">
                <v-icon>mdi-telegram</v-icon>
              </v-btn>
            </div>

            <div class="d-flex align-center">
              <span class="textFaint--text">
                Status
              </span>
              <v-spacer></v-spacer>
              <span class="text-uppercasee textFaint--text">{{ presaleStageString }}</span>
            </div>

            <div class="d-flex align-center">
              <span class="textFaint--text">
                Softcap
              </span>
              <v-spacer></v-spacer>
              <span class="text-uppercase textFaint--text">
                {{ softcapHuman }} {{ baseTokenSymbol }}
              </span>
            </div>

            <div class="d-flex align-center">
              <span class="textFaint--text">
                Badges
              </span>
              <v-spacer></v-spacer>
              <!-- BADGES -->
              <div class="pl-4 mb-2 d-flex align-center justify-center flex-wrap">
                
                <span class="d-flex border align-center px-2 br-c caption textFaint--text">
                  <v-icon size="16" color="textFaint" class="mr-1">mdi-account</v-icon>
                    x
                    {{ presaleInfo.num_buyers }}
                </span>
                <template v-if="tokenHydrated.address">

                  <span class="d-flex border align-center px-2 br-c">
                    <v-icon size="16" color="textFaint" class="mr-1">mdi-lock</v-icon>
                    <span class="textFaint--text caption font-weight-medium">
                      {{ presaleInfo.liquidity_percent / 10 }}%
                    </span>
                  </span>

                  <div v-if="dbInfo.hot" class="caption d-flex align-center font-weight-medium lgrad-green white--text px-2 br-20">
                    <!-- <v-icon size="16" color="white" class="mx-1">mdi-rocket</v-icon> -->
                    INCUBATED
                  </div>

                  <div v-if="tokenHydrated.isENMT" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                    <v-icon size="16" color="white" class="mx-1">mdi-leaf</v-icon>
                    <span>
                      ENMT
                    </span>
                  </div>

                  <div v-if="dbInfo.audit_rating === 2" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                    <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                    <span>
                      Flagged
                    </span>
                  </div>

                  <div v-else-if="dbInfo.audit_rating === 1" class="caption d-flex align-center font-weight-medium orange white--text pr-2 br-20">
                    <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                    Audit
                  </div>

                  <div v-else-if="dbInfo.audit_rating === 0" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                    <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                    <span>
                      Audit
                    </span>
                  </div>

                  <!--
                  <div v-else-if="!tokenHydrated.isENMT" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                    <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                    <span>
                      Audit
                    </span>
                  </div>
                  -->
                </template>

                <div v-if="dbInfo.kyc_rating === 0" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                  <v-icon size="16" color="white" class="mx-1">mdi-magnify</v-icon>
                  <span>
                    KYC
                  </span>
                </div>

                <div v-else-if="dbInfo.kyc_rating === 1" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                  <v-icon size="16" color="white" class="mx-1">mdi-magnify</v-icon>
                  <span>
                    KYC
                  </span>
                </div>

              </div>
              <!-- BADGES -->
            </div>
          </div>

        </div>
        <!-- MOBILE -->
        <div v-else class="pa-4 text-center">
          <v-btn text rounded @click.stop="$root.tokenPage.open(presaleInfo.token.address)" class="title pa-0 pl-4" style="min-width: 10px;">
            <v-icon v-if="dbInfo.hot" size="24" color="primary" class="mr-1 br-c">mdi-rocket</v-icon>
            <span :class="['text-truncate', {'primary--text': dbInfo.hot}]">
              {{ presaleInfo.token.name }}
            </span>
          </v-btn>
          <!-- PROFIT AND LOSS -->
          <div v-if="presaleStage === 5" class="d-flex justify-center align-center mb-1">
            <chart-links :token_address="presaleInfo.token.address" :pair_address="finalizedPairAddress" :exchange="$store.state.exchange"></chart-links>
            <!--
            <v-btn @click.stop="getPriceDiff" text icon small>
              <v-icon color="textFaint">mdi-refresh</v-icon>
            </v-btn>
            -->
            <v-icon v-if="price_diff > 0" color="primary">mdi-finance</v-icon>
            <div v-if="price_diff >= 100" :class="['d-flex title align-center primary--text px-2 br-c font-weight-bold ml-1']">
              {{ ((price_diff / 100) + 1).toFixed(1) }}X
            </div>
            <div v-else-if="price_diff > 0 || $store.state.showAllStatsMode" :class="['d-flex caption align-center primary--text px-2 br-c font-weight-bold ml-1']">
              <span :class="[price_diff >= 0 ? 'primary--text' : 'red--text', 'title font-weight-medium']">{{ price_diff.toFixed(0) }}%</span>
            </div>
          </div>
          <!-- PROFIT AND LOSS -->
          <div>
            <coin-icon :url="dbInfo.icon_url" :size="130"></coin-icon>
          </div>
          <!-- BADGES -->
          <div class="pl-4 mb-2 d-flex align-center justify-center flex-wrap">
            
            <span class="d-flex border align-center px-2 br-c caption textFaint--text">
              <v-icon size="16" color="textFaint" class="mr-1">mdi-account</v-icon>
                x
                {{ presaleInfo.num_buyers }}
            </span>
            <template v-if="tokenHydrated.address">

              <span class="d-flex border align-center px-2 br-c">
                <v-icon size="16" color="textFaint" class="mr-1">mdi-lock</v-icon>
                <span class="textFaint--text caption font-weight-medium">
                  {{ presaleInfo.liquidity_percent / 10 }}%
                </span>
              </span>

              <div v-if="dbInfo.hot" class="caption d-flex align-center font-weight-medium lgrad-green white--text px-2 br-20">
                <!-- <v-icon size="16" color="white" class="mx-1">mdi-rocket</v-icon> -->
                INCUBATED
              </div>

              <div v-if="tokenHydrated.isENMT" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                <v-icon size="16" color="white" class="mx-1">mdi-leaf</v-icon>
                <span>
                  ENMT
                </span>
              </div>

              <div v-if="dbInfo.audit_rating === 2" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                <span>
                  Flagged
                </span>
              </div>

              <div v-else-if="dbInfo.audit_rating === 1" class="caption d-flex align-center font-weight-medium orange white--text pr-2 br-20">
                <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                Audit
              </div>

              <div v-else-if="dbInfo.audit_rating === 0" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                <span>
                  Audit
                </span>
              </div>

              <!--
              <div v-else-if="!tokenHydrated.isENMT" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                <span>
                  Audit
                </span>
              </div>
              -->
            </template>

            <div v-if="dbInfo.kyc_rating === 0" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
              <v-icon size="16" color="white" class="mx-1">mdi-magnify</v-icon>
              <span>
                KYC
              </span>
            </div>

            <div v-else-if="dbInfo.kyc_rating === 1" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
              <v-icon size="16" color="white" class="mx-1">mdi-magnify</v-icon>
              <span>
                KYC
              </span>
            </div>

          </div>
          <!-- BADGES -->
          <div>
            <v-btn v-if="dbInfo.website_url" icon class="border" color="textFaint" rounded :href="dbInfo.website_url" target="_blank">
              <v-icon>mdi-web</v-icon>
            </v-btn>
            <v-btn v-if="dbInfo.twitter_url" icon class="border" color="textFaint" rounded :href="dbInfo.twitter_url" target="_blank">
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn v-if="dbInfo.telegram_url" icon class="border" color="textFaint" rounded :href="dbInfo.telegram_url" target="_blank">
              <v-icon>mdi-telegram</v-icon>
            </v-btn>
          </div>
        </div>

      </div>
    </div>

    <div v-if="!firstLoad" class="px-2 py-1 border-b foreground">

      <!-- UNCL PROGRESS BAR -->
      <v-row class="ma-0 d-flex align-center mx-auto" dense :style="`max-width: ${$vuetify.breakpoint.xs ? '600px': '1100px'};`">
        <v-col cols="12" md="6">
          <!--
          <div class="caption text-center">
            Raised
          </div>
          -->
          <div class="d-flex align-center">
          <v-progress-linear height="30" :value="presaleProgress" 
          :color="tokenomicsIsRed ? 'red' : dbInfo.hot ? 'lgrad-green' : 'lgrad-green'"
          :class="['font-weight-medium br-20', {'text--text': presaleProgress > 50}, {'text--text': presaleProgress <= 50}]"
          background-color="background">
            <div class="d-flex px-3 align-center">
              <div class="">
                {{ totalEthCollectedHuman }}
              </div>
              <div :class="`px-1`">
                /
              </div>
              <div :class="`d-flex align-center`">
                {{ hardcapHuman }} {{ baseTokenSymbol }}
              </div>
            </div>
          </v-progress-linear>

          </div>
        </v-col>
        <v-col cols="6" md="3">
          <!-- 
          <div class="caption text-center textFaint--text">
            UNCL Reservations
          </div>
          -->
          <v-progress-linear rounded height="20" :value="unclProgress" 
          :color="tokenomicsIsRed ? 'red' : dbInfo.hot ? 'lgrad-blue' : 'lgrad-blue'"
          :class="['justify-center font-weight-medium br-20', {'text--text': unclProgress > 50}, {'text--text': unclProgress <= 50}]"
          background-color="background">
          <!-- <img 
          src="@/assets/img/UNCL.svg"
          height="18px"
          width="18px"
          class="mr-2 ml-2"> -->
            <div class="caption font-weight-medium">
              {{ presaleInfo.uncl_participants }} / {{ presaleInfo.uncl_max_participants }} reserved
            </div>
          </v-progress-linear>
        </v-col>
        <v-col cols="6" md="3">
          <!-- <div class="caption text-center textFaint--text">
            Whitelist
          </div> -->
          <v-progress-linear rounded height="20" :value="whitelistProgress" 
          :color="tokenomicsIsRed ? 'red' : dbInfo.hot ? 'lgrad-blue' : 'lgrad-blue'"
          :class="['justify-center font-weight-medium br-20', {'text--text': whitelistProgress > 50}, {'text--text': whitelistProgress <= 50}]"
          background-color="background">
            <!-- <v-icon size="18" class="mx-1" color="text">mdi-file-document-outline</v-icon> -->
            <div class="caption font-weight-medium">
              {{ presaleInfo.whitelist_assigned }} / {{ presaleInfo.whitelist_max_participants }} whitelist
            </div>
          </v-progress-linear>
        </v-col>

      </v-row>
      <!-- PROGRESS BAR -->
    </div>

    <div :style="`max-width: ${$vuetify.breakpoint.xs ? '600px': '1100px'};`" class="mx-auto">
      <super-user v-if="$store.state.superUserMode" :address="address" :presale_info="presaleInfo" style="max-width: 600px;" class="mx-auto"></super-user>

      <div class="">
        
        <div v-if="!firstLoad && (userIsOwner || SHOWALL)" class="d-flex align-center py-2 pr-2">
          <!--
          <v-btn :to="`${$store.state.ammPrefix}/ilos`" text rounded color="textFaint">
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            Back to presales
          </v-btn>
          -->
          <v-spacer></v-spacer>
          <v-btn :to="`${$store.state.ammPrefix}/ilo/${this.address}/edit`" color="textFaint" text rounded class="ml-2">
            Edit presale
          </v-btn>

        </div>
      </div>

      <div v-if="!$store.state.userSettings.decentralisedPresaleSafetyAlertApproved" :class="{'pa-2': $vuetify.breakpoint.xs}">
        <v-card class="mt-2 mb-1 d-flex align-center font-weight-medium pa-4 br-20">
          <div>
            <div class="d-flex align-center mb-2 orange--text">
              <img 
              src="@/assets/img/flags/exclamation.svg" 
              height="26px"
              class="mr-2"
              width="26px">
              Safety Alert
            </div>
            <div class="caption textFaint--text font-weight-medium">
              This is a decentralised and open presale platform. Similar to Uniswap anyone can create and name a presale freely
              including fake versions of existing tokens. It is also possible for developers of a token to mint near infinite
              tokens and dump them on locked liquidity.
              Please do your own research before using this platform.
            </div>
            <div class="mt-2 d-flex align-center">
              <v-btn to="/terms/ilos" text small rounded>
                Terms And Conditions
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn href="https://unicrypt.medium.com/decentralised-presales-99809cdb398d" target="_blank"
              rounded small text color="text" class="mr-2">
                More info
              </v-btn>
              <v-btn color="orange" rounded class="white--text" @click="disableSafetyAlert">
                I understand
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>

      <v-row dense v-if="firstLoad" class="ma-0">
        <v-col cols="12" md="6">
          <div :class="['mb-16', {'pa-4': $vuetify.breakpoint.xs}, {'v-card pa-8 br-20': !$vuetify.breakpoint.xs}]">
            <div class="text-center" style="height: 80vh;">
              <v-progress-circular
              indeterminate
              size="130"
              color="#aaa">
                <img 
                src="@/assets/img/UNCX_fill.svg" 
                height="120px"
                class="greyscale"
                width="120px">
              </v-progress-circular>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div :class="['mb-16', {'pa-4': $vuetify.breakpoint.xs}, {'v-card pa-8 br-20': !$vuetify.breakpoint.xs}]">
            <div class="text-center" style="height: 80vh;">
              <v-progress-circular
              indeterminate
              size="130"
              color="#aaa">
                <img 
                src="@/assets/img/UNCX_fill.svg" 
                height="120px"
                class="greyscale"
                width="120px">
              </v-progress-circular>
            </div>
          </div>
        </v-col>
      </v-row>

      <template v-else>

        <v-row dense class="ma-0">
          <v-col cols="12" md="6">

            <div :class="['mb-16', {'': $vuetify.breakpoint.xs}, {'v-card br-20': !$vuetify.breakpoint.xs}]">

              <div v-if="dbInfoLoadStatus !== 'success'" class="pa-4 caption">
                Load presale information status: <span class="light-blue--text font-weight-bold">{{ dbInfoLoadStatus }}</span>
              </div>

               <v-row v-if="false" class="">

                <v-col class="text-center" cols="4">

                  <v-progress-circular
                  :value="presaleInfo.liquidity_percent / 10"
                  :rotate="-90"
                  size="40"
                  width="3"
                  color="text">
                    <v-icon size="20" color="text">mdi-lock</v-icon>
                  </v-progress-circular>

                  <div class="title text--text">
                    {{ presaleInfo.liquidity_percent / 10 }}%
                  </div>
                  <div class="caption textFaint--text">
                    Lock {{ lockPeriodHuman }}
                  </div>
                </v-col>
                <v-col class="text-center" cols="4">
                  <v-progress-circular
                  :value="0"
                  :rotate="-90"
                  size="40"
                  width="3"
                  color="light-blue">
                    <v-icon size="20" color="text">mdi-account</v-icon>
                  </v-progress-circular>
                  <div class="title text--text">
                    {{ presaleInfo.num_buyers }}
                  </div>
                  <div class="caption textFaint--text">
                    Participants
                  </div>
                </v-col>
                <v-col class="text-center" cols="4">
                  <v-progress-circular
                  :value="tokenomicsScore"
                  :rotate="-90"
                  size="40"
                  width="3"
                  color="text">
                    <v-icon size="20" color="text">mdi-chart-pie</v-icon>
                  </v-progress-circular>
                  <div :class="['title', 
                  {'text--text': tokenomicsScore >= 60},
                  {'textFaint--text': tokenomicsScore < 60}]">
                    {{ tokenomicsScore }}%
                  </div>
                  <div class="caption textFaint--text">
                    Tokenomics
                  </div>
                </v-col>
              </v-row>

              <div v-if="false" class="px-4 pb-3">
                <v-progress-linear height="30" :value="presaleProgress" 
                :color="tokenomicsIsRed ? 'red' : dbInfo.hot ? 'lgrad-green' : 'lgrad-green'"
                :class="['justify-center font-weight-medium br-20', {'text--text': presaleProgress > 50}, {'text--text': presaleProgress <= 50}]"
                background-color="background">
                  <div class="d-flex px-3 align-center">
                    <div class="">
                      {{ totalEthCollectedHuman }}
                    </div>
                    <div :class="`px-1`">
                      /
                    </div>
                    <div :class="`d-flex align-center`">
                      {{ hardcapHuman }} {{ baseTokenSymbol }}
                    </div>
                  </div>
                </v-progress-linear>

                <!-- UNCL PROGRESS BAR -->
                <v-row class="ma-0" dense>
                  <v-col cols="6">
                    <v-progress-linear rounded height="20" :value="unclProgress" 
                    :color="tokenomicsIsRed ? 'red' : dbInfo.hot ? 'lgrad-blue' : 'lgrad-blue'"
                    :class="['justify-center font-weight-medium br-20', {'text--text': unclProgress > 50}, {'text--text': unclProgress <= 50}]"
                    background-color="background">
                    <img 
                    src="@/assets/img/UNCL.svg"
                    height="18px"
                    width="18px"
                    class="mr-2 ml-2">
                      <div class="caption font-weight-medium">
                        {{ presaleInfo.uncl_participants }} / {{ presaleInfo.uncl_max_participants }}
                      </div>
                    </v-progress-linear>
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear rounded height="20" :value="whitelistProgress" 
                    :color="tokenomicsIsRed ? 'red' : dbInfo.hot ? 'lgrad-blue' : 'lgrad-blue'"
                    :class="['justify-center font-weight-medium br-20', {'text--text': whitelistProgress > 50}, {'text--text': whitelistProgress <= 50}]"
                    background-color="background">
                      <v-icon size="18" class="mx-1" color="text">mdi-file-document-outline</v-icon>
                      <div class="caption font-weight-medium">
                        {{ presaleInfo.whitelist_assigned }} / {{ presaleInfo.whitelist_max_participants }}
                      </div>
                    </v-progress-linear>
                  </v-col>

                </v-row>
                <!-- PROGRESS BAR -->
              </div>

              <!-- USER VESTING -->
              <div v-if="presaleInfo.vesting_implemented" class="border-t">
                <div class="d-flex my-4 mx-2 br-8 align-center pa-3">
                  <v-icon size="50" color="foreground" class="textFaint br-c pa-2">mdi-lock</v-icon>
                  <div class="flex ml-3">
                    <div>
                      {{ presaleInfo.vesting_percentage }}% of your tokens will be locked
                    </div>
                    <div class="caption">
                      Lock Type: {{ presaleInfo.vesting_linear_lock ? 'Linear' : 'Cliff' }}
                    </div>
                    <template v-if="presaleInfo.vesting_linear_lock ">
                      <div class="caption">
                        Start emission: {{ dateStartUserVesting }}
                      </div>
                      <div class="caption">
                        End emission: {{ dateEndUserVesting }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="caption">
                        Unlock Date: {{ dateEndUserVesting }}
                      </div>
                    </template>
                    <div class="caption">
                      Duration: {{ durationUserVesting }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="presaleInfo.vesting_requested" class="border-t">
                <div class="d-flex my-4 mx-2 br-8 align-center pa-3">
                  <!-- <v-icon size="50" color="foreground" class="textFaint br-c pa-2">mdi-lock</v-icon> -->
                  <div class="flex ml-3">
                    <div>
                      <span class="">
                        Participant lock
                      </span>
                    </div>
                    <div class="caption">
                      The developers have requested token vesting on presale participants.
                      Please be aware if Unicrypt approves their request a percentage of your tokens will be locked 
                      upon presale end and not sellable for some time. Exact percentages and unlock date to be determined.
                    </div>
                  </div>
                </div>
              </div>
              <!-- USER VESTING -->

              <div class="">
                <div v-if="false" class="text-center px-4 pt-4">
                  Tokenomics
                </div>
                <div v-if="false" class="textFaint--text text-center caption">
                  Total supply: {{ totalSupplyHuman }}
                </div>
                <div class="d-flex justify-center">
                  <div style="width: 80%;max-width: 250px;">
                    <donut-chart :chartdata="chartData" :options="chartOptions" :red="tokenomicsIsRed" ref="donutChart1" />
                  </div>
                </div>
              </div>

              <div class="mb-1 px-4">
                <v-btn rounded text small color="primary" class="ml-1"
                :to="`/services/lock-tokens?token=${presaleInfo.token.address}`">
                  Lock team tokens
                </v-btn>
              </div>

              <div class="px-8 caption textFaint--text pb-2">
                Locked: refers to token locks with maturity at least 7 days after the presale begins.
              </div>

              <!-- PRICE IMPACT -->
              <div v-if="rugPullSeverityRisk <= 20 && rugPullSeverityRisk > 1 && !dbInfo.hot" class="caption d-flex align-center font-weight-medium px-8 mb-1 textFaint--text" style="position: relative; overflow: hidden;">
                <!-- <div class="primary" style="position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0px;opacity: 0.2;">
                </div> -->
                <div class="flex">
                  <span class="orange--text">
                    -{{ rugPullSeverityRisk }}% price impact
                  </span>
                  <div>
                    if unlocked tokens are dumped on the pool.
                  </div>
                  <div class="d-flex  align-center caption font-weight-medium">
                    Decrease risk in this presale by asking the devs to lock team tokens.
                  </div>
                </div>
              </div>

              <div v-if="rugPullSeverityRisk > 20 && !dbInfo.hot" class="caption d-flex align-center font-weight-medium px-8 mb-1">
                <!-- <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon> -->
                <div class="flex">
                  <span class="orange--text">
                    -{{ rugPullSeverityRisk }}% price impact
                  </span>
                  <div>
                    if unlocked tokens are dumped on the pool.
                  </div>
                  <div class="d-flex  align-center caption font-weight-medium">
                    Decrease risk in this presale by asking the devs to lock team tokens.
                  </div>
                </div>
              </div>

              <div v-if="tokenomicsIsRed && !dbInfo.hot" class="caption d-flex align-center textFaint--text font-weight-medium px-8 mb-1">
                <!-- <v-icon size="26" color="white" class="mr-2">mdi-alert-outline</v-icon> -->
                <div class="flex">
                  <span class="orange--text">
                    -100% price impact
                  </span>
                  <div>
                    if new tokens are <strong>minted</strong> and dumped on the pool.
                  </div>
                  <div class="d-flex  align-center caption font-weight-medium">
                    This token has no audit and is not an ENMT token. This means the contract may contain 
                    minting, blacklisting, or high taxation exploits.
                  </div>
                </div>
              </div>
              <!-- PRICE IMPACT -->

              <!--
              <div v-if="dbInfo.kyc_rating !== 0" class="d-flex lgrad-red white--text align-center font-weight-medium pa-4 border-b mb-1">
                <v-icon size="26" color="white" class="mr-2">mdi-magnify</v-icon>
                <div>
                  This team is anonymous / This is a high risk ILO
                </div>
              </div>
              -->

              <div v-if="dbInfo.audit_rating === 2" class="d-flex lgrad-red white--text align-center font-weight-medium pa-4 border-b">
                <v-icon size="26" color="white" class="mr-2">mdi-file-document-outline</v-icon>
                <div>
                  This token has been flagged by independant auditors. Please check the Audit tab for details.
                </div>
              </div>

              <template v-else-if="dbInfo.audit_rating === 1">
                <!-- minor warning ignore -->
              </template>

              <!--
              <div v-else-if="dbInfo.audit_rating !== 0 && !tokenHydrated.isENMT" class="d-flex lgrad-red white--text align-center font-weight-medium pa-4 border-b">
                <v-icon size="26" color="white" class="mr-2">mdi-file-document-outline</v-icon>
                <div>
                  This token has no audits on Unicrypt.
                </div>
              </div>
              -->

              <!-- LOCKS -->
              <div class="px-8">
                <v-btn rounded block outlined class="my-2 text-center textFaint--text" @click="showLocks = !showLocks">
                  {{ showLocks ? 'Hide' : 'Show' }} Token Locks
                </v-btn>
              </div>

              <v-expand-transition mode="out-in">
                <div v-if="showLocks">
                  <v-tabs
                  v-model="lock_tab"
                  fixed-tabs
                  background-color="midground"
                  class="text--text"
                  color="text"
                  >
                    <v-tab class="caption">
                      Locked ({{ tokenLocks.locked.length }})
                    </v-tab>
                    <v-tab class="caption">
                      Partial Lock ({{ tokenLocks.partial.length }})
                    </v-tab>
                    <v-tab class="caption">
                      Unlocked ({{ tokenLocks.unlocked.length }})
                    </v-tab>
                  </v-tabs>

                  <!-- VALID LOCKS -->
                  <div v-if="lock_tab === 0">
                    <div v-if="tokenLocks.locked.length > 0">
                      <lock-row 
                      v-for="lock in paginatedLocks" 
                      :key="lock.id"
                      :item="lock"
                      :disableDialogs="true"
                      :tokenHydrated="presaleInfo.token">
                      </lock-row>
                    </div>
                    <div v-else class="pa-4 textFaint--text">
                      No valid locks for this token
                    </div>
                  </div>

                  <!-- PARTIAL LOCKS -->
                  <div v-if="lock_tab === 1">
                    <div class="background textFaint--text caption pa-4 ma-1">
                      Partially locked locks start emitting 7 days before the presale begins, this includes linear locks which have started emitting already.
                    </div>
                    <div v-if="tokenLocks.partial.length > 0">
                      <lock-row 
                      v-for="lock in paginatedLocks" 
                      :key="lock.id"
                      :item="lock"
                      :disableDialogs="true"
                      :tokenHydrated="presaleInfo.token">
                      </lock-row>
                    </div>
                    <div v-else class="pa-4 textFaint--text">
                      No partial locks for this token
                    </div>
                  </div>

                  <!-- PARTIAL LOCKS -->
                  <div v-if="lock_tab === 2">
                    <div class="background textFaint--text caption pa-4 ma-1">
                      Locks are conidered unlocked if they unlock within 7 days of presale start block.
                    </div>
                    <div v-if="tokenLocks.unlocked.length > 0">
                      <lock-row 
                      v-for="lock in paginatedLocks" 
                      :key="lock.id"
                      :item="lock"
                      :disableDialogs="true"
                      :tokenHydrated="presaleInfo.token">
                      </lock-row>
                    </div>
                    <div v-else class="pa-4 textFaint--text">
                      No unlocked locks for this token
                    </div>
                  </div>

                  <!-- LOCK PAGINATION -->
                  <div v-if="pageCount > 1" class="d-flex align-center justify-center background pa-2">
                    <v-btn :disabled="lockPage === 0" @click="lockPage = 0" text icon color="text">
                      <v-icon>mdi-page-first</v-icon>
                    </v-btn>
                    <v-btn @click="lockPage--" text :disabled="lockPage === 0">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    {{ lockPage + 1 }} / {{ pageCount }}
                    <v-btn @click="lockPage++" text :disabled="!nextLockPageExists">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn icon :disabled="!nextLockPageExists" @click="lockPage = pageCount - 1" text color="text">
                      <v-icon>mdi-page-last</v-icon>
                    </v-btn>
                  </div>
                  <!-- LOCK PAGINATION -->
                </div>
              </v-expand-transition>
              <!-- LOCKS -->
            </div>

          </v-col>
          <v-col cols="12" md="6">
            
            <div v-if="false" class="py-1">

              <v-btn href="https://t.me/Unicrypt_Presales" target="_blank" text rounded color="textFaint">
                <v-icon size="26" class="mr-1">mdi-telegram</v-icon>
                Unicrypt ILO chat
                <!-- <v-icon x-small class="ml-1">mdi-arrow-top-right</v-icon> -->
              </v-btn>
              <v-btn href="https://discord.gg/WrSThq3d8U" target="_blank" text rounded color="textFaint">
                <v-icon size="20" class="mr-1">mdi-discord</v-icon>
                Unicrypt Discord chat
                <!-- <v-icon x-small class="ml-1">mdi-arrow-top-right</v-icon> -->
              </v-btn>
            </div>

            <div :class="['', {'': $vuetify.breakpoint.xs}, {'br-20 v-card': !$vuetify.breakpoint.xs}]" style="overflow: hidden;">
              <!-- AUDIT -->
              <audit-block :address="presaleInfo.token.address" @on-update="loadDBInfo"></audit-block>
              <!-- AUDIT -->
            </div>

            <!-- PRESALE CARD -->
            <div :class="['mb-16 mt-2', {'': $vuetify.breakpoint.xs}, {'v-card br-20': !$vuetify.breakpoint.xs}]">
              <v-tabs
              v-model="tab"
              fixed-tabs
              background-color="midground"
              class="text--text border-b"
              color="text"
              >
                <v-tab>
                  Presale
                </v-tab>
                <v-tab>
                  Info
                </v-tab>
                <!--
                <v-tab>
                  Disqus (
                  <DisqusCount shortname='unicrypt-network' :identifier="netIdentifier" />
                  )
                </v-tab>
                -->
              </v-tabs>
              
              <div class="pa-4">
                <div v-show="tab === 0">
                  <v-card v-if="!showRiskyPresaleCard && dbInfo.kyc_rating !== 0 && !dbInfo.hot" class="lgrad-red white--text d-flex align-center font-weight-medium pa-4 br-8">
                    <div>
                      <div class="d-flex align-center mb-2">
                        <img 
                        src="@/assets/img/flags/exclamation.svg" 
                        height="26px"
                        class="mr-2"
                        width="26px">
                        SAFETY ALERT
                      </div>
                      <div class="caption font-weight-medium">
                        This token is high risk. Please confirm you have read all warnings and understand that you stand to lose everything you invest in 
                        this presale.
                      </div>
                      <div class="mt-2 d-flex align-center">
                        <v-spacer></v-spacer>
                        <v-btn color="white" rounded class="red--text" @click="showRiskyPresaleCard = true">
                          I understand
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                  
                  <template v-else>
                    
                    <v-card v-if="SHOWALL" class="pa-4 mb-2 br-20">
                      <presale-info-card :address="address"></presale-info-card>
                    </v-card>

                    <div outlined v-if="presaleStage === 0 || SHOWALL" class="mb-2">
                      <reserve-with-uncl :presale_info="presaleInfo"></reserve-with-uncl>
                    </div>

                    <!-- USER CARD -->
                    <div v-if="!sEthers.coinbase" class="pa-2">
                      <v-btn rounded large block outlined depressed class="primary--text pa-1" style="border: 2px solid var(--v-outline-base);" @click="connectWallet">
                        <div class="">
                          <div class="">
                            Connect your wallet
                          </div>
                        </div>
                      </v-btn>
                    </div>

                    <div v-if="sEthers.coinbase" class="mb-1">
                      <div class="pa-4 d-flex align-center">
                        <v-icon size="40" color="textFaint">mdi-account-circle</v-icon>
                        <div class="ml-2">
                          <div class="mb-1">
                            Your account 
                            <span class="textFaint--text caption">({{ sEthers.coinbase_condensed }})</span>
                          </div>

                          <!-- BADGES -->
                          <div class="d-flex align-center flex-wrap">

                            <div v-if="userIsWhitelisted" class="caption d-flex align-center font-weight-medium text background--text pr-2 br-20">
                              <v-icon size="16" color="background" class="mx-1">mdi-rocket</v-icon>
                              Whitelisted
                            </div>
                            <div v-if="userHasReservedASpot" class="caption d-flex align-center font-weight-medium text background--text pr-2 br-20">
                              <img 
                              src="@/assets/img/UNCL.svg"
                              height="16px"
                              width="16px"
                              class="mx-1">
                              Reserved
                            </div>
                          </div>
                          <!-- BADGES -->

                          <div v-if="userInfo.tokens_owed !== '0'" outlined class="d-flex align-center">
                            <!-- <coin-icon :url="presaleInfo.icon_url" :size="30"></coin-icon> -->
                            <div class="">
                              <div class="caption">
                                You've bought
                                <span class="caption font-weight-bold">
                                  {{ $root.formatAmount(userInfo.tokens_owed, presaleInfo.token.decimals) }} {{ presaleInfo.token.symbol }}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="d-flex align-center">

                            <div class="text-left">
                              <div class="text--text caption">
                                Your spent allowance
                              </div>
                              <div class="caption font-weight-bold">
                                {{ userEthDepositedHuman }} / {{ userMaxLimitHuman }} {{ baseTokenSymbol }}
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
  
                    </div>
                    <!-- USER CARD -->

                    <v-stepper v-if="presaleStage > 0 && false" alt-labels v-model="presaleStep">
                      <v-stepper-header>
                        <v-stepper-step 
                        step="1" 
                        :complete="presaleStep > 1">
                          <span class="textFaint--text">
                            Round 1
                          </span>
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="2" :complete="presaleStep > 2">
                          <span class="textFaint--text">
                            Round 2
                          </span>
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="3" :complete="presaleStep > 2">
                          <span class="textFaint--text">
                            <template v-if="presaleStage === 4">
                              Failed
                            </template>
                            <template v-else>
                              Complete
                            </template>
                          </span>
                        </v-stepper-step>

                      </v-stepper-header>
                    </v-stepper>

                    <div v-if="false" class="display-1 mb-6 d-flex align-center">
                      
                      <v-spacer></v-spacer>
                      <!--
                      <div class="title font-weight-medium excolor px-2 py-2 br-8" style="position: relative; overflow: hidden;">
                        <div :class="presaleStageColor" style="position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0px;opacity: 0.2;">

                        </div>
                        <span class="text-uppercasee">{{ presaleStageString }}</span>
                      </div>
                      -->
                    </div>

                    <!--
                    <v-card class="pa-4 br-20" v-if="presaleStage === 0 || presaleStage === 1">
                      <round-1-requirements :round1Length="presaleInfo.round1_length" :settings_contract_address="presale_settings_address"></round-1-requirements>
                    </v-card>
                    -->
                    
                    <v-card v-if="userInfo.tokens_owed !== '0' && (presaleStage === 1 || presaleStage === 2 || presaleStage === 3)" outlined class="d-flex background textFaint--text align-center pa-4 mt-4 br-20">
                      <coin-icon :url="dbInfo.icon_url" :size="30"></coin-icon>
                      <div class="ml-3">
                        <div>
                          You've bought
                        </div>
                        <div class="font-weight-bold">
                          {{ userTokensOwedHuman }} {{ presaleInfo.token.symbol }}
                        </div>
                        <div class="caption textFaint--text">
                          You can withdraw your {{ presaleInfo.token.symbol }} once the presale has concluded.
                        </div>
                      </div>
                    </v-card>

                    <!-- BUY CARD -->
                    <template v-if="
                    (
                    (presaleStage === 0 && userIsWhitelisted) ||
                    (presaleStage === 0 && userHasReservedASpot) ||
                    (presaleStage === 1 && userHoldsRound1Tokens) ||
                    (presaleStage === 2) || 
                    SHOWALL
                    ) && sEthers.coinbase">
                      <v-card class="pa-4 mt-4 br-20">

                        <div v-if="userIsWhitelisted || userHasReservedASpot" class="lgrad-blue white--text pa-4 br-8">
                          <div class="title">
                            You have a guaranteed allocation!
                          </div>
                          You must participate <strong>before</strong> round 1 begins, thereafter its a first come first serve presale.
                        </div>

                        <user-spend-eth :presaleInfo="presaleInfo"></user-spend-eth>
                      </v-card>
                    </template>
                    <!-- BUY CARD -->

                    <!-- WITHDRAW CARD -->
                    <!-- <user-withdraw-token v-if="presaleStage === 5 && userInfo.tokens_owed !== '0' || SHOWALL" :presaleInfo="presaleInfo" :presaleSettings="presaleSettings" class="mt-4"></user-withdraw-token> -->
                    <user-withdraw-token v-if="presaleStage === 5 && userInfo.eth_deposited !== '0' || SHOWALL" :presaleInfo="presaleInfo" :presaleSettings="presaleInfo" class="mt-4"></user-withdraw-token>
                    <user-withdraw-eth v-if="presaleStage === 4 && sEthers.coinbase && (userInfo.eth_deposited !== '0' || userInfo.uncl_owed !== '0') || SHOWALL" :presaleInfo="presaleInfo" class="mt-4"></user-withdraw-eth>
                    <!-- WITHDRAW CARD -->

                    <div v-if="presaleStage === 3 || SHOWALL" class="v-card br-20 mt-8 pa-8 text-center">
                      <div class="title mb-2 primary--text">
                        Presale success!
                      </div>
                      <div class="">
                        Anyone from the public can call this function to create the pool, lock liquidity and enable token withdrawals.
                      </div>
                      <v-btn @click="addLiquidity" rounded class="mt-3" x-large block outlined>
                        Initialize Markets!
                      </v-btn>
                    </div>

                    <v-card v-if="false" outlined class="background br-20 pa-4">
                      <div class="text-center caption textFaint--text">
                        Add {{ presaleInfo.token.symbol }} to your wallet as a custom token with the following address
                      </div>
                      <div class="text-center">
                        <copy-address :address="presaleInfo.token.address" color=""></copy-address>
                      </div>
                    </v-card>

                    <div v-if="false" class="caption mt-8">
                      <div>
                        {{ presaleInfo }}
                      </div>
                    </div>

                  </template>
                </div>

                <div v-show="tab === 1">

                  <div class="mt-4 textFaint--text">

                    <div class="">
                      <div class="title text-center text--text mb-2">
                        Presale info
                      </div>
                      <div class="textFaint--text pa-4">
                        <div v-if="dbInfo.website_url">
                          Website: 
                          <a :href="dbInfo.website_url" target="_blank" class="deadlink primary--text">
                            {{ dbInfo.website_url }}
                            <v-icon x-small color="primary">mdi-arrow-top-right</v-icon>
                          </a>
                        </div>
                        <div v-if="dbInfo.twitter_url">
                          Twitter: 
                          <a :href="dbInfo.twitter_url" target="_blank" class="deadlink primary--text">
                            {{ dbInfo.twitter_url }}
                            <v-icon x-small color="primary">mdi-arrow-top-right</v-icon>
                          </a>
                        </div>
                        <div v-if="dbInfo.telegram_url">
                          Telegram: 
                          <a :href="dbInfo.telegram_url" target="_blank" class="deadlink primary--text">
                            {{ dbInfo.telegram_url }}
                            <v-icon x-small color="primary">mdi-arrow-top-right</v-icon>
                          </a>
                        </div>
                      </div>

                      <div class="d-flex align-center px-4">
                        <span class="textFaint--text">
                          Token
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn small rounded color="textFaint" text :href="`${$store.state.etherscan_url}/address/${presaleInfo.token.address}`" target="_blank">
                          {{ $store.state.explorer_name }}
                          <v-icon x-small>mdi-arrow-top-right</v-icon>
                        </v-btn>
                        <copy-address :address="presaleInfo.token.address" color="textFaint"></copy-address>
                      </div>

                      <div class="pa-4">
                        <div>
                          Start block: {{ startBlock }} (Approx: {{ dateStartBlockHuman }})
                        </div>
                        <div v-if="$store.state.superUserMode && $store.state.user.jwtObject.user_id === '21'" class="primary--text">
                          Start block real: {{ presaleInfo.start_block }}
                        </div>
                        <div>
                          End block: {{ presaleInfo.end_block }} (Approx: {{ dateEndBlockHuman }})
                        </div>
                      </div>
                    </div>

                    <div class="pa-3 d-flex border-b">
                      <v-icon size="40">mdi-lock-outline</v-icon>
                      <div class="ml-4">
                        <div class="text--text">
                          {{ presaleInfo.liquidity_percent / 10 }}% {{ baseTokenSymbol }} raised liquidity lock
                        </div>
                        <div class="textFaint--text">
                          {{ lockPeriodHuman }} lock duration
                        </div>
                      </div>
                    </div>

                    <div class="pa-3 d-flex border-b">
                      <v-icon size="40">mdi-ethereum</v-icon>
                      <div class="ml-4">
                        <div class="text--text">
                          {{ softcapHuman }} {{ baseTokenSymbol }}
                        </div>
                        <div class="textFaint--text">
                          Softcap
                        </div>
                      </div>
                    </div>

                    <div class="pa-3 d-flex border-b">
                      <v-icon size="40">mdi-ethereum</v-icon>
                      <div class="ml-4">
                        <div class="text--text">
                          {{ hardcapHuman }} {{ baseTokenSymbol }}
                        </div>
                        <div class="textFaint--text">
                          Hardcap
                        </div>
                      </div>
                    </div>

                    <div class="pa-3 d-flex border-b">
                      <v-icon size="40">mdi-ethereum</v-icon>
                      <div class="ml-4">
                        <div class="text--text">
                          {{ userMaxLimitHuman }} {{ baseTokenSymbol }}
                        </div>
                        <div class="textFaint--text">
                          Max spend per account
                        </div>
                      </div>
                    </div>

                    <div class="pa-3 d-flex border-b">
                      <v-icon size="40">mdi-ethereum</v-icon>
                      <div class="ml-4">
                        <div class="text--text">
                          {{ tokenPriceHuman }} {{ presaleInfo.token.symbol }} <span class="textFaint--text caption">/ per {{ baseTokenSymbol }}</span>
                        </div>
                        <div class="textFaint--text">
                          Presale price
                        </div>
                      </div>
                    </div>

                    <div class="pa-3 d-flex">
                      <v-icon size="40">mdi-ethereum</v-icon>
                      <div class="ml-4">
                        <div class="text--text">
                          {{ listingPriceHuman }} {{ presaleInfo.token.symbol }} <span class="textFaint--text caption">/ per {{ baseTokenSymbol }}</span>
                        </div>
                        <div class="textFaint--text">
                          Listing price
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <!--
                <div v-if="tab === 2">
                  <div class="text-center textFaint--text mb-4">
                    Discuss the presale token. Mention mints / proxies or dangerous code below.
                  </div>
                  <comments :identifier="`token_${presaleInfo.token.address}`"></comments>
                </div>
                -->
              </div>

            </div>
            <!-- PRESALE CARD -->
          </v-col>
        </v-row>
        <!-- PRO AND CON -->
      </template>

    </div>

    <kyc-dialog ref="kycDialog"></kyc-dialog>

  </v-container>
</template>

<script>
import PresaleSettingsContract from '@/smart-contracts/presales/v6/presale-settings'
import PresaleContract from '@/smart-contracts/presales/v6/presale-contract'
import PresaleABI from '@/smart-contracts/presales/v6/presale-abis'
import ReserveWithUncl from './reserve-with-uncl'
import VestingPager from '@/smart-contracts/token-locker/pager-contract'
import VESTABI from '@/smart-contracts/token-locker/token-locker-abis'
import { ethers } from 'ethers'
import TxWrapper from '@/web3/tx-wrapper-2';
import PresaleInfoCard from '@/views/presale/presale/presale-info-card'
import UserWithdrawToken from './user-withdraw-token'
import UserWithdrawEth from './user-withdraw-eth'
import UserSpendEth from './user-spend-eth'
import SuperUser from './super-user'
import moment from 'moment'
// import Round1Requirements from '@/views/presale/presale/round-1-requirements'
import ERC20 from '@/smart-contracts/erc20/pager'
import ERC20BALANCE from '@/smart-contracts/erc20'
import DonutChart from '@/components/charts/donut6burn'
// import Comments from '@/components/disqus/comments'
// import { DisqusCount } from 'vue-disqus'
import LockRow from '@/views/locker/erc20/user/user-row'
import AuditBlock from '@/views/audit-kyc/main-block'
import KycDialog from '@/views/presale/KYCDialog'
import ChartLinks from '@/components/linkers/charts'

import V2PAIR from '@/smart-contracts/uniswap/v2-pair'
import V2FACTORY from '@/smart-contracts/uniswap/uniswap-factory-contract'

export default {
  components: {
    UserWithdrawToken,
    UserWithdrawEth,
    UserSpendEth,
    PresaleInfoCard,
    DonutChart,
    // Comments,
    // DisqusCount,
    ChartLinks,
    LockRow,
    ReserveWithUncl,
    SuperUser,
    AuditBlock,
    KycDialog
  },

  props: {
    address: {
      type: String
    }
  },

  data: () => ({
    tab: 0,
    lock_tab: 0,
    showLocks: false,
    userInfo: {
      eth_deposited: '0',
      tokens_owed: '0',
      uncl_owed: '0'
    },
    userIsWhitelisted: false,
    tokenHydrated: {},
    presaleInfo: {
      token: {},
      presale_owner: '',
      eth_price: '0',
      token_price: '0',
      max_spend_per_buyer: '0',
      hardcap: '0',
      softcap: '0'
    },
    dbInfo: {

    },
    dbInfoLoadStatus: 'loading', // loading, failed, success
    totalSupply: '0',
    userHoldsRound1Tokens: false,
    SHOWALL: false,
    firstLoad: true,
    chartData: {
      labels: ['Presale', 'Liquidity', 'Fees', 'Locked', 'Burnt', 'Unlocked'],
      datasets: [{
        data: [],
        backgroundColor: [
          'rgb(20, 240, 132)',
          'rgb(54, 162, 235)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
        ],
        borderWidth: 1
        // borderColor: 'rgba(0,0,0,0.1)'
      }]

    },
    chartOptions: {
      cutoutPercentage: 70,
      legend: {
        position: 'right'
      }
    },
    tokenLocks: {
      locked: [],
      partial: [],
      unlocked: []
    },
    tokenomicsScore: 0,
    TOKENOMICS: {
      presale: '0',
      liquidity: '0',
      fees: '0',
      locked: '0',
      unlocked: '0'
    },
    showRiskyPresaleCard: true,
    rowsPerLockPage: 20,
    lockPage: 0,

    price_diff: 0,
    price_diff_positive: true,

    finalizedPairAddress: null
  }),

  computed: {
    pageCount () {
      var count = Math.ceil(this.tabLocks.length / this.rowsPerLockPage)
      return count === 0 ? 1 : count
    },
    // return locks specific to current tab
    tabLocks () {
      if (this.lock_tab === 0) {
        return this.tokenLocks.locked
      } else if (this.lock_tab === 1) {
        return this.tokenLocks.partial
      } else if (this.lock_tab === 2) {
        return this.tokenLocks.unlocked
      }
      return []
    },
    paginatedLocks () {
      var offset = this.lockPage * this.rowsPerLockPage
      return this.tabLocks.slice(offset, offset + this.rowsPerLockPage)
    },
    nextLockPageExists () {
      return (this.lockPage + 1) * this.rowsPerLockPage < this.tabLocks.length
    },
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    presale_settings_address () {
      return PresaleABI.presale_settings_address()
    },
    tokenomicsIsRed () {
      return false
      /* if (this.dbInfo.audit_rating === 2) {
        return true
      }
      return !(this.tokenHydrated.isENMT || this.dbInfo.audit_rating === 0 || this.dbInfo.audit_rating === 1) */
    },
    netIdentifier () {
      var identifier = `token_${this.presaleInfo.token.address}`
      return `${this.$store.state.requiredNetwork}_${identifier}`
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    startBlock () {
      return Number(this.presaleInfo.start_block)
    },
    tokenAddressCondensed () {
      var address = this.presaleInfo.token.address
      if (!address) {
        return ''
      }
      return address.slice(0, 6) + '...' + address.slice(address.length - 4)
    },
    userHasReservedASpot () {
      return ethers.BigNumber.from(this.userInfo.uncl_owed).gt(0)
    },
    liquidityAtHardcap () {
      var listingRate = ethers.BigNumber.from(this.presaleInfo.listing_rate).mul(1000).div(this.presaleInfo.token_price).toString()
      var amount = ethers.BigNumber.from(this.presaleInfo.amount).mul(this.presaleInfo.liquidity_percent).mul(listingRate).div(1000000).toString()
      return ethers.BigNumber.from(amount).toString()
    },
    percentTokensOfTotal () {
      if (this.totalSupply === '0') {
        return '0'
      }
      var amount = ethers.BigNumber.from(this.liquidityAtHardcap).add(this.presaleInfo.amount)
      var percent = ethers.BigNumber.from(amount).mul(100).div(this.totalSupply)
      return percent.toNumber()
    },
    baseTokenSymbol () {
      return this.presaleInfo.presale_in_eth ? this.$store.state.nativeGasTokenSymbol : this.presaleInfo.base_token.symbol.toUpperCase()
    },
    userIsOwner () {
      return this.sEthers.coinbase === this.presaleInfo.presale_owner
    },
    totalSupplyHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.token.totalSupply, this.presaleInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: this.presaleInfo.token.decimals})
    },
    userTokensOwedHuman () {
      var amount = ethers.utils.formatUnits(this.userInfo.tokens_owed, this.presaleInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: this.presaleInfo.token.decimals})
    },
    userMaxLimitHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.max_spend_per_buyer, this.presaleInfo.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    userEthDepositedHuman () {
      var amount = ethers.utils.formatUnits(this.userInfo.eth_deposited, this.presaleInfo.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: this.presaleInfo.base_token.decimals})
    },
    remainingSpendableAllowance () {
      return ethers.BigNumber.from(this.presaleInfo.max_spend_per_buyer).sub(this.userInfo.eth_deposited).toString()
    },
    tokenPriceHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.token_price, this.presaleInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    listingPriceHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.listing_rate, this.presaleInfo.token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    lockPeriodHuman () {
      var endOfLock = moment().unix() + Number(this.presaleInfo.lock_period)
      if (endOfLock > 9999999999) {
        var diff = Number(this.presaleInfo.lock_period) - moment().unix()
        return moment.duration(diff * 1000).years() + ' Years'
      }
      return this.$root.getReadableTimeSpan(this.presaleInfo.lock_period)
    },
    totalEthCollectedHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.total_base_collected, this.presaleInfo.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    softcapHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.softcap, this.presaleInfo.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    hardcapHuman () {
      var amount = ethers.utils.formatUnits(this.presaleInfo.hardcap, this.presaleInfo.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    presaleProgress () {
      var percent = ethers.BigNumber.from(this.presaleInfo.total_base_collected).mul(1000).div(this.presaleInfo.hardcap)
      return percent.toNumber() / 10
      // return 0
    },
    unclProgress () {
      var max_participants = this.presaleInfo.uncl_max_participants === '0' ? 1 : this.presaleInfo.uncl_max_participants
      var percent = ethers.BigNumber.from(this.presaleInfo.uncl_participants).mul(1000).div(max_participants)
      return percent.toNumber() / 10
    },
    whitelistProgress () {
      var max_participants = this.presaleInfo.whitelist_max_participants === '0' ? 1 : this.presaleInfo.whitelist_max_participants
      var percent = ethers.BigNumber.from(this.presaleInfo.whitelist_assigned).mul(1000).div(max_participants)
      return percent.toNumber() / 10
    },
    rugPullSeverityRisk () { 
      if (this.TOKENOMICS.liquidity === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.TOKENOMICS.unlocked).mul(1000).div(this.TOKENOMICS.liquidity)
      percent = percent.toNumber() / 10
      if (percent > 100) {
        percent = 100
      }
      return percent
    },
    presaleSoftcapProgress () {
      var percent = ethers.BigNumber.from(this.presaleInfo.total_base_collected).mul(1000).div(this.presaleInfo.softcap)
      return percent.toNumber() / 10
      // return 0
    },
    dateStartBlock () {
      var numBlocks = this.startBlock - this.blockNumber
      var seconds = numBlocks * this.$store.state.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateStartBlockHuman () {
      return this.dateStartBlock.format('ddd D MMM HH:mm')
    },
    timeToStartBlock () {
      return this.dateStartBlock.fromNow()
    },
    dateEndBlock () {
      var numBlocks = this.presaleInfo.end_block - this.blockNumber
      var seconds = numBlocks * this.$store.state.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateEndBlockHuman () {
      return this.dateEndBlock.format('ddd D MMM HH:mm')
    },
    timeToEndBlock () {
      return this.dateEndBlock.fromNow()
    },
    dateStartUserVesting () {
      var date = moment(this.dateStartBlock).add(Number(this.presaleInfo.vesting_start_emission), 'seconds')
      return date.format('D MMM HH:mm, yyyy')
    },
    dateEndUserVesting () {
      var date = moment(this.dateStartBlock).add(Number(this.presaleInfo.vesting_end_emission), 'seconds')
      return date.format('D MMM HH:mm, yyyy')
    },
    durationUserVesting () {
      var startDate = moment(this.dateStartBlock)
      var endDate = moment(this.dateStartBlock).add(Number(this.presaleInfo.vesting_end_emission), 'seconds')
      var duration = endDate.diff(startDate)
      return this.$root.getReadableTimeSpan(duration / 1000)
    },
    round2StartBlock () {
      return Number(this.startBlock) + Number(this.presaleInfo.round1_length)
    },
    round2Date () {
      var numBlocks = this.round2StartBlock - this.blockNumber
      var seconds = numBlocks * this.$store.state.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    timeToRound2 () {
      return this.round2Date.fromNow()
    },
    round2DateHuman () {
      return this.round2Date.format('ddd D MMM HH:mm')
    },
    presaleStage () {
      if (this.presaleInfo.lp_generation_complete) {
        return 5 // finalized
      }
      if (this.presaleInfo.force_failed) {
        return 4 // failed
      }
      if (Number(this.blockNumber) > Number(this.presaleInfo.end_block) && ethers.BigNumber.from(this.presaleInfo.total_base_collected).lt(this.presaleInfo.softcap)) {
        return 4 // failed
      }
      if (ethers.BigNumber.from(this.presaleInfo.total_base_collected).gte(this.presaleInfo.hardcap)) {
        return 3 // success
      }
      if (Number(this.blockNumber) > Number(this.presaleInfo.end_block) && ethers.BigNumber.from(this.presaleInfo.total_base_collected).gte(this.presaleInfo.softcap)) {
        return 3 // success
      }
      if (Number(this.blockNumber) >= Number(this.startBlock) && Number(this.blockNumber) <= Number(this.presaleInfo.end_block)) {
        if (Number(this.blockNumber) < (Number(this.startBlock) + Number(this.presaleInfo.round1_length))) {
          return 1 // round 1
        }
        return 2 // round 2
      }

      return 0 // awaiting start
    },
    presaleStep () {
      if (this.presaleStage === 5 || this.presaleStage === 4 || this.presaleStage === 3) {
        return 3
      }
      if (this.presaleStage === 2) {
        return 2
      }
      if (this.presaleStage === 1) {
        return 1
      }
      return 0
    },
    presaleStageString () {
      var arr = ['Awaiting Start', 'LIVE: Round 1', 'LIVE: Round 2', 'Success', 'Failed', 'Trading']
      return arr[this.presaleStage]
    },
    presaleStageColor () {
      var arr = ['blue', 'primary', 'primary', 'primary', 'pink', 'purple']
      return arr[this.presaleStage]
    }
  },

  watch: {
    lock_tab () {
      this.lockPage = 0
    },
    sEthersWatcher () {
      this.refresh()
    },
    blockNumber () {
      this.refresh()
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    disableSafetyAlert () {
      this.$store.commit('editUserSettings', {
        setting: 'decentralisedPresaleSafetyAlertApproved',
        value: true
      })
    },
    async getPriceDiff () {
      if (this.presaleStage !== 5) {
        return
      }
      this.refreshPriceLoading = true
      console.log(this.presaleInfo)
      this.finalizedPairAddress = await V2FACTORY.getPair(this.presaleInfo.token.address, this.presaleInfo.base_token.address, this.$store.state.requiredNetwork, this.$store.state.exchange)
      var reserves = await V2PAIR.getReserves(this.finalizedPairAddress, this.$store.state.requiredNetwork)
      var token0 = await V2PAIR.token0(this.finalizedPairAddress, this.$store.state.requiredNetwork)

      var priceToken0
      if (token0 === this.presaleInfo.token.address) {
        priceToken0 = ethers.BigNumber.from(ethers.BigNumber.from(10).pow(this.presaleInfo.base_token.decimals)).mul(reserves.reserve0).div(reserves.reserve1)
      } else {
        priceToken0 = ethers.BigNumber.from(ethers.BigNumber.from(10).pow(this.presaleInfo.base_token.decimals)).mul(reserves.reserve1).div(reserves.reserve0)
      }
      this.current_price = priceToken0

      var priceDifference = ethers.BigNumber.from(this.presaleInfo.token_price).sub(this.current_price)
      priceDifference = priceDifference.mul(10000).div(this.current_price).toString()
      var newPrice = Number(priceDifference / 100)
      this.price_diff_positive = newPrice >= this.price_diff 
      this.price_diff = newPrice
      this.refreshPriceLoading = false
    },
    async addLiquidity () {
      TxWrapper.doTransaction(PresaleContract.addLiquidity,
        [this.address], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          // this.lockLoading = false
        })
    },
    async getUserInfo () {
      if (!this.sEthers.coinbase) {
        this.userInfo = {
          eth_deposited: '0',
          tokens_owed: '0',
          uncl_owed: '0'
        }
        this.userHoldsRound1Tokens = false
        return
      }

      var userIsWhitelisted = await PresaleContract.getUserWhitelistStatus(this.address, this.sEthers.coinbase, this.$store.state.requiredNetwork)
      this.userIsWhitelisted = userIsWhitelisted

      var userInfo = await PresaleContract.userInfo(this.address, this.sEthers.coinbase, this.$store.state.requiredNetwork)
      this.userInfo = userInfo

      this.userHoldsRound1Tokens = await PresaleSettingsContract.userHoldsSufficientRound1Token(this.sEthers.coinbase, this.$store.state.requiredNetwork)
    },
    async getPresaleInfo () {
      var response = await PresaleContract.getInfo(this.address, this.$store.state.requiredNetwork)
      this.presaleInfo = response.presale_info
    },
    async loadDBInfo () {
      this.dbInfoLoadStatus = 'loading'
      this.$axios.get(`/presales/info/${this.address}`)
        .then(response => {
          this.dbInfo = response.data
          this.dbInfoLoadStatus = 'success'
          if (this.dbInfo.kyc_rating !== 0) {
            // this.$refs.kycDialog.open()
          }
        })
        .catch(error => {
          console.log(error)
          this.dbInfoLoadStatus = 'failed'
        })
    },
    async getTotalSupply () {
      try {
        this.totalSupply = this.tokenHydrated.totalSupply

        var listingRatePercent = ethers.BigNumber.from(this.presaleInfo.listing_rate).mul(1000).div(this.presaleInfo.token_price).toString()
        var unicryptFee = ethers.BigNumber.from(this.presaleInfo.amount).mul(this.presaleInfo.fee_token).div(1000).toString()
        var liquidity = ethers.BigNumber.from(this.presaleInfo.amount).sub(unicryptFee).mul(this.presaleInfo.liquidity_percent).mul(listingRatePercent).div(1000000).toString()

        // tokenLocks
        var numLocks = await VestingPager.getTokenLocksLength(this.presaleInfo.token.address, this.$store.state.requiredNetwork)
        var rows = []
        var page = 0;
        while (rows.length < numLocks) {
          var lock_ids = await VestingPager.getTokenLocks(this.presaleInfo.token.address, page * VESTABI.MAX_GETTER_LENGTH, VESTABI.MAX_GETTER_LENGTH, this.$store.state.requiredNetwork)
          var locks = await VestingPager.getLocks(lock_ids, this.$store.state.requiredNetwork)
          rows.push(...locks)
          page++
        }
        // total locked
        // var now = moment().unix()
        // var now = this.dateStartBlock.unix()
        var now = moment(this.dateStartBlock).add(6, 'days').unix()
        var unlockedLocks = []
        var lockedLocks = []
        var partialLocks = []
        rows.forEach(item => {
          if (item.start_emission !== 0) { // Linear locks
            if (item.start_emission > now && item.condition === ethers.constants.AddressZero) {
              lockedLocks.push(item)
            } else if (item.end_emission > now && item.condition === ethers.constants.AddressZero) {
              partialLocks.push(item)
            } else {
              unlockedLocks.push(item)
            }
          } else { // Cliff locks
            if (item.end_emission > now && item.condition === ethers.constants.AddressZero) {
              lockedLocks.push(item)
            } else {
              unlockedLocks.push(item)
            }
          }
        })
        var amountLocked = JSON.parse(JSON.stringify(lockedLocks)).reduce((a, item) => {
          return a.add(item.tokens_deposited).sub(item.tokens_withdrawn)
        }, ethers.BigNumber.from(0))
        var amountLockedPartially = JSON.parse(JSON.stringify(partialLocks)).reduce((a, item) => {
          var timeclamp = now
          if (timeclamp > item.end_emission) {
            timeclamp = item.end_emission;
          }
          if (timeclamp < item.start_emission) {
            timeclamp = item.start_emission
          }
          var remainingTime = item.end_emission - timeclamp
          var fullperiod = item.end_emission - item.start_emission
          var tokens = ethers.BigNumber.from(item.tokens_deposited).sub(item.tokens_withdrawn)
          var locked = tokens.mul(remainingTime).div(fullperiod)
          return a.add(locked)
        }, ethers.BigNumber.from(0))
        amountLocked = amountLocked.add(amountLockedPartially)

        this.tokenLocks.locked = lockedLocks.sort((a, b) => a.end_emission - b.end_emission)
        this.tokenLocks.unlocked = unlockedLocks.sort((a, b) => a.end_emission - b.end_emission)
        this.tokenLocks.partial = partialLocks.sort((a, b) => a.end_emission - b.end_emission)

        var burnt = await ERC20BALANCE.getBalance('0x000000000000000000000000000000000000dEaD', this.presaleInfo.token.address, this.$store.state.requiredNetwork)
        var remain = ethers.BigNumber.from(this.totalSupply).sub(this.presaleInfo.amount).sub(liquidity).sub(unicryptFee).sub(amountLocked).sub(burnt)

        this.TOKENOMICS.presale = this.presaleInfo.amount
        this.TOKENOMICS.liquidity = liquidity
        this.TOKENOMICS.fees = unicryptFee
        this.TOKENOMICS.locked = amountLocked.toString()
        this.TOKENOMICS.unlocked = remain.toString()

        // chart DATA
        var unsold = ethers.utils.formatUnits(remain, this.presaleInfo.token.decimals)

        var amount = ethers.utils.formatUnits(this.presaleInfo.amount, this.presaleInfo.token.decimals)
        unicryptFee = ethers.utils.formatUnits(unicryptFee, this.presaleInfo.token.decimals)
        liquidity = ethers.utils.formatUnits(liquidity, this.presaleInfo.token.decimals)
        amountLocked = ethers.utils.formatUnits(amountLocked, this.presaleInfo.token.decimals)
        burnt = ethers.utils.formatUnits(burnt, this.presaleInfo.token.decimals)

        var data = [amount, liquidity, unicryptFee, amountLocked, burnt, unsold]
        this.chartData.datasets[0].data = data
        this.$refs.donutChart1.update()

        // percent of total
        var percent = ethers.BigNumber.from(this.totalSupply).sub(remain).mul(100).div(this.totalSupply)
        this.tokenomicsScore = Math.min(percent.toNumber(), 100)
      } catch (e) {
        console.log(e)
        // incase on testnets and token vesting is not connected
        // this prevents errors in the browser console that make no sense
      }
    },
    async refresh () {
      this.getPresaleInfo()
      this.getUserInfo()
    },
    async doFirstLoad () {
      this.loadDBInfo()
      await this.getPresaleInfo()
      this.getPriceDiff()
      this.tokenHydrated = await ERC20.getERC20(this.presaleInfo.token.address, this.$store.state.requiredNetwork)
      this.getUserInfo()
        .then(() => {
          this.getTotalSupply()
        })
        .catch(e => {
          console.log(e)
        })
        .then(() => {
          this.firstLoad = false
        })
    }
  },

  created () {
    this.doFirstLoad()
  }
}
</script>