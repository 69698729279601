const Self = {
  FARMS: {
    'eth': {
      server: 'https://api-chain-eth.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'bsc': {
      server: 'https://api-chain-bsc.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'kovan': {
      server: 'https://api-chain-kovan.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'avax': {
      server: 'https://api-chain-avax.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'ganache': {
      // server: 'https://api-chain-kovan.unicrypt.network/api/v1'
      server: 'http://localhost/api/v1'
    }
  },
  
  TXNS: 'http://localhost/api/v1'
}

module.exports = Self