<template>
  <div class="px-4 py-3 c-list d-flex align-center">
    {{ item.country_name }}
    <v-spacer></v-spacer>
    <span class="textFaint--text font-italic caption">
      {{ item.continent_name }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {

      }
    }
  }
}
</script>