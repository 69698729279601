<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="550" content-class="br-20">
    <v-card class="br-20">
      <v-row class="pa-4 ma-0 font-weight-medium background align-center">
        Select Country
        <v-spacer></v-spacer>
        
        <v-btn @click="close" icon color="text">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <div v-if="true" class="pa-3 border-b">

        <div class="d-flex align-center pa-3 r-outlined br-20">
          <c-input :value.sync="searchCountry" placeholder="Search...">
          </c-input>

          <v-icon v-if="searchCountry === ''">
            mdi-magnify
          </v-icon>
          <v-icon v-else @click="searchCountry = ''">
            mdi-close
          </v-icon>

        </div>
      </div>

        <!-- Main content -->
        <v-card-text class="pa-0" style="height: 50vh;">

          <country-row v-for="item in filteredList" :key="item.address" :item="item" @click.native="selectCountry(item)">
          </country-row>

          <div v-if="filteredList.length === 0" class="pa-4 text-center">
            <v-icon size="100" color="textFaint">mdi-magnify</v-icon>
            <div class="textFaint--text">
              No countries match your search.
            </div>
          </div>

        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CountryRow from './country-row'

export default {
  components: {
    CountryRow
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    searchCountry: '',
    defaultList: {
      0: {
        id: 0,
        country_code: null,
        country_name: 'Other',
        continent_name: null
      },
      1: {
        id: 1,
        country_code: 'AF',
        country_name: 'Afghanistan',
        continent_name: 'Asia'
      },
      2: {
        id: 1,
        country_code: 'AX',
        country_name: 'Aland Islands',
        continent_name: 'Europe'
      },
      3: {
        id: 3,
        country_code: 'AL',
        country_name: 'Albania',
        continent_name: 'Europe'
      },
      4: {
        id: 4,
        country_code: 'DZ',
        country_name: 'Algeria',
        continent_name: 'Africa'
      },
      5: {
        id: 5,
        country_code: 'AS',
        country_name: 'American Samoa',
        continent_name: 'Oceania'
      },
      6: {
        id: 6,
        country_code: 'AD',
        country_name: 'Andorra',
        continent_name: 'Europe'
      },
      7: {
        id: 7,
        country_code: 'AO',
        country_name: 'Angola',
        continent_name: 'Africa'
      },
      8: {
        id: 8,
        country_code: 'AI',
        country_name: 'Anguilla',
        continent_name: 'North America'
      },
      9: {
        id: 9,
        country_code: 'AQ',
        country_name: 'Antarctica',
        continent_name: 'Antarctica'
      },
      10: {
        id: 10,
        country_code: 'AG',
        country_name: 'Antigua and Barbuda',
        continent_name: 'North America'
      },
      11: {
        id: 11,
        country_code: 'AR',
        country_name: 'Argentina',
        continent_name: 'South America'
      },
      12: {
        id: 12,
        country_code: 'AM',
        country_name: 'Armenia',
        continent_name: 'Asia'
      },
      13: {
        id: 13,
        country_code: 'AW',
        country_name: 'Aruba',
        continent_name: 'North America'
      },
      14: {
        id: 14,
        country_code: 'AU',
        country_name: 'Australia',
        continent_name: 'Oceania'
      },
      15: {
        id: 15,
        country_code: 'AT',
        country_name: 'Austria',
        continent_name: 'Europe'
      },
      16: {
        id: 16,
        country_code: 'AZ',
        country_name: 'Azerbaijan',
        continent_name: 'Asia'
      },
      17: {
        id: 17,
        country_code: 'BS',
        country_name: 'Bahamas',
        continent_name: 'North America'
      },
      18: {
        id: 18,
        country_code: 'BH',
        country_name: 'Bahrain',
        continent_name: 'Asia'
      },
      19: {
        id: 19,
        country_code: 'BD',
        country_name: 'Bangladesh',
        continent_name: 'Asia'
      },
      20: {
        id: 20,
        country_code: 'BB',
        country_name: 'Barbados',
        continent_name: 'North America'
      },
      21: {
        id: 21,
        country_code: 'BY',
        country_name: 'Belarus',
        continent_name: 'Europe'
      },
      22: {
        id: 22,
        country_code: 'BE',
        country_name: 'Belgium',
        continent_name: 'Europe'
      },
      23: {
        id: 23,
        country_code: 'BZ',
        country_name: 'Belize',
        continent_name: 'North America'
      },
      24: {
        id: 24,
        country_code: 'BJ',
        country_name: 'Benin',
        continent_name: 'Africa'
      },
      25: {
        id: 25,
        country_code: 'BM',
        country_name: 'Bermuda',
        continent_name: 'North America'
      },
      26: {
        id: 26,
        country_code: 'BT',
        country_name: 'Bhutan',
        continent_name: 'Asia'
      },
      27: {
        id: 27,
        country_code: 'BO',
        country_name: 'Bolivia',
        continent_name: 'South America'
      },
      28: {
        id: 28,
        country_code: 'BQ',
        country_name: 'Bonaire, Sint Eustatius and Saba',
        continent_name: 'North America'
      },
      29: {
        id: 29,
        country_code: 'BA',
        country_name: 'Bosnia and Herzegovina',
        continent_name: 'Europe'
      },
      30: {
        id: 30,
        country_code: 'BW',
        country_name: 'Botswana',
        continent_name: 'Africa'
      },
      31: {
        id: 31,
        country_code: 'BV',
        country_name: 'Bouvet Island',
        continent_name: 'Antarctica'
      },
      32: {
        id: 32,
        country_code: 'BR',
        country_name: 'Brazil',
        continent_name: 'South America'
      },
      33: {
        id: 33,
        country_code: 'IO',
        country_name: 'British Indian Ocean Territory',
        continent_name: 'Asia'
      },
      34: {
        id: 34,
        country_code: 'BN',
        country_name: 'Brunei Darussalam',
        continent_name: 'Asia'
      },
      35: {
        id: 35,
        country_code: 'BG',
        country_name: 'Bulgaria',
        continent_name: 'Europe'
      },
      36: {
        id: 36,
        country_code: 'BF',
        country_name: 'Burkina Faso',
        continent_name: 'Africa'
      },
      37: {
        id: 37,
        country_code: 'BI',
        country_name: 'Burundi',
        continent_name: 'Africa'
      },
      38: {
        id: 38,
        country_code: 'KH',
        country_name: 'Cambodia',
        continent_name: 'Asia'
      },
      39: {
        id: 39,
        country_code: 'CM',
        country_name: 'Cameroon',
        continent_name: 'Africa'
      },
      40: {
        id: 40,
        country_code: 'CA',
        country_name: 'Canada',
        continent_name: 'North America'
      },
      41: {
        id: 41,
        country_code: 'CV',
        country_name: 'Cape Verde',
        continent_name: 'Africa'
      },
      42: {
        id: 42,
        country_code: 'KY',
        country_name: 'Cayman Islands',
        continent_name: 'North America'
      },
      43: {
        id: 43,
        country_code: 'CF',
        country_name: 'Central African Republic',
        continent_name: 'Africa'
      },
      44: {
        id: 44,
        country_code: 'TD',
        country_name: 'Chad',
        continent_name: 'Africa'
      },
      45: {
        id: 45,
        country_code: 'CL',
        country_name: 'Chile',
        continent_name: 'South America'
      },
      46: {
        id: 46,
        country_code: 'CN',
        country_name: 'China',
        continent_name: 'Asia'
      },
      47: {
        id: 47,
        country_code: 'CX',
        country_name: 'Christmas Island',
        continent_name: 'Asia'
      },
      48: {
        id: 48,
        country_code: 'CC',
        country_name: 'Cocos (Keeling) Islands',
        continent_name: 'Asia'
      },
      49: {
        id: 49,
        country_code: 'CO',
        country_name: 'Columbia',
        continent_name: 'South America'
      },
      50: {
        id: 50,
        country_code: 'KM',
        country_name: 'Comoros',
        continent_name: 'Africa'
      },
      51: {
        id: 51,
        country_code: 'CG',
        country_name: 'Congo',
        continent_name: 'Africa'
      },
      52: {
        id: 52,
        country_code: 'CD',
        country_name: 'Congo, Democratic Republic of the Congo',
        continent_name: 'Africa'
      },
      53: {
        id: 53,
        country_code: 'CK',
        country_name: 'Cook Islands',
        continent_name: 'Oceania'
      },
      54: {
        id: 54,
        country_code: 'CR',
        country_name: 'Costa Rica',
        continent_name: 'North America'
      },
      55: {
        id: 55,
        country_code: 'CI',
        country_name: "Cote D'Ivoire",
        continent_name: 'Africa'
      },
      56: {
        id: 56,
        country_code: 'HR',
        country_name: "Croatia",
        continent_name: 'Europe'
      },
      57: {
        id: 57,
        country_code: 'CU',
        country_name: "Cuba",
        continent_name: 'North America'
      },
      58: {
        id: 58,
        country_code: 'CW',
        country_name: "Curacao",
        continent_name: 'North America'
      },
      59: {
        id: 59,
        country_code: 'CY',
        country_name: "Cyprus",
        continent_name: 'Asia'
      },
      60: {
        id: 60,
        country_code: 'CZ',
        country_name: "Czech Republic",
        continent_name: 'Europe'
      },
      61: {
        id: 61,
        country_code: 'DK',
        country_name: "Denmark",
        continent_name: 'Europe'
      },
      62: {
        id: 62,
        country_code: 'DJ',
        country_name: "Djibouti",
        continent_name: 'Africa'
      },
      63: {
        id: 63,
        country_code: 'DM',
        country_name: "Dominica",
        continent_name: 'North America'
      },
      64: {
        id: 64,
        country_code: 'DO',
        country_name: "Dominican Republic",
        continent_name: 'North America'
      },
      65: {
        id: 65,
        country_code: 'EC',
        country_name: "Ecuador",
        continent_name: 'South America'
      },
      66: {
        id: 66,
        country_code: 'EG',
        country_name: "Egypt",
        continent_name: 'Africa'
      },
      67: {
        id: 67,
        country_code: 'SV',
        country_name: "El Salvador",
        continent_name: 'North America'
      },
      68: {
        id: 68,
        country_code: 'GQ',
        country_name: "Equatorial Guinea",
        continent_name: 'Africa'
      },
      69: {
        id: 69,
        country_code: 'ER',
        country_name: "Eritrea",
        continent_name: 'Africa'
      },
      70: {
        id: 70,
        country_code: 'EE',
        country_name: "Estonia",
        continent_name: 'Europe'
      },
      71: {
        id: 71,
        country_code: 'ET',
        country_name: "Ethiopia",
        continent_name: 'Africa'
      },
      72: {
        id: 72,
        country_code: 'FK',
        country_name: "Falkland Islands (Malvinas)",
        continent_name: 'South America'
      },
      73: {
        id: 73,
        country_code: 'FO',
        country_name: "Faroe Islands",
        continent_name: 'Europe'
      },
      74: {
        id: 74,
        country_code: 'FJ',
        country_name: "Fiji",
        continent_name: 'Oceania'
      },
      75: {
        id: 75,
        country_code: 'FI',
        country_name: "Finland",
        continent_name: 'Europe'
      },
      76: {
        id: 76,
        country_code: 'FR',
        country_name: "France",
        continent_name: 'Europe'
      },
      77: {
        id: 77,
        country_code: 'GF',
        country_name: "French Guiana",
        continent_name: 'South America'
      },
      78: {
        id: 78,
        country_code: 'PF',
        country_name: "French Polynesia",
        continent_name: 'Oceania'
      },
      79: {
        id: 79,
        country_code: 'TF',
        country_name: "French Southern Territories",
        continent_name: 'Antarctica'
      },
      80: {
        id: 80,
        country_code: 'GA',
        country_name: "Gabon",
        continent_name: 'Africa'
      },
      81: {
        id: 81,
        country_code: 'GM',
        country_name: "Gambia",
        continent_name: 'Africa'
      },
      82: {
        id: 82,
        country_code: 'GE',
        country_name: "Georgia",
        continent_name: 'Asia'
      },
      83: {
        id: 83,
        country_code: 'DE',
        country_name: "Germany",
        continent_name: 'Europe'
      },
      84: {
        id: 84,
        country_code: 'GH',
        country_name: "Ghana",
        continent_name: 'Africa'
      },
      85: {
        id: 85,
        country_code: 'GI',
        country_name: "Gibraltar",
        continent_name: 'Europe'
      },
      86: {
        id: 86,
        country_code: 'GR',
        country_name: "Greece",
        continent_name: 'Europe'
      },
      87: {
        id: 87,
        country_code: 'GL',
        country_name: "Greenland",
        continent_name: 'North America'
      },
      88: {
        id: 88,
        country_code: 'GD',
        country_name: "Grenada",
        continent_name: 'North America'
      },
      89: {
        id: 89,
        country_code: 'GP',
        country_name: "Guadeloupe",
        continent_name: 'North America'
      },
      90: {
        id: 90,
        country_code: 'GU',
        country_name: "Guam",
        continent_name: 'Oceania'
      },
      91: {
        id: 91,
        country_code: 'GT',
        country_name: "Guatemala",
        continent_name: 'North America'
      },
      92: {
        id: 92,
        country_code: 'GG',
        country_name: "Guernsey",
        continent_name: 'Europe'
      },
      93: {
        id: 93,
        country_code: 'GN',
        country_name: "Guinea",
        continent_name: 'Africa'
      },
      94: {
        id: 94,
        country_code: 'GW',
        country_name: "Guinea-Bissau",
        continent_name: 'Africa'
      },
      95: {
        id: 95,
        country_code: 'GY',
        country_name: "Guyana",
        continent_name: 'South America'
      },
      96: {
        id: 96,
        country_code: 'HT',
        country_name: "Haiti",
        continent_name: 'North America'
      },
      97: {
        id: 97,
        country_code: 'HM',
        country_name: "Heard Island and Mcdonald Islands",
        continent_name: 'Antarctica'
      },
      98: {
        id: 98,
        country_code: 'VA',
        country_name: "Holy See (Vatican City State)",
        continent_name: 'Europe'
      },
      99: {
        id: 99,
        country_code: 'HN',
        country_name: "Honduras",
        continent_name: 'North America'
      },
      100: {
        id: 100,
        country_code: 'HK',
        country_name: "Hong Kong",
        continent_name: 'Asia'
      },
      101: {
        id: 101,
        country_code: 'HU',
        country_name: "Hungary",
        continent_name: 'Europe'
      },
      102: {
        id: 102,
        country_code: 'IS',
        country_name: "Iceland",
        continent_name: 'Europe'
      },
      103: {
        id: 103,
        country_code: 'IN',
        country_name: "India",
        continent_name: 'Asia'
      },
      104: {
        id: 104,
        country_code: 'ID',
        country_name: "Indonesia",
        continent_name: 'Asia'
      },
      105: {
        id: 105,
        country_code: 'IR',
        country_name: "Iran, Islamic Republic of",
        continent_name: 'Asia'
      },
      106: {
        id: 106,
        country_code: 'IQ',
        country_name: "Iraq",
        continent_name: 'Asia'
      },
      107: {
        id: 107,
        country_code: 'IE',
        country_name: "Ireland",
        continent_name: 'Europe'
      },
      108: {
        id: 108,
        country_code: 'IM',
        country_name: "Isle of Man",
        continent_name: 'Europe'
      },
      109: {
        id: 109,
        country_code: 'IL',
        country_name: "Israel",
        continent_name: 'Asia'
      },
      110: {
        id: 110,
        country_code: 'IT',
        country_name: "Italy",
        continent_name: 'Europe'
      },
      111: {
        id: 111,
        country_code: 'JM',
        country_name: "Jamaica",
        continent_name: 'North America'
      },
      112: {
        id: 112,
        country_code: 'JP',
        country_name: "Japan",
        continent_name: 'Asia'
      },
      113: {
        id: 113,
        country_code: 'JE',
        country_name: "Jersey",
        continent_name: 'Europe'
      },
      114: {
        id: 114,
        country_code: 'JO',
        country_name: "Jordan",
        continent_name: 'Asia'
      },
      115: {
        id: 115,
        country_code: 'KZ',
        country_name: "Kazakhstan",
        continent_name: 'Asia'
      },
      116: {
        id: 116,
        country_code: 'KE',
        country_name: "Kenya",
        continent_name: 'Africa'
      },
      117: {
        id: 117,
        country_code: 'KI',
        country_name: "Kiribati",
        continent_name: 'Oceania'
      },
      118: {
        id: 118,
        country_code: 'KP',
        country_name: "Korea, Democratic People's Republic of",
        continent_name: 'Asia'
      },
      119: {
        id: 119,
        country_code: 'KR',
        country_name: "Korea, Republic of",
        continent_name: 'Asia'
      },
      120: {
        id: 120,
        country_code: 'XK',
        country_name: "Kosovo",
        continent_name: 'Europe'
      },
      121: {
        id: 121,
        country_code: 'KW',
        country_name: "Kuwait",
        continent_name: 'Asia'
      },
      122: {
        id: 122,
        country_code: 'KG',
        country_name: "Kyrgyzstan",
        continent_name: 'Asia'
      },
      123: {
        id: 123,
        country_code: 'LA',
        country_name: "Lao People's Democratic Republic",
        continent_name: 'Asia'
      },
      124: {
        id: 124,
        country_code: 'LV',
        country_name: "Latvia",
        continent_name: 'Europe'
      },
      125: {
        id: 125,
        country_code: 'LB',
        country_name: "Lebanon",
        continent_name: 'Asia'
      },
      126: {
        id: 126,
        country_code: 'LS',
        country_name: "Lesotho",
        continent_name: 'Africa'
      },
      127: {
        id: 127,
        country_code: 'LR',
        country_name: "Liberia",
        continent_name: 'Africa'
      },
      128: {
        id: 128,
        country_code: 'LY',
        country_name: "Libyan Arab Jamahiriya",
        continent_name: 'Africa'
      },
      129: {
        id: 129,
        country_code: 'LI',
        country_name: "Liechtenstein",
        continent_name: 'Europe'
      },
      130: {
        id: 130,
        country_code: 'LT',
        country_name: "Lithuania",
        continent_name: 'Europe'
      },
      131: {
        id: 131,
        country_code: 'LU',
        country_name: "Luxembourg",
        continent_name: 'Europe'
      },
      132: {
        id: 132,
        country_code: 'MO',
        country_name: "Macao",
        continent_name: 'Asia'
      },
      133: {
        id: 133,
        country_code: 'MK',
        country_name: "Macedonia, the Former Yugoslav Republic of",
        continent_name: 'Europe'
      },
      134: {
        id: 134,
        country_code: 'MG',
        country_name: "Madagascar",
        continent_name: 'Africa'
      },
      135: {
        id: 135,
        country_code: 'MW',
        country_name: "Malawi",
        continent_name: 'Africa'
      },
      136: {
        id: 136,
        country_code: 'MY',
        country_name: "Malaysia",
        continent_name: 'Asia'
      },
      137: {
        id: 137,
        country_code: 'MV',
        country_name: "Maldives",
        continent_name: 'Asia'
      },
      138: {
        id: 138,
        country_code: 'ML',
        country_name: "Mali",
        continent_name: 'Africa'
      },
      139: {
        id: 139,
        country_code: 'MT',
        country_name: "Malta",
        continent_name: 'Europe'
      },
      140: {
        id: 140,
        country_code: 'MH',
        country_name: "Marshall Islands",
        continent_name: 'Oceania'
      },
      141: {
        id: 141,
        country_code: 'MQ',
        country_name: "Martinique",
        continent_name: 'North America'
      },
      142: {
        id: 142,
        country_code: 'MR',
        country_name: "Mauritania",
        continent_name: 'Africa'
      },
      143: {
        id: 143,
        country_code: 'MU',
        country_name: "Mauritius",
        continent_name: 'Africa'
      },
      144: {
        id: 144,
        country_code: 'YT',
        country_name: "Mayotte",
        continent_name: 'Africa'
      },
      145: {
        id: 145,
        country_code: 'MX',
        country_name: "Mexico",
        continent_name: 'North America'
      },
      146: {
        id: 146,
        country_code: 'FM',
        country_name: "Micronesia, Federated States of",
        continent_name: 'Oceania'
      },
      147: {
        id: 147,
        country_code: 'MD',
        country_name: "Moldova, Republic of",
        continent_name: 'Europe'
      },
      148: {
        id: 148,
        country_code: 'MC',
        country_name: "Monaco",
        continent_name: 'Europe'
      },
      149: {
        id: 149,
        country_code: 'MN',
        country_name: "Mongolia",
        continent_name: 'Asia'
      },
      150: {
        id: 150,
        country_code: 'ME',
        country_name: "Montenegro",
        continent_name: 'Europe'
      },
      151: {
        id: 151,
        country_code: 'MS',
        country_name: "Montserrat",
        continent_name: 'North America'
      },
      152: {
        id: 152,
        country_code: 'MA',
        country_name: "Morocco",
        continent_name: 'Africa'
      },
      153: {
        id: 153,
        country_code: 'MZ',
        country_name: "Mozambique",
        continent_name: 'Africa'
      },
      154: {
        id: 154,
        country_code: 'MM',
        country_name: "Myanmar",
        continent_name: 'Asia'
      },
      155: {
        id: 155,
        country_code: 'NA',
        country_name: "Namibia",
        continent_name: 'Africa'
      },
      156: {
        id: 156,
        country_code: 'NR',
        country_name: "Nauru",
        continent_name: 'Oceania'
      },
      157: {
        id: 157,
        country_code: 'NP',
        country_name: "Nepal",
        continent_name: 'Asia'
      },
      158: {
        id: 158,
        country_code: 'NL',
        country_name: "Netherlands",
        continent_name: 'Europe'
      },
      159: {
        id: 159,
        country_code: 'AN',
        country_name: "Netherlands Antilles",
        continent_name: 'North America'
      },
      160: {
        id: 160,
        country_code: 'NC',
        country_name: "New Caledonia",
        continent_name: 'Oceania'
      },
      161: {
        id: 161,
        country_code: 'NZ',
        country_name: "New Zealand",
        continent_name: 'Oceania'
      },
      162: {
        id: 162,
        country_code: 'NI',
        country_name: "Nicaragua",
        continent_name: 'North America'
      },
      163: {
        id: 163,
        country_code: 'NE',
        country_name: "Niger",
        continent_name: 'Africa'
      },
      164: {
        id: 164,
        country_code: 'NG',
        country_name: "Nigeria",
        continent_name: 'Africa'
      },
      165: {
        id: 165,
        country_code: 'NU',
        country_name: "Niue",
        continent_name: 'Oceania'
      },
      166: {
        id: 166,
        country_code: 'NF',
        country_name: "Norfolk Island",
        continent_name: 'Oceania'
      },
      167: {
        id: 167,
        country_code: 'MP',
        country_name: "Northern Mariana Islands",
        continent_name: 'Oceania'
      },
      168: {
        id: 168,
        country_code: 'NO',
        country_name: "Norway",
        continent_name: 'Europe'
      },
      169: {
        id: 169,
        country_code: 'OM',
        country_name: "Oman",
        continent_name: 'Asia'
      },
      170: {
        id: 170,
        country_code: 'PK',
        country_name: "Pakistan",
        continent_name: 'Asia'
      },
      171: {
        id: 171,
        country_code: 'PW',
        country_name: "Palau",
        continent_name: 'Oceania'
      },
      172: {
        id: 172,
        country_code: 'PS',
        country_name: "Palestinian Territory, Occupied",
        continent_name: 'Asia'
      },
      173: {
        id: 173,
        country_code: 'PA',
        country_name: "Panama",
        continent_name: 'North America'
      },
      174: {
        id: 174,
        country_code: 'PG',
        country_name: "Papua New Guinea",
        continent_name: 'Oceania'
      },
      175: {
        id: 175,
        country_code: 'PY',
        country_name: "Paraguay",
        continent_name: 'South America'
      },
      176: {
        id: 176,
        country_code: 'PE',
        country_name: "Peru",
        continent_name: 'South America'
      },
      177: {
        id: 177,
        country_code: 'PH',
        country_name: "Philippines",
        continent_name: 'Asia'
      },
      178: {
        id: 178,
        country_code: 'PN',
        country_name: "Pitcairn",
        continent_name: 'Oceania'
      },
      179: {
        id: 179,
        country_code: 'PL',
        country_name: "Poland",
        continent_name: 'Europe'
      },
      180: {
        id: 180,
        country_code: 'PT',
        country_name: "Portugal",
        continent_name: 'Europe'
      },
      181: {
        id: 181,
        country_code: 'PR',
        country_name: "Puerto Rico",
        continent_name: 'North America'
      },
      182: {
        id: 182,
        country_code: 'QA',
        country_name: "Qatar",
        continent_name: 'Asia'
      },
      183: {
        id: 183,
        country_code: 'RE',
        country_name: "Reunion",
        continent_name: 'Africa'
      },
      184: {
        id: 184,
        country_code: 'RO',
        country_name: "Romania",
        continent_name: 'Europe'
      },
      185: {
        id: 185,
        country_code: 'RU',
        country_name: "Russian Federation",
        continent_name: 'Asia'
      },
      186: {
        id: 186,
        country_code: 'RW',
        country_name: "Rwanda",
        continent_name: 'Africa'
      },
      187: {
        id: 187,
        country_code: 'BL',
        country_name: "Saint Barthelemy",
        continent_name: 'North America'
      },
      188: {
        id: 188,
        country_code: 'SH',
        country_name: "Saint Helena",
        continent_name: 'Africa'
      },
      189: {
        id: 189,
        country_code: 'KN',
        country_name: "Saint Kitts and Nevis",
        continent_name: 'North America'
      },
      190: {
        id: 190,
        country_code: 'LC',
        country_name: "Saint Lucia",
        continent_name: 'North America'
      },
      191: {
        id: 191,
        country_code: 'MF',
        country_name: "Saint Martin",
        continent_name: 'North America'
      },
      192: {
        id: 192,
        country_code: 'PM',
        country_name: "Saint Pierre and Miquelon",
        continent_name: 'North America'
      },
      193: {
        id: 193,
        country_code: 'VC',
        country_name: "Saint Vincent and the Grenadines",
        continent_name: 'North America'
      },
      194: {
        id: 194,
        country_code: 'WS',
        country_name: "Samoa",
        continent_name: 'Oceania'
      },
      195: {
        id: 195,
        country_code: 'SM',
        country_name: "San Marino",
        continent_name: 'Europe'
      },
      196: {
        id: 196,
        country_code: 'ST',
        country_name: "Sao Tome and Principe",
        continent_name: 'Africa'
      },
      197: {
        id: 197,
        country_code: 'SA',
        country_name: "Saudi Arabia",
        continent_name: 'Asia'
      },
      198: {
        id: 198,
        country_code: 'SN',
        country_name: "Senegal",
        continent_name: 'Africa'
      },
      199: {
        id: 199,
        country_code: 'RS',
        country_name: "Serbia",
        continent_name: 'Europe'
      },
      200: {
        id: 200,
        country_code: 'CS',
        country_name: "Serbia and Montenegro",
        continent_name: 'Europe'
      },
      201: {
        id: 201,
        country_code: 'SC',
        country_name: "Seychelles",
        continent_name: 'Africa'
      },
      202: {
        id: 202,
        country_code: 'SL',
        country_name: "Sierra Leone",
        continent_name: 'Africa'
      },
      203: {
        id: 203,
        country_code: 'SG',
        country_name: "Singapore",
        continent_name: 'Asia'
      },
      204: {
        id: 204,
        country_code: 'SX',
        country_name: "Sint Maarten",
        continent_name: 'North America'
      },
      205: {
        id: 205,
        country_code: 'SK',
        country_name: "Slovakia",
        continent_name: 'Europe'
      },
      206: {
        id: 206,
        country_code: 'SI',
        country_name: "Slovenia",
        continent_name: 'Europe'
      },
      207: {
        id: 207,
        country_code: 'SB',
        country_name: "Solomon Islands",
        continent_name: 'Oceania'
      },
      208: {
        id: 208,
        country_code: 'SO',
        country_name: "Somalia",
        continent_name: 'Africa'
      },
      209: {
        id: 209,
        country_code: 'ZA',
        country_name: "South Africa",
        continent_name: 'Africa'
      },
      210: {
        id: 210,
        country_code: 'GS',
        country_name: "South Georgia and the South Sandwich Islands",
        continent_name: 'Antarctica'
      },
      211: {
        id: 211,
        country_code: 'SS',
        country_name: "South Sudan",
        continent_name: 'Africa'
      },
      212: {
        id: 212,
        country_code: 'ES',
        country_name: "Spain",
        continent_name: 'Europe'
      },
      213: {
        id: 213,
        country_code: 'LK',
        country_name: "Sri Lanka",
        continent_name: 'Asia'
      },
      214: {
        id: 214,
        country_code: 'SD',
        country_name: "Sudan",
        continent_name: 'Africa'
      },
      215: {
        id: 215,
        country_code: 'SR',
        country_name: "Suriname",
        continent_name: 'South America'
      },
      216: {
        id: 216,
        country_code: 'SJ',
        country_name: "Svalbard and Jan Mayen",
        continent_name: 'Europe'
      },
      217: {
        id: 217,
        country_code: 'SZ',
        country_name: "Swaziland",
        continent_name: 'Africa'
      },
      218: {
        id: 218,
        country_code: 'SE',
        country_name: "Sweden",
        continent_name: 'Europe'
      },
      219: {
        id: 219,
        country_code: 'CH',
        country_name: "Switzerland",
        continent_name: 'Europe'
      },
      220: {
        id: 220,
        country_code: 'SY',
        country_name: "Syrian Arab Republic",
        continent_name: 'Asia'
      },
      221: {
        id: 221,
        country_code: 'TW',
        country_name: "Taiwan",
        continent_name: 'Asia'
      },
      222: {
        id: 222,
        country_code: 'TJ',
        country_name: "Tajikistan",
        continent_name: 'Asia'
      },
      223: {
        id: 223,
        country_code: 'TZ',
        country_name: "Tanzania",
        continent_name: 'Africa'
      },
      224: {
        id: 224,
        country_code: 'TH',
        country_name: "Thailand",
        continent_name: 'Asia'
      },
      225: {
        id: 225,
        country_code: 'TL',
        country_name: "Timor-Leste",
        continent_name: 'Asia'
      },
      226: {
        id: 226,
        country_code: 'TG',
        country_name: "Togo",
        continent_name: 'Africa'
      },
      227: {
        id: 227,
        country_code: 'TK',
        country_name: "Tokelau",
        continent_name: 'Oceania'
      },
      228: {
        id: 228,
        country_code: 'TO',
        country_name: "Tonga",
        continent_name: 'Oceania'
      },
      229: {
        id: 229,
        country_code: 'TT',
        country_name: "Trinidad and Tobago",
        continent_name: 'North America'
      },
      230: {
        id: 230,
        country_code: 'TN',
        country_name: "Tunisia",
        continent_name: 'Africa'
      },
      231: {
        id: 231,
        country_code: 'TR',
        country_name: "Turkey",
        continent_name: 'Asia'
      },
      232: {
        id: 232,
        country_code: 'TM',
        country_name: "Turkmenistan",
        continent_name: 'Asia'
      },
      233: {
        id: 233,
        country_code: 'TC',
        country_name: "Turks and Caicos Islands",
        continent_name: 'North America'
      },
      234: {
        id: 234,
        country_code: 'TV',
        country_name: "Tuvalu",
        continent_name: 'Oceania'
      },
      235: {
        id: 235,
        country_code: 'UG',
        country_name: "Uganda",
        continent_name: 'Africa'
      },
      236: {
        id: 236,
        country_code: 'UA',
        country_name: "Ukraine",
        continent_name: 'Europe'
      },
      237: {
        id: 237,
        country_code: 'AE',
        country_name: "United Arab Emirates",
        continent_name: 'Asia'
      },
      238: {
        id: 238,
        country_code: 'GB',
        country_name: "United Kingdom",
        continent_name: 'Europe'
      },
      239: {
        id: 239,
        country_code: 'US',
        country_name: "United States",
        continent_name: 'North America'
      },
      240: {
        id: 240,
        country_code: 'UM',
        country_name: "United States Minor Outlying Islands",
        continent_name: 'North America'
      },
      241: {
        id: 241,
        country_code: 'UY',
        country_name: "Uruguay",
        continent_name: 'South America'
      },
      242: {
        id: 242,
        country_code: 'UZ',
        country_name: "Uzbekistan",
        continent_name: 'Asia'
      },
      243: {
        id: 243,
        country_code: 'VU',
        country_name: "Vanuatu",
        continent_name: 'Oceania'
      },
      244: {
        id: 244,
        country_code: 'VE',
        country_name: "Venezuela",
        continent_name: 'South America'
      },
      245: {
        id: 245,
        country_code: 'VN',
        country_name: "Vietnam",
        continent_name: 'Asia'
      },
      246: {
        id: 246,
        country_code: 'VG',
        country_name: "Virgin Islands, British",
        continent_name: 'North America'
      },
      247: {
        id: 247,
        country_code: 'VI',
        country_name: "Virgin Islands, U.S.",
        continent_name: 'North America'
      },
      248: {
        id: 248,
        country_code: 'WF',
        country_name: "Wallis and Futuna",
        continent_name: 'Oceania'
      },
      249: {
        id: 249,
        country_code: 'EH',
        country_name: "Western Sahara",
        continent_name: 'Africa'
      },
      250: {
        id: 250,
        country_code: 'YE',
        country_name: "Yemen",
        continent_name: 'Asia'
      },
      251: {
        id: 251,
        country_code: 'ZM',
        country_name: "Zambia",
        continent_name: 'Africa'
      },
      252: {
        id: 252,
        country_code: 'ZW',
        country_name: "Zimbabwe",
        continent_name: 'Africa'
      },
    }
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    countryList () {
      return Object.values(this.defaultList)
    },
    filteredList () {
      var searchString = this.searchCountry.toLowerCase()
      return this.countryList.filter(item => item.country_name.toLowerCase().includes(searchString))
    }
  },

  watch: {
    searchCountry (nv) {
    }
  },

  methods: {
    open () {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    selectCountry (item) {
      this.resolve(item)
      this.dialog = false
    },
    close () {
      this.dialog = false
      this.reject()
    }
  },

  created () {
  }
}
</script>