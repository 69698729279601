<template>
  <div>
    <div class="d-flex border-b">
      <v-btn text class="br-0 border-r flex c-list textFaint--text font-weight-regular" color="" v-if="$store.state.user.username && $store.state.user.permissions.auditor" @click="toggleAuditCard">
        Submit Audit
        <v-icon small :class="[{'rotate-180': showAuditCard}]">mdi-chevron-down</v-icon>
      </v-btn>
      <v-btn text class="br-0 flex c-list textFaint--text font-weight-regular" color="" v-if="$store.state.user.username && $store.state.user.permissions.kyc_verifier" @click="toggleKYCCard">
        Submit KYC
        <v-icon small :class="[{'rotate-180': showKYCCard}]">mdi-chevron-down</v-icon>
      </v-btn>
    </div>
    <template>
      <v-expand-transition>
        <div v-show="showAuditCard" class="pa-2 midground">
          <audit-card :address="address" @on-update="onAuditUpdate"></audit-card>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-show="showKYCCard" class="pa-2 midground">
          <kyc-card :address="address" @on-update="onKYCUpdate"></kyc-card>
        </div>
      </v-expand-transition>
    </template>
    <audit-overview :address="address" ref="auditOverview"></audit-overview>
    <kyc-overview :address="address" ref="kycOverview"></kyc-overview>
  </div>
</template>

<script>
import AuditCard from './audit-card'
import KycCard from './kyc-card'
import AuditOverview from './audit-overview'
import KycOverview from './kyc-overview'

export default {
  components: {
    AuditCard,
    AuditOverview,
    KycCard,
    KycOverview,
  },
  props: {
    address: {
      type: String
    }
  },

  data: () => ({
    showAuditCard: false,
    showKYCCard: false,
  }),

  methods: {
    toggleAuditCard () {
      this.showAuditCard = !this.showAuditCard
      this.showKYCCard = false
    },
    toggleKYCCard () {
      this.showKYCCard = !this.showKYCCard
      this.showAuditCard = false
    },
    onAuditUpdate () {
      this.$refs.auditOverview.normalLoad()
      this.$emit('on-update')
    },
    onKYCUpdate () {
      this.$refs.kycOverview.normalLoad()
      this.$emit('on-update')
    },
  },

  created () {
  }
}
</script>
