<template>
  <div class="v-card br-20">

    <div class="pa-6">

      <div>
        <v-btn @click="$root.chainLink(`/farms`)" icon x-large>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>

      <div class="display-1 text-uppercase text-center font-weight-medium mb-4">
        Create Stake Pool (Oracle)
      </div>
    </div>

    <div v-if="!sEthers.coinbase" class="text-center pa-6">
      <div>
        <v-icon size="40" color="navcolor">mdi-account-circle</v-icon>
        <div class="textFaint--text pt-2">
          Connect your wallet to create a Stake Pool
        </div>
      </div>
      <div class="pt-4">
        <v-btn
        large
        block
        color="navcolor"
        class="white--text"
        rounded
        @click="connectWallet"
        >
          CONNECT
        </v-btn>
      </div>
    </div>

    <template v-else>
      <div class="px-4 py-6">
        <!-- COUNTRY SELECT -->
        <div class="font-weight-medium text--text">
          Select your country
        </div>
        <div class="textFaint--text pb-4">
          Where is your business headquartered?
        </div>
        <div>
          <div @click="openSelectCountryDialog"
          class="inputcolor pa-4 br-8 d-flex align-center c-list font-weight-bold">
            <span v-if="selectedCountry.id === -1" class="red--text">
              Select your country
            </span>
            <div v-else>
              {{ selectedCountry.country_name }}
            </div>
            <v-spacer></v-spacer>
            <template v-if="selectedCountry.id === -1">
              <v-icon color="red">mdi-alert-outline</v-icon>
            </template>
            <v-icon>mdi-chevron-down</v-icon>
          </div>
        </div>
        <!-- END COUNTRY SELECT -->

        <div class="mt-4 font-weight-medium">
          Decimals
        </div>
        <div class="d-flex mt-2 br-8 align-center inputcolor pa-2">
          <c-input :value.sync="decimals" placeholder="18" class="pa-2 font-weight-bold">
          </c-input>
        </div>

      </div>

      <template v-if ="chargeFee">
        <div class="mt-4 mx-4 pa-4 br-8 midground">
          <div class="caption textFaint--text">
            STAKE POOL CREATION
          </div>
          <div class="d-flex font-weight-bold">
            Fee
            <v-spacer></v-spacer>
            {{ ethCreationFeeHuman }} {{ $store.state.nativeGasTokenSymbol }}
          </div>
        </div>
        <div class="mx-4 mt-2 mb-4 pa-4 br-8 midground">
          <div class="caption textFaint--text">
            REWARD POOL CREATION (Charged later when creating reward pools)
          </div>
          <div class="d-flex textFaint--text font-weight-bold">
            Fee
            <v-spacer></v-spacer>
            {{ stake_fees.reward_fee / 10 }}%
          </div>
        </div>
      </template>
      <div v-else class="ma-4 pa-4 br-8 midground d-flex font-weight-bold">
        <img 
        v-if="true"
        src="@/assets/img/UNCX_fill.svg"
        height="40px"
        width="40px"
        class="mr-3">
        <div>
          <div class="caption textFaint--text">
            UNICRYPT PREMIUM USER
          </div>
          <div class="primary--text">
            No Fees!
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-icon color="textFaint" size="40">mdi-emoticon-excited-outline</v-icon>
      </div>

      <v-btn @click="createStakePool" block x-large rounded color="primary" :loading="createLoading">
        Create Stake Pool
      </v-btn>
    </template>

    <!-- DIALOGS -->
    <country-list-dialog ref="countryListDialog"></country-list-dialog>
    <!-- DIALOGS -->

  </div>
</template>

<script>
import { ethers } from 'ethers'
import StakePoolGeneratorContract from '@/smart-contracts/farms-v2/oracle/stake-pool/v2/stake-pool-generator'
import StakeSettingsContract from '@/smart-contracts/farms-v2/oracle/stake-pool/v2/stake-settings'
import StakeFactoryContract from '@/smart-contracts/farms-v2/oracle/stake-factory'
import TxWrapper from '@/web3/tx-wrapper-2';
import CountryListDialog from '@/components/dialogs/country-select/csdialog'

export default {

  components: {
    CountryListDialog
  },

  data: () => ({
    stake_fees: {
      eth_creation_fee: '0'
    },

    decimals: 18,

    selectedCountry: {
      id: -1,
      country_code: null,
      country_name: 'No selection',
      continent_name: null
    },

    chargeFee: true,

    createLoading: false,
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    ethCreationFeeHuman () {
      var amount = ethers.utils.formatUnits(this.stake_fees.eth_creation_fee, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3})
    },
  },

  watch: {
    sEthersWatcher () {
      this.checkEcosystemFee()
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    async checkEcosystemFee () {
      // this.chargeFee = true
      // var chargeFee = await EcosystemWhitelistContract.chargeFee(this.tokenHydrated.address, this.sEthers.coinbase)
      // this.chargeFee = chargeFee
    },
    async getStakePoolFees () {
      this.stake_fees = await StakeSettingsContract.getFees()
    },
    openSelectCountryDialog () {
      this.$refs.countryListDialog.open()
        .then(country => {
          this.selectedCountry = country
        })
    },
    createStakePool () {
      this.createLoading = true
      var userAddress = this.sEthers.coinbase
      var flatFee = this.stake_fees.eth_creation_fee
      if (!this.chargeFee) {
        flatFee = '0'
      }
      TxWrapper.doTransaction(StakePoolGeneratorContract.createStakePool,
        [
          this.selectedCountry.id,
          this.decimals,
          flatFee
        ], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(response => {
          this.redirectToLatestPool(userAddress)
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.createLoading = false
        })
    },
    async redirectToLatestPool (userAddress) {
      var index = await StakeFactoryContract.getNumPoolsForAdmin(userAddress)
      var address = await StakeFactoryContract.getStakePoolForAdminAtIndex(userAddress, index - 1)
      this.$root.chainLink(`/farm/${address}`)
    }
  },

  created () {
    this.getStakePoolFees()
  }
}
</script>