<template>
  <div>
    <div class="v-card pa-2 br-20">

      <div class="text-center mb-3">
        Admin functions
      </div>

      <v-bottom-navigation
        color="primary"
        grow
        class="align-center transparent br-c"
        style="box-shadow: none;"
        :value="tab"
      >

        <v-btn @click="tab = 0">
          <span class="font-weight-medium">Vesting</span>
          <v-icon>mdi-clock-outline</v-icon>
        </v-btn>

        <v-btn @click="tab = 1">
          <span class="font-weight-medium">UNCL allocations</span>
          <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
        </v-btn>

        <v-btn @click="tab = 2">
          <span class="font-weight-medium">Fail</span>
          <v-icon>mdi-close-outline</v-icon>
        </v-btn>

        <v-btn @click="tab = 3">
          <span class="font-weight-medium">Referrer</span>
          <v-icon>mdi-account-outline</v-icon>
        </v-btn>

        <v-btn @click="tab = 4">
          <span class="font-weight-medium">Presale Info</span>
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>

      </v-bottom-navigation>

      <div v-if="tab === 0" class="background pa-5 text-center my-2">
        <v-icon size="60">mdi-lock-outline</v-icon>
        <div>
          TokenVesting
        </div>
        <div>
          Enabled: 
          <v-btn outlined :color="token_vesting.implement_vesting ? 'primary' : 'textFaint'" @click="token_vesting.implement_vesting = !token_vesting.implement_vesting">
            {{ token_vesting.implement_vesting ? 'Implemented' : 'Disabled' }}
          </v-btn>
        </div>
        <div>
          Lock Type:
          <v-btn outlined :color="token_vesting.implement_vesting ? 'primary' : 'textFaint'" @click="token_vesting.linear_lock = !token_vesting.linear_lock">
            {{ token_vesting.linear_lock ? 'Linear Lock' : 'Normal Lock' }}
          </v-btn>
        </div>
        <div>
          Start
          <span class="textFaint--text caption">
            (<span class="primary--text">{{ this.presale_info.vesting_start_emission }}</span> seconds currently set)
          </span>
        </div>
        <div class="d-flex align-center border br-20">
          <c-input :value.sync="token_vesting.start_emission" placeholder="start emission" class="pa-2 font-weight-bold">
          </c-input>

          <v-menu
          offset-y
          >
            <template v-slot:activator="{ attrs, on }">
              <div class="d-flex align-center inputcolor pa-4 r-outlined"
                v-bind="attrs"
                v-on="on"
              >
                {{ lockPeriodStart }}
                <v-spacer></v-spacer>
                <v-icon small>mdi-chevron-down</v-icon>
              </div>
            </template>

            <v-list style="min-width: 200px;" class="background">
              <v-list-item @click="lockPeriodStart = 'days'">
                <v-list-item-title>
                  days
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="lockPeriodStart = 'months'">
                <v-list-item-title>
                  months
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div>
          End
          <span class="textFaint--text caption">
            (<span class="primary--text">{{ this.presale_info.vesting_end_emission }}</span> seconds currently set)
          </span>
        </div>
        <div class="d-flex align-center border br-20">
          <c-input :value.sync="token_vesting.end_emission" placeholder="end emission" class="pa-2 font-weight-bold">
          </c-input>

          <v-menu
          offset-y
          >
            <template v-slot:activator="{ attrs, on }">
              <div class="d-flex align-center inputcolor pa-4 r-outlined"
                v-bind="attrs"
                v-on="on"
              >
                {{ lockPeriodEnd }}
                <v-spacer></v-spacer>
                <v-icon small>mdi-chevron-down</v-icon>
              </div>
            </template>

            <v-list style="min-width: 200px;" class="background">
              <v-list-item @click="lockPeriodEnd = 'days'">
                <v-list-item-title>
                  days
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="lockPeriodEnd = 'months'">
                <v-list-item-title>
                  months
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </div>
        <div class="py-2">
          Percentage: (Must be whole number without decimals between 0-100)
        </div>
        <div class="d-flex align-center border br-20 pr-4 mb-2">
          <c-input :value.sync="token_vesting.percentage" placeholder="percentage" class="pa-2 font-weight-bold">
          </c-input>
          %
        </div>
        <v-btn rounded large block depressed color="blue" @click="updateTokenVesting" class="ml-2">
          Update Token Vesting params
        </v-btn>
      </div>

      <div v-else-if="tab === 1" class="pa-5 background">
        <div class="textFaint--text caption">
          Set UNCL guaranteed allocation amount
        </div>
        <div>
          <div class="d-flex align-center border br-20">
            <c-input :value.sync="unclAmountHuman" placeholder="0.0" @updateWhileFocussed="onHumanAmountChange" class="pa-2 font-weight-bold">
            </c-input>

            <div class="font-weight-bold mr-6">
              UNCL
            </div>
          </div>
        </div>
        <v-btn rounded block large depressed color="blue" @click="setUNCLAmount" class="mt-2 ml-2">
          SET UNCL allocation
        </v-btn>
      </div>
      
      <div v-else-if="tab === 2" class="background pa-5">
        <v-btn @click="forceFailByUnicrypt" large color="red" block>
          Force Fail
        </v-btn>
      </div>

      <div v-else-if="tab === 3" class="background pa-5">
        <div class="caption">
          Referrer 01: {{ presale_info.referrer_01 }}
        </div>
        <div class="caption">
          Referrer 02: {{ presale_info.referrer_02 }}
        </div>

        <div class="d-flex align-center border br-20 pr-4 mb-2">
          <c-input :value.sync="referrer02" placeholder="0x... address referrer 2" class="pa-2 font-weight-bold">
          </c-input>
        </div>

        <v-btn rounded block large depressed color="blue" @click="setReferrer" class="mt-2 ml-2">
          SET Referrer 02
        </v-btn>
      </div>

      <div v-else-if="tab === 4" class="background pa-5">
        {{ presale_info }}
      </div>

    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers'
import PresaleContract from '@/smart-contracts/presales/v6/presale-contract'
import TxWrapper from '@/web3/tx-wrapper-2';

export default {
  props: {
    address: {
      type: String
    },
    presale_info: {
      type: Object,
      default: () => {

      }
    }
  },

  components: {
  },

  data: () => ({
    tab: 0,
    unclAmount: '2000000000000000000',
    unclAmountHuman: '2',
    token_vesting: {
      implement_vesting: false,
      linear_lock: true,
      start_emission: '0',
      end_emission: '0',
      percentage: '0'
    },
    lockPeriodStart: 'months',
    lockPeriodEnd: 'months',
    referrer02: ''
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
  },

  watch: {
  },

  methods: {
    onHumanAmountChange (val) {
      try {
        this.unclAmount = ethers.utils.parseUnits(val, 18)
      } catch (e) {
        this.unclAmount = '0'
      }
    },
    async setReferrer () {
      TxWrapper.doTransaction(PresaleContract.setReferrer,
        [this.address, this.referrer02], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          // this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
    },
    async setUNCLAmount () {
      TxWrapper.doTransaction(PresaleContract.setUNCLAmount,
        [this.address, this.unclAmount], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          // this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // this.lockLoading = false
        })
    },
    async updateTokenVesting () {
      var startMultiplier = 60 * 60 * 24 // days
      if (this.lockPeriodStart === 'months') {
        startMultiplier *= 30
      }
      var startEmission = this.token_vesting.start_emission * startMultiplier

      var endMultiplier = 60 * 60 * 24 // days
      if (this.lockPeriodEnd === 'months') {
        endMultiplier *= 30
      }
      var endEmission = this.token_vesting.end_emission * endMultiplier

      TxWrapper.doTransaction(PresaleContract.setTokenVestingParams,
        [this.address, this.token_vesting.implement_vesting, this.token_vesting.linear_lock, startEmission, endEmission, this.token_vesting.percentage], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          // this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // this.lockLoading = false
        })
    },
    async forceFailByUnicrypt () {
      TxWrapper.doTransaction(PresaleContract.forceFailByUnicrypt,
        [this.address], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          // this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // this.lockLoading = false
        })
    }
  },

  created () {
    this.token_vesting.implement_vesting = this.presale_info.vesting_implemented
    this.token_vesting.linear_lock = this.presale_info.vesting_linear_lock
    // this.token_vesting.start_emission = this.presale_info.vesting_start_emission
    // this.token_vesting.end_emission = this.presale_info.vesting_end_emission
    this.token_vesting.percentage = this.presale_info.vesting_percentage
  }

}
</script>