<template>
  <div class="text-center cursor-pointer" @click="$root.ammLinkTwo(`/ilo/${db_info.presale_contract}`, exchange)">
    <v-progress-circular
    :value="unclProgress"
    :rotate="-90"
    size="60"
    width="2"
    :color="db_info.hot ? 'primary' : 'light-blue'"
    background-color="background"
    :class="[db_info.hot ? 'primary-pulse': '', 'br-c']">
      <coin-icon :url="db_info.icon_url" :size="50"></coin-icon>
    </v-progress-circular>
    <template v-if="db_info.hot">
      <div class="caption font-weight-bold primary--text">
        {{ db_info.s_token.symbol }}
      </div>
      <div class="caption font-weight-medium primary--text">
        {{ startDateFromNow }}
      </div>
      <div v-if="!firstLoad" class="caption font-weight-medium primary--text">
        {{ hardcapHuman }} {{ baseTokenSymbol }}
      </div>
    </template>
    <template v-else>
      <div class="caption font-weight-bold text--text">
        {{ db_info.s_token.symbol }}
      </div>
      <div class="caption font-weight-medium textFaint--text">
        {{ startDateFromNow }}
      </div>
      <div v-if="!firstLoad" class="caption font-weight-medium textFaint--text">
        {{ hardcapHuman }} {{ baseTokenSymbol }}
      </div>
    </template>
  </div>
</template>

<script>
import { ethers } from 'ethers'
import moment from 'moment'
import PresaleContract4 from '@/smart-contracts/presales/v4/presale-contract'
import PresaleContract6 from '@/smart-contracts/presales/v6/presale-contract'
import PresalePeriphery from '@/smart-contracts/presales/presale-periphery'

export default {

  props: {
    db_info: {
      type: Object,
      default: () => ({
        presale_contract: null
      })
    },
    exchange: null
  },

  data: () => ({
    presale_info: {
      hardcap: '0',
      base_token: {
        symbol: '?'
      },
      uncl_participants: '0',
      uncl_max_participants: '0'
    },
    firstLoad: true
  }),

  computed: {
    cNetwork () {
      return this.$settings.AMMS[this.exchange].chain
    },
    startDateFromNow () {
      var numBlocks = this.db_info.start_block - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date.fromNow()
    },
    blockNumber () {
      if (this.exchange) {
        return this.$store.state.blockNumz[this.$settings.AMMS[this.exchange].chain]
      }
      return this.$store.state.blockNumber
    },
    blocksPerSecond () {
      return this.$settings.CHAINS[this.cNetwork].blockTime
    },
    unclProgress () {
      if (this.presale_info.uncl_max_participants === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.presale_info.uncl_participants).mul(1000).div(this.presale_info.uncl_max_participants)
      return percent.toNumber() / 10
    },
    hardcapHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.hardcap, this.presale_info.base_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    baseTokenSymbol () {
      return this.presale_info.base_token.address === this.$settings.WRAPPED_GAS_TOKEN[this.cNetwork] ? this.$settings.CHAINS[this.cNetwork].gasToken.symbol : this.presale_info.base_token.symbol.toUpperCase()
    },
  },

  methods: {
    async refresh () {
      var network = this.exchange ? this.$settings.AMMS[this.exchange].chain : null
      var contract_version = await PresalePeriphery.getContractVersion(this.db_info.presale_contract, network)
      var response
      if (contract_version >= 3 && contract_version <= 5) {
        response = await PresaleContract4.getInfo(this.db_info.presale_contract, network)
      } else if (contract_version === 6) {
        response = await PresaleContract6.getInfo(this.db_info.presale_contract, network)
      } else {
        return
      }
      this.presale_info = response.presale_info
      this.firstLoad = false
    },
  },

  created () {
    this.refresh()
  }
}
</script>