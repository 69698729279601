<template>
  <div class="pa-4">
    
    <div v-if="pool_info.creator !== sEthers.coinbase">
      Connect the pool admin wallet to edit these settings
      <div class="v-card pa-3 font-weight-bold d-flex align-center">
        <v-icon class="mr-3 pa-2 lgrad-green br-c" size="32" color="white">mdi-account-circle-outline</v-icon>
        <div>
          <div>
            Pool Admin
          </div>
          <div class="caption font-weight-medium textFaint--text">
            {{ pool_info.creator }}
          </div>
        </div>
      </div>
    </div>

    <div v-else class="">
      <div class="pa-4">
        <div class="font-weight-bold pb-1">
          Rules
        </div>
        <div class="textFaint--text font-weight-medium pb-10">
          Do not use another tokens icon which is not your own, and do not post vulgar images. Doing so will get your farm delisted permanently from the platform.
        </div>
      </div>

      <div class="v-card pa-4 br-8">
        <div class="d-flex align-center font-weight-bold">
          Token icon (200px x 200px)
          <v-spacer></v-spacer>
          <v-btn small href="https://imgur.com/upload" target="_blank" rounded class="lgrad-green white--text">
            <v-icon small color="white" class="mr-1">mdi-upload</v-icon>
            Imgur
          </v-btn>
        </div>
        <div class="caption textFaint--text">
          Icon must have equal width and height. Use imgur, fast and easy. No account required.
        </div>
        <div class="caption textFaint--text">
          e.g. https://i.imgur.com/HTdrQ38.png
        </div>
        <div class="mt-2 d-flex align-center pa-3 br-20 inputcolor">
          <c-input :value.sync="icon.url" placeholder="https://">
          </c-input>
        </div>
        <div class="caption textFaint--text text-end">
          {{ (typeof icon.url === 'string' ? icon.url : '').length }} / 300
        </div>

        <div v-if="icon.url.length > 0" class="midground pa-4 br-20 text-center">

          <template v-if="!icon.error">
            <div class="primary--text d-flex align-center justify-center">
              <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
              Token image
            </div>
            <img 
            :src="icon.url" 
            height="100px"
            width="100px"
            class="br-c"
            @error="icon.error = true">
          </template>

          <div v-else>
            <div class="title">
              Image not found
            </div>
            <div class="caption">
              Does your image url end in .svg .png .jpg ...?
            </div>
            <div class="caption">
              Your link MUST point to an image, NOT a gallery. Try last clicking on the image,
              and selecting 'Open image in a new tab'. This is the desired link.
              e.g. https://i.imgur.com/7sDE3Uw.jpg
            </div>
          </div>
        </div>
      </div>
      <div class="v-card pa-4 mt-6 br-8">
        <div class="d-flex align-center font-weight-bold">
          Banner (1400px x 500px)
          <v-spacer></v-spacer>
          <v-btn small href="https://imgur.com/upload" target="_blank" rounded class="lgrad-green white--text">
            <v-icon small color="white" class="mr-1">mdi-upload</v-icon>
            Imgur
          </v-btn>
        </div>
        <div class="caption textFaint--text">
          Banner should be of the dimensions 1400/500.
        </div>
        <div class="caption textFaint--text">
          e.g. https://i.imgur.com/HTdrQ38.png
        </div>
        <div class="mt-2 d-flex align-center pa-3 br-20 inputcolor">
          <c-input :value.sync="banner.url" placeholder="https://">
          </c-input>
        </div>
        <div class="caption textFaint--text text-end">
          {{ (typeof banner.url === 'string' ? banner.url : '').length }} / 300
        </div>

        <div v-if="banner.url.length > 0" class="midground pa-4 br-20 text-center">

          <template v-if="!banner.error">
            <div class="primary--text d-flex align-center justify-center">
              <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
              Banner image
            </div>
            <img 
            :src="banner.url" 
            height="200px"
            @error="banner.error = true">
          </template>

          <div v-else>
            <div class="title">
              Image not found
            </div>
            <div class="caption">
              Does your image url end in .svg .png .jpg ...?
            </div>
            <div class="caption">
              Your link MUST point to an image, NOT a gallery. Try last clicking on the image,
              and selecting 'Open image in a new tab'. This is the desired link.
              e.g. https://i.imgur.com/7sDE3Uw.jpg
            </div>
          </div>
        </div>
      </div>

      <v-btn @click="saveSettings" x-large block rounded class="mt-4 lgrad-green white--text font-weight-medium">
        UPDATE IMAGES
      </v-btn>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {

  props: {
    address: null,
    pool_info: {
      type: Object,
      default: () => {
        return {
          share_weight_total: '0',
          min_stake_amount: '0',
          max_stake_amount: '0',
        }
      }
    },
    apiData: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },

  components: {
  },

  data: () => ({
    icon: {
      url: '',
      error: false
    },
    banner: {
      url: '',
      error: false
    },
    signedMessage: null
  }),

  watch: {
    'icon.url' () {
      this.icon.error = false
    },
    'banner.url' () {
      this.banner.error = false
    }
  },

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
  },

  methods: {
    async saveSettings () {
      await this.sign()
      this.updateInfo()
    },
    async sign () {
      const domain = {
        name: 'Unicrypt'
      }
      const types = {
        Message: [
          { name: 'content', type: 'string' }
        ]
      }
      const message = {
        content: `Update farm ${this.address}`
      }

      this.signedMessage = await this.sEthers.signer._signTypedData(domain, types, message)
    },
    async updateInfo () {
      var data = {
        iconURL: this.icon.url,
        bannerURL: this.banner.url,
        signature: this.signedMessage,
        farmContract: this.address
      }
      axios.post(`${this.$root.$servers.FARMS[this.$root.getLegacyChainKey(this.$store.state.requiredNetwork)].server}/farms/update-media`, data)
        .then(response => {
          this.$root.$dialog.globalSuccess.open('Information updated')
          this.$emit('api-refresh')
        })
        .catch(error => {
          this.$root.$dialog.web3Error.open(error.message)
        })
    },
  },

  created () {
    this.icon.url = this.apiData.meta.media.icon ? this.apiData.meta.media.icon : ''
    this.banner.url = this.apiData.meta.media.banner ? this.apiData.meta.media.banner : ''
  }
}
</script>