<template>
  <v-navigation-drawer
  v-model="drawer"
  app
  temporary
  stateless
  class="foreground"
  :width="$vuetify.breakpoint.xs ? '256' : '50%'"
  >
    <div class="border-b pa-2 text-end">
      <v-btn x-large icon @click="closeDrawer">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div>
      <v-menu
      offset-y
      >
        <template v-slot:activator="{ attrs, on }">
          <div class="d-flex align-center pa-3 midground br-20 ma-3"
          v-bind="attrs"
          v-on="on"
          >
            {{ tab }}
            <v-spacer></v-spacer>
            <v-icon small>mdi-chevron-down</v-icon>
          </div>
        </template>

        <v-list style="min-width: 200px;" class="background">
          <v-list-item @click="tab = 'servers'">
            <v-list-item-title>
              Servers
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="tab = 'ecosystem-fees'">
            <v-list-item-title>
              Ecosystem fees
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <servers v-if="tab === 'servers'"></servers>

    <ecosystem-fees v-else-if="tab === 'ecosystem-fees'"></ecosystem-fees>
    
  </v-navigation-drawer>
</template>

<script>
import EcosystemFees from './ecosystem-fees/main'
import Servers from './servers/Page'

export default {
  components: {
    EcosystemFees,
    Servers
  },

  data: () => ({
    tab: 'servers', // servers, ecosystem-fees
  }),

  computed: {
    drawer: {
      get () {
        return this.$store.state.superUserDrawerOpen
      },
      set (nv) {
        this.$store.commit('setSuperUserDrawerState', nv)
      }
    }
  },

  methods: {
    closeDrawer () {
      this.$store.commit('setSuperUserDrawerState', false)
    },
  }
}
</script>
