<template>
  <div class="">

    <div class="text-center pt-2 pb-2">
      <v-btn large rounded depressed @click="$root.$dialog.chainSwitcher.open()" color="midground">
        <img 
        :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
        height="18px"
        width="18px"
        class="mr-3 br-20">
        {{ $settings.CHAIN_DISPLAY_NAME[$store.state.requiredNetwork] }}
        <v-icon small color="textFaint">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <!-- NOT CONNECTED -->
    <div v-if="!sEthers.coinbase" class="text-center pa-6">
      <div>
        <v-icon size="40" color="textFaint">mdi-account-circle</v-icon>
        <div class="textFaint--text caption pt-2">
          Connect your wallet to view your locks
        </div>
      </div>
      <div class="pt-2">
        <v-btn
        large
        block
        outlined
        color="navcolor"
        class="white--text"
        rounded
        @click="connectWallet"
        >
          CONNECT
        </v-btn>
      </div>
    </div>
    <!-- NOT CONNECTED -->

    <template v-else>
      <div class="pa-4 border-b">
        Your Token Locks
      </div>

      <v-fade-transition mode="out-in">
        <user-tokens v-if="tab === 0" @on-select-token="selectToken"></user-tokens>

        <div v-if="tab === 1">
          <div class="pl-2 pt-2 background">
            <v-btn icon @click="tab = 0" color="text">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <user-locks-for-token :tokenHydrated="tokenHydrated"></user-locks-for-token>
        </div>
      </v-fade-transition>
    </template>
  </div>
</template>

<script>
import UserTokens from './UserTokens'
import UserLocksForToken from './UserLocksForToken'

export default {
  components: {
    UserTokens,
    UserLocksForToken
  },

  data: () => ({
    tab: 0,
    tokenHydrated: {}
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    }
  },

  watch: {
    sEthersWatcher () {
      this.tab = 0
    },
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    selectToken (token) {
      this.tokenHydrated = token
      this.tab = 1
    }
  },

  created () {
  }
}
</script>