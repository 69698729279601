<template>
  <div>

    <v-scroll-y-transition mode="out-in">
      <div v-if="loading" class="text-center pa-8">
        <v-progress-circular
        indeterminate
        size="120"
        width="2"
        color="#aaa">
          <img 
          src="@/assets/img/UNCX_fill.svg" 
          height="110px"
          class="greyscale"
          width="110px">
        </v-progress-circular>
      </div>
      <div v-else>
        
        <template v-if="num_stakers > 0">

          <div class="textFaint--text d-flex align-center pa-2">
            {{ num_stakers }} active staker{{ num_stakers === '1' ? '' : 's' }}
            <v-spacer></v-spacer>
            <v-btn @click="refresh" text rounded color="textFaint">
              <span class="font-weight-medium mr-1">Refresh</span>
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </div>

          <div style="overflow: auto;">
            <table class="c-table" style="width: 100%">
              <tr v-if="!$vuetify.breakpoint.xs" class="caption textFaint--text">
                <th>
                  User
                </th>
                <th>
                  shares staking
                </th>
                <th>
                  Time Boost
                </th>
                <th>
                  Boost
                </th>
                <th>
                  Stake Weight
                </th>
                <th v-if="false">
                  Subs
                </th>
                <th>
                  Withdrawl date
                </th>
              </tr>
              <tr v-else class="caption textFaint--text">
                <th>
                  <div>
                    User
                  </div>
                  <div class="caption">
                    Boost info
                  </div>
                </th>
                <th>
                  <div>
                    shares staking
                  </div>
                  <div class="caption">
                    Share weight
                  </div>
                </th>
              </tr>
              <staker-row v-for="staker of stakers" :key="staker.user_address" :staker_info="staker" :pool_info="pool_info">
              </staker-row>
            </table>
          </div>
        </template>
        <div v-else class="pa-8">
          No one is staking in this stake pool.
        </div>

      </div>
    </v-scroll-y-transition>

  </div>
</template>

<script>
import StakePoolPaginatorContract from '@/smart-contracts/farms-v2/oracle/stake-pool/v2/stake-pool-paginator'
import StakerRow from './staker-view-row'
// import ERC20 from '@/smart-contracts/erc20/pager'

export default {

  props: {
    stake_pool_address: null,
    pool_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },

  components: {
    StakerRow
  },

  data: () => ({
    num_stakers: 0,
    stakers: [],
    loading: false
  }),

  methods: {
    async refresh () {
      this.loading = true
      this.num_stakers = await StakePoolPaginatorContract.getNumStakers(this.stake_pool_address)
      this.stakers = await StakePoolPaginatorContract.getStakers(this.stake_pool_address, 0, this.num_stakers)
      this.loading = false
    },
    async getUnclTokenInfo () {
    }
  },

  created () {
    this.getUnclTokenInfo()
    this.refresh()
  }
}
</script>